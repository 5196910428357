import { Inject, Injectable } from '@angular/core';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import get from 'lodash-es/get';

import { DineToFinderErrorModalComponent } from '@finder/shared/components/dine-to-finder-error-modal/dine-to-finder-error-modal.component';
import { ModalComponent } from '@finder/shared/components/modal/modal.component';
import { ModalService } from '@finder/shared/components/modal/modal.service';
import { LoadingService } from '@finder/shared/services/loading-service/loading-service.service';
import { WindowRef } from '@finder/shared/services/window-ref/window-ref.service';

const QUERY_STRING = 'dineErrorCode';

@Injectable({
    providedIn: 'root'
})
export class DineToFinderErrorService {

    private modalRef: ModalComponent;

    constructor(
        private windowRef: WindowRef,
        private loadingService: LoadingService,
        private modalService: ModalService,
        @Inject(DOCUMENT) private document: Document,
    ) { }

    initialize(languageBundle: any) {
        const queryParams = new URLSearchParams(this.windowRef.nativeWindow.location.search);
        const dineToFinderError = queryParams.get(QUERY_STRING);

        if (!dineToFinderError) {
            return;
        }

        const modalText = get(languageBundle, dineToFinderError, languageBundle.default);

        this.modalRef = this.modalService.create(
            DineToFinderErrorModalComponent,
            {
                title: modalText.title,
                data: {
                    copy: modalText.copy
                }
            }
        );

        /**
         * the following subscription will be destroyed:
         * - After the first emission: Modal should be displayed only once
         * - If the page emits a loading error: Should not show modal above error pages
         */
        this.validateLoadingState()
            .pipe(
                switchMap(() => this.modalRef.ready()),
                take(1),
                takeUntil(this.loadingService.getErrorState())
            )
            .subscribe(() => {
                // remove the query string from the query params reference
                queryParams.delete(QUERY_STRING);

                // apply new query string in the current url
                const url = this.windowRef.nativeWindow.location.href.replace(
                    this.windowRef.nativeWindow.location.search,
                    queryParams.toString()
                );

                // update url without reloading page
                history.pushState({}, this.document.title, url);
                this.modalRef.open();
            });
    }

    /**
     * Creates an Observable that only will emit a value when the page is loaded.
     *
     * @returns
     */
    private validateLoadingState() {
        return this.loadingService.getLoadingState()
            .pipe(
                filter(loaded => loaded)
            );
    }
}
