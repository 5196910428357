import { InjectionToken } from '@angular/core';
import { DATE_CONSTANTS } from '../../constants/date.constants';

/**
 * @description Convert a string date into a date object
 * @param date  String version of a date
 */
export const getDateObject = (simpleDate: string): Date => {
    if (!simpleDate) {
        return;
    }

    // Replace '/' with '-'
    simpleDate = simpleDate && simpleDate.replace(/\//gi, '-');
    // I need to lock in the date with a timezone because browsers treat time differently
    const dateObj = new Date(simpleDate + DATE_CONSTANTS.timeZone);

    if (isNaN(dateObj.valueOf())) {
        return;
    }

    return dateObj;
};

export type GetDateObjectProvider = (simpleDate: string) => Date;
export const GET_DATE_OBJECT =
    new InjectionToken('GET_DATE_OBJECT', {
        factory: () => getDateObject
    });
