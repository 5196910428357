import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FontIconComponent } from './font-icon.component';
import { SafeHtmlPipeModule } from '@finder/shared/utils/pipes/safe-html/safe.html.module';


@NgModule({
    imports: [
        CommonModule,
        SafeHtmlPipeModule,
    ],
    declarations: [
        FontIconComponent,
    ],
    providers: [],
    exports: [
        FontIconComponent,
    ]
})
export class FontIconModule {}

