import { InjectionToken, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * Append a javascript resource at the end of the body
 *
 * @param document the document
 * @param src resource src
 */
export const loadScriptResource = (document, src: string, zone: NgZone): Observable<boolean> => {
    const loaded$ = new Subject<boolean>();

    zone.runOutsideAngular(() => {
        const scriptElement = document.createElement('script');
        // Listen for the script to be loaded
        scriptElement.onload = () => {
            loaded$.next(true);
            loaded$.complete();
        };
        scriptElement.onerror = () => {
            loaded$.error(false);
            loaded$.complete();
        };
        scriptElement.src = src;
        scriptElement.type = 'text/javascript';
        document.body.appendChild(scriptElement);
    });

    return loaded$.asObservable();
};

export type LoadScriptResourceProvider = (document: any, src: string, zone: NgZone) => Observable<boolean>;
export const LOAD_SCRIPT_RESOURCE =
    new InjectionToken('LOAD_SCRIPT_RESOURCE', {
        factory: () => loadScriptResource
    });
