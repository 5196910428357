import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { DebugCheckService } from '@finder/shared/services/debug-check/debug-check.service';

/**
 * This service allows you to keep logging in place but _only_ show it when the debug
 * cookie is set.
 *
 * While working with a third party integration I found it helpful to log interactions
 * just so I could see the flow of things as I was using the product. I did not necessarily
 * need to set breakpoints and stop and inspect, just see the flow. But it was also handy
 * to be able to go back and inspect values if I needed to. I did not want to have to keep
 * adding and removing logging, so I came up with this.
 */
@Injectable({
    providedIn: 'root',
})
export class DebugLogService {
    private _output = new BehaviorSubject(null);
    get output(): Observable<any> {
        return this._output.asObservable();
    }

    constructor(
        private debugCheckService: DebugCheckService,
    ) {}

    log(...args) {
        // TODO: Use a decorator when available
        if (!this.checkDebug()) {
            return;
        }

        this._output.next(args);

        console.log(...args);
    }

    error(...args) {
        // TODO: Use a decorator when available
        if (!this.checkDebug()) {
            return;
        }

        this._output.next(args);

        console.error(...args);
    }

    info(...args) {
        this._output.next(args);

        // This allows us to use `console.info` here but not EVERYWHERE...
        // eslint-disable-next-line no-console
        console.info(...args);
    }

    private checkDebug() {
        return this.debugCheckService.isDebugEnabled();
    }
}
