import { Animation } from '@finder/shared/components/animations/animation/animation.interface';

export const getSimpleSprite = (data: Animation, isMobile: boolean = false) => {
    const scalingRatio = isMobile ? (data.scale || 0.5) : 1;
    const { height, frames, width, source, duration, repeat, inset } = data;
    const animationDelay = data.delay || 1000; // milliseconds
    const scaledWidth = Math.floor(width * scalingRatio);
    const scaledheight = Math.floor(height * scalingRatio);
    const componentInset = inset || 'auto';
    const componentInsetMobile = data.insetMobile || componentInset;

    const settings: any = {
        componentStyles: {
            height: `${scaledheight / parseInt(frames, 10)}px`,
            width: `${scaledWidth}px`,
            inset: `${componentInset}`,
        },
        spriteStyles: {
            height: `${scaledheight}px`,
            width: `${scaledWidth}px`,
            'background-image': `url("${source}")`
        },
        wrapperStyles: {
            height: `${scaledheight / parseInt(frames, 10)}px`,
            width: `${scaledWidth}px`
        },
        animateStyles: {
            'animation-delay': `${animationDelay}ms`,
            'animation-name': 'simple-sprite',
            'animation-duration': `${duration}ms`,
            'animation-timing-function': `steps(${frames})`,
            'animation-iteration-count': repeat,
            'animation-fill-mode': 'forwards',
            'animation-direction': 'normal',
        }
    };

    if (componentInsetMobile !== componentInset) {
        settings.componentStyles.insetMobile = componentInsetMobile;
    }

    return settings;
};
