import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AsyncScheduler } from 'rxjs/internal/scheduler/AsyncScheduler';

import { ButtonModule } from './button/button.module';
import { CalendarService } from '@finder/shared/services/calendar-service/calendar-service.service';
import { CampaignStickyCtaComponent } from '@finder/shared/components/campaign-sticky-cta/campaign-sticky-cta.component';
import { CssLoaderModule } from '@finder/shared/components/css-loader/css-loader.module';
import { FeatureDetectionService } from '@finder/shared/services/feature-detection/feature-detection.service';
import { FontIconLoaderComponent } from '@finder/shared/components/font-icon-loader/font-icon-loader.component';
import { HeaderOverridesService } from '@finder/shared/services/header-overrides/header-overrides.service';
import { HorizontalRuleModule } from '@finder/shared/components/horizontal-rule/horizontal-rule.module';
import { InfiniteScrollingComponent } from '@finder/shared/components/infinite-scrolling/infinite-scrolling.component';
import { JoinModule } from '../utils/pipes/join/join.module';
import { LayoutComponent } from '@finder/shared/components/layout/layout.component';
import { LoadingSpinnerModule } from '@finder/shared/components/loading-spinner/loading-spinner.module';
import { ModalService } from '@finder/shared/components/modal/modal.service';
import { PremierAccessComponent } from '@finder/shared/components/premier-access/premier-access.component';
import { SafeHtmlPipeModule } from '@finder/shared/utils/pipes/safe-html/safe.html.module';
import { ScriptLoaderModule } from '@finder/shared/components/script-loader/script-loader.module';
import { SyndicatedFooterComponent } from '@finder/shared/components/syndicated-layout/syndicated-footer/syndicated-footer.component';
import { SyndicatedHeaderComponent } from '@finder/shared/components/syndicated-layout/syndicated-header/syndicated-header.component';
import { SystemErrorModule } from '@finder/shared/components/system-error/system-error.module';
import { WindowRef } from '@finder/shared/services/window-ref/window-ref.service';

const exportableComponents = [
    LayoutComponent,
    FontIconLoaderComponent,
    SyndicatedFooterComponent,
    SyndicatedHeaderComponent,
    InfiniteScrollingComponent,
    CampaignStickyCtaComponent
];

const exportableProviders = [
    { provide: AsyncScheduler, useValue: AsyncScheduler },
    CalendarService,
    CurrencyPipe,
    DecimalPipe,
    FeatureDetectionService,
    HeaderOverridesService,
    ModalService,
    WindowRef,
];

const exportableModules = [
    ButtonModule,
    CssLoaderModule,
    JoinModule,
    HorizontalRuleModule,
    LoadingSpinnerModule,
    PremierAccessComponent,
    SafeHtmlPipeModule,
    ScriptLoaderModule,
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ...exportableModules,
        SystemErrorModule,
    ],
    declarations: [...exportableComponents],
    exports: [...exportableComponents, ...exportableModules],
    providers: exportableProviders,
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class ComponentsModule {
}
