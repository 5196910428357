<picture
    [rootMargin]="rootMargin"
    intersectOnce="true"
    [intersectionEnabled]="lazyLoad"
    (finderIntersection)="onIntersectionObserver($event)"
    [ngStyle]="{aspectRatio: loaded ? 'auto': skeletonAspectStyling.aspectRation}"
    >
    <source *ngIf="media.mobile && media.desktop" [srcset]="media.mobile" [media]="media.media" />
    <img class="picture-img" *ngIf="picture"
        height="100%"
        width="100%"
        [ngClass]="{'loading': loading, 'loaded': loaded}"
        [attr.alt]="picture.alt || ''"
        [attr.aria-label]="picture.alt !== '' ? picture.alt : null"
        [src]="media.desktop || media.mobile || picture.desktop  || picture.mobile ||  defaultImage"
        [attr.loading]="loadingType"
        (load)="onLoad($event)"
    />
</picture>
