import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CONFIG_CONSTANTS, API_CONSTANTS } from '@finder/shared/constants/app.constants';
import { ConfigService } from '@finder/core/config.service';
import getApiBase from '@finder/shared/utils/string/getApiBase';

const apiUrlKey = CONFIG_CONSTANTS.apiUrlKey;
const siteIdKey = CONFIG_CONSTANTS.siteIdKey;
const defaultVersionKey = CONFIG_CONSTANTS.defaultVersionKey;
const reservationConflictingItemsEndpoint = API_CONSTANTS.reservationConflictingItemsEndpoint;

@Injectable()
export class ReservationServiceConnectorService {

    private baseUrl: string;
    private version: string;
    private siteId: string;

    constructor(
        private http: HttpClient,
        private config: ConfigService
    ) {
        this.baseUrl = this.config.getValue(apiUrlKey);
        this.version = this.config.getValue(defaultVersionKey);
        this.siteId = this.config.getValue(siteIdKey);
    }

    /**
     * Get Conflicting items for a reservation time.
     *
     * @param swid the swid
     * @param date the date to check
     * @param time the time to check
     * @param productType the product type
     * @param guestIdentifier the guest identifier
     * @param version web api verison
     * @returns an observable
     */
    public getConflictingItems(
        swid: string, date: string, time: string, productType: string, guestIdentifier: string, version?: string
    ): Observable<any> {
        let url = this.constructUrl(reservationConflictingItemsEndpoint, this.baseUrl, version);
        url = `${url}/${swid}/${this.siteId}/${date}/${time}/${productType}/${guestIdentifier}`;

        return this.http.get(url);
    }

    /**
     * Constructs the options and endpoint URL
     *
     * @param endpoint  endpoint
     * @param baseUrl   base URL
     * @param version   api version -- defaulted to v1
     */
    private constructUrl(endpoint: string, baseUrl: string, version: string = this.version): string {
        const apiBase = getApiBase(baseUrl, version);

        return `${apiBase}/${endpoint}`;
    }
}
