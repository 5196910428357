import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { GeolocationService } from '@wdpr/ra-angular-geolocation';
import { filter, switchMap } from 'rxjs/operators';
import isEmpty from 'lodash-es/isEmpty';
import { from } from 'rxjs';
// DO NOT REMOVE [AnalyticsService] - It is necessary for the framework to load
import { AnalyticsService, ConfigService as AnalyticsConfigService } from '@wdpr/angular-analytics';
import { LoggerService } from '@wdpr/ra-angular-logger';
// DO NOT REMOVE [AnalyticsService] - It is necessary for the framework to load
import { map } from 'rxjs/operators';
import {forkJoin, Observable } from 'rxjs';

import { ConfigService } from '@finder/core/config.service';
import { Facet } from '@finder/shared/services/filter-emitter-service/facet.class';
import { WindowRef } from '@finder/shared/services/window-ref/window-ref.service';
import { LocaleService } from '@finder/shared/services/locale-service/locale-service.service';
import { FinderPageKeyService } from '@finder/shared/services/finder-page-key/finder-page-key.service';
import {
    ANALYTICS_FACETS_UTILIZED_ACTION,
    ANALYTICS_DEFAULT_SITE_SECTION,
    ANALYTICS_CONSTANTS,
    ANALYTICS_CUSTOM_PAGE_ID_LIST
} from '@finder/shared/constants/app.constants';

@Injectable()
export class FinderAnalyticsService {

    private loader: any;
    private loaderPromise: any;
    private resolvePromise: any;
    private guestModel: any;
    private finderAnalytics = ANALYTICS_CONSTANTS;

    constructor(
        private analyticsService: AnalyticsService,
        private analyticsConfigService: AnalyticsConfigService,
        private pageKeyService: FinderPageKeyService,
        private geolocationService: GeolocationService,
        private config: ConfigService,
        private windowRef: WindowRef,
        private localeService: LocaleService,
        private logger: LoggerService,
    ) {}

    initAnalytics() {
        this.analyticsConfigService.autoPageViewTrackingTiming = this.finderAnalytics.config.pageViewTrackTiming;
    }

    /**
     * Gets guest analytics model data - this is used both by personalization (via
     * the decision attributes loader) as well as the general page analytics
     */
    getGuestModel(): Observable<any> {
        const observables: Observable<any>[] = [ this.geolocationService.getGeoCode({forceRefresh: true}) ];

        return forkJoin(observables)
            .pipe(
                map(res => {
                    const pageData = this.pageKeyService.get() ? this.getPageData() : {} ;

                    // If we've already loaded this up just return the result rather than calling services again
                    /* istanbul ignore next */
                    if (this.guestModel) {
                        this.guestModel.pageUrl = this.windowRef.nativeWindow.location.pathname;

                        return { ...this.guestModel, ...pageData };
                    }

                    const geo = res[0];
                    const hasMedia = res[1] || false;

                    const guestAffiliations = [];

                    // Set geo values
                    if (geo.metro) {
                        geo.dma = geo.metro;
                    }

                    if (geo.metrocode) {
                        geo.dmaCode = geo.metrocode;
                    }

                    if (geo.state) {
                        geo.region = geo.state;
                    }

                    if (geo.zip) {
                        geo.zipcode = geo.zip;
                    }

                    // Check CA resident
                    if (geo.state === this.finderAnalytics.geo.CA.name) {
                        guestAffiliations.push(this.finderAnalytics.geo.CA.resident);
                    }

                    // Check FL resident
                    if (geo.state === this.finderAnalytics.geo.FL.name) {
                        guestAffiliations.push(this.finderAnalytics.geo.FL.resident);
                    }

                    // Check SoCal resident
                    const zipNumber = Number(geo.zip || '0');
                    if (
                        zipNumber >= this.finderAnalytics.geo.affiliations.SoCal.zipStart &&
                        zipNumber <= this.finderAnalytics.geo.affiliations.SoCal.zipEnd
                    ) {
                        guestAffiliations.push(
                            this.finderAnalytics.geo.affiliations.SoCal.name
                        );
                    }

                    // Get locale parts
                    const localeParts = this.localeService.getLocaleParts();
                    // This allows Analytics to fire on local env when locale parts are missing
                    const resultConfiguration = localeParts ?
                        {
                            contentLanguage: localeParts.language,
                            contentLocale: localeParts.locale,
                        } :
                        { };

                    // We have enough for a basic analytics model
                    const result: any = {
                        configuration: resultConfiguration,
                        geo,
                        guestAffiliations,
                        site: this.config.getValue('siteId'),
                        availableGuestMedia: hasMedia,
                        pageUrl: this.windowRef.nativeWindow.location.pathname,
                    };

                    Object.assign(result, pageData);
                    this.guestModel = result;

                    return result;
                }),
            );
    }

    /**
     * Returns a promise that resolves to analytics model updates.
     * This is used by personalization framework via a factory provider.
     */
    getDecisionAttributes(): Promise<any> {
        return this.getGuestModel()
            .toPromise();
    }

    /**
     * Resolves the promise that returns the analytics configuration data.
     */
    /* istanbul ignore next */
    updateModel() {
        // if loaderPromise exists means
        // finderAnalyticsLoader was called first.
        if (this.loaderPromise) {
            this.loaderPromise.then(this.resolvePromise);
        }
        // after resolve the promise we revert back the
        // ConfigService.load method to the original.
        this.analyticsConfigService.loader = this.loader;
    }

    /**
     * Updates the analytics model with page-specific data
     */
    updatePage(params?: ParamMap) {
        this.analyticsConfigService.addGlobalProcessors(this.getPageData(params));
    }

    updateErrorPage(status) {
        this.analyticsConfigService.addGlobalProcessors({
            pageId: `${status}`,
            siteSection: this.finderAnalytics.errorPage.siteSection,
        });
    }

    updateOtherProperties(props: any) {
        this.analyticsConfigService.addGlobalProcessors(props);
    }

    /**
     * Updates the analytics model with facility data
     */
    updateFacility(id: number | string, name: string) {
        const processor: any = {
            facility: {
                id,
                name
            }
        };

        this.analyticsConfigService.addGlobalProcessors(processor);
    }

    /**
     * Updates the analytics model with geo and affiliation data
     */
    updateGeoAndAffiliations(guestModel) {
        this.analyticsConfigService.addGlobalProcessors(guestModel);
    }

    updateStoreType() {
        const processor: any = {
            storeType: this.finderAnalytics.storeType // IMPORTANT - triggers the tracking events
        };

        this.analyticsConfigService.addGlobalProcessors(processor);
    }

    updateAnalyticsModel(
        urlParams: ParamMap,
        guestModel: any,
        entityId?: string,
        title?: string,
        linkId?: string,
        otherParams?: any
    ) {
        this.updateGeoAndAffiliations(guestModel);
        this.updateFacility(entityId, title);
        this.updatePage(urlParams);
        this.updateStoreType();
        if (otherParams) {
            this.updateOtherProperties(otherParams);
        }
        if (linkId) {
            this.updateModelAndTrack({linkId});
        }
        this.updateModel();
    }

    /**
     * It adds the list of facets that are applied into analytics model, as well as the facet passed with its action.
     * For example, if a user selects a filter that has 'parades' as associated facet, parameters will be:
     * facetsList: [
     *     id: 'parades',
     *     ...
     * ]
     * facet: 'parades'
     * If the facet is already on the facetsList, it means the user is removing it, so we are setting 'Remove' as action.
     * facetsUtilized: The facet that is being added or removed. It is equal to prop20/evar13. i.e: 'Add:parades', 'Remove:abc'
     * facetsProgress: The list of all facets that are applied. It is equal to evar14. i.e: 'character-experiences,parades'.
     */
    updateAnalyticsFacets(facetsList: Facet[], facet?: string) {
        // Subscribe to the pageKey service and wait for a value before running this code
        this.pageKeyService.pageKeyReady().pipe(
            // Ignore empty results. The first result from the pageKey service is a blank string
            filter(result => !isEmpty(result)),
            switchMap(result => from(this.analyticsService.getModel()))
        ).subscribe((model: any) => {
            const modelProperties = {
                facetsUtilized: facet ?
                    [{
                        name: facet,
                        action: facetsList.find(element => element.id === facet) ?
                            ANALYTICS_FACETS_UTILIZED_ACTION.add :
                            ANALYTICS_FACETS_UTILIZED_ACTION.remove
                    }] :
                    [],
                facetsProgress: facetsList.map((obj) => ({ name: obj.id }))
            };

            Object.assign(model, modelProperties || {});
            this.analyticsService.update();
        });
    }

    /**
     * It updates the passed properties on the analytics model.
     * i.e: if we want to update the linkId:
     * updateModelAndTrack({linkId: 'someLinkId'})
     * This fires "otherClick" event
     */
    updateModelAndTrack(modelProperties: any) {
        this.analyticsService.trackLink(modelProperties);
    }

    analyticsCustomLoader(pageKey: string, analyticsModel: any) {
        this.setAnalyticsPageIdAndSection(analyticsModel);
        this.analyticsConfigService.loader = (pagekey) => Promise.resolve(analyticsModel);
        this.loader = this.analyticsConfigService.loader;
    }

    initAnalyticsLibrary(analyticsObject) {
        if (analyticsObject?.key && analyticsObject?.value) {
            const linkId = analyticsObject.value?.linkId ? analyticsObject.value.linkId : '';
            analyticsObject.value.linkId = linkId ?
                linkId :
                // We replace any '.' or '-' to '_' to keep consistency.
                // example of key: wdw_attractions_epcot_mission_space_advanced_training_lab
                analyticsObject.key.replaceAll(/[.-]/g, '_');
            this.analyticsCustomLoader(analyticsObject.key, analyticsObject.value);
        } else {
            this.logger.log('There is no analytics data coming from API response.');
        }
    }

    /**
     * Checks if p13n is enabled by brand and locale.
     * Config example format:
     * {
     *     wdw: [ 'en_US' ],
     *     dlr: []
     * }
     */
    /* istanbul ignore next */
    private isP13nEnabled() {
        // Get the config (see above for format)
        const p13n = this.config.getValue('personalizationEnabled');

        // Using the current brand, grab the array of enabled locales (if any)
        const enabledLocales: string[] = p13n[this.config.getValue('siteId')] || [];

        // Check to see if the current locale is in the array of brand-enabled locales
        return enabledLocales.indexOf(this.localeService.getLocale()) > -1;
    }

    /**
     * Gets URL parameter parts from a `ParamMap`
     */
    private getParts(params: ParamMap): any {
        return {
            type: params.get('type') || '',
            parkName: params.get('location') || '', // want to make sure 'null' is not the value
            friendlyId: params.get('id')
        };
    }

    /**
     * Gets a `siteSection` analytics value from the parameter parts
     */
    private getSiteSection(parts): string {
        if (!parts) {
            return '';
        }
        // If parkName is undefined the template literal will turn it into 'undefined'
        // so we need to explicitly handle that with an empty string - the replace will
        // then strip off the trailing slash after the empty string

        return `${this.finderAnalytics.siteSectionPrefix || ''}/${parts.join('/') || ''}`
            .replace(/-/g, '')      // dashes get stripped
            .replace(/\/\//g, '/')  // double slashes to single slash
            .replace(/\/$/, '');    // trailing slash gets stripped
    }

    /**
     * Gets the analytics `pageId` value from the `friendlyId` parameter part
     */
    private getPageId(friendlyId): string {
        return (friendlyId || '').replace(/-/g, '');
    }

    /**
     * Gets a variety of analytics data from a `ParamMap`
     */
    private getPageData(params?: ParamMap): any {
        const parts = params ? this.getParts(params) : this.pageKeyService.getParts();
        const nonProccessedKeys = this.pageKeyService.getPageKeyParts();
        const pageId = this.getPageId(parts.friendlyId);
        let siteSection = this.getSiteSection(nonProccessedKeys);
        // On listing pages we need to remove the pageId from the siteSection
        // to avoid getting i.e. /attractions/attractions on the analytics pageName
        if (siteSection.endsWith('/' + pageId)) {
            siteSection = siteSection.replace('/' + pageId, '');
        }

        // Set the basic page/site values
        return {
            pageId,
            pageVariant: this.finderAnalytics.pageVariant,
            siteSection,
        };
    }

    private setAnalyticsPageIdAndSection(analyticsModel: any) {
        // There's an issue with pages that have hashes on the URL. If that is the case, we set
        // the pageId and siteSection ourselves in order to prevent /na/na values on the pageName.
        // Also, analytics is being configured before calendars page adds the hash, so checking for that case
        const pageUrl = this.windowRef.nativeWindow.location.href;
        if (!ANALYTICS_CUSTOM_PAGE_ID_LIST.some(value => pageUrl.includes(value))) {
            return;
        }

        let pathName = this.windowRef.nativeWindow.location.pathname;
        const urlParts = pathName.split('/').filter(e => e.length);

        analyticsModel.siteSection = ANALYTICS_DEFAULT_SITE_SECTION;

        if (urlParts.length === 1) {
            analyticsModel.pageId = pathName;
        } else {
            analyticsModel.pageId = urlParts.at(-1);
            pathName = pathName.replace(analyticsModel.pageId, '');
            // Clean up double slashes
            pathName.replaceAll('//', '');
            analyticsModel.siteSection = `${analyticsModel.siteSection}${pathName}`;
        }
    }
}
