import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { LoggerService } from '@wdpr/ra-angular-logger';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';

import { LocaleParts } from './locale-parts.interface';
import { LOCALES, LOCALE_HREF_PREFIX_IGNORELIST } from '@finder/shared/constants/app.constants';
import { COOKIE_NAMES } from '@finder/shared/constants/cookies.constants';

@Injectable()
export class LocaleService {
    private localeCookieNames = {
        default: {
            languageSelection: 'languageSelection_jar',
            locale: 'localeCookie_jar'
        },
        akamai: {
            languageSelection: COOKIE_NAMES.LANGUAGE_SELECTION_AKA,
            locale: COOKIE_NAMES.LOCALE_AKA
        }
    };
    private LACDLocales = ['es-ar', 'es-cl', 'es-co', 'es-pe', 'es-mx', 'pt-br'];
    private HKDLLocales = ['en', 'zh-hk', 'zh-cn', 'ja', 'ko', 'th', 'id', 'ms'];
    private defaultLocales = LOCALE_HREF_PREFIX_IGNORELIST;

    constructor(
        private cookieService: CookieService,
        private loggerService: LoggerService
    ) {}

    /**
     * Gets the locale from the language selection cookie.
     *
     * @returns the locale string.
     */
    getLocale(): string {
        const languageCookieValue = this.getLanguageCookie();
        let result;

        if (languageCookieValue) {
            result = languageCookieValue.preferredLanguage || languageCookieValue.language;
        }

        return result;
    }

    /**
     * Gets the value of the language selection cookie.
     *
     * @returns the cookie value object or undefined
     */
    getLanguageCookie(): any {
        return this.getCookieObjectValue(
            get(this.localeCookieNames, 'akamai.languageSelection'), get(this.localeCookieNames, 'default.languageSelection')
        );
    }

    /**
     * Gets the value of the locale cookie.
     *
     * @returns the cookie value object or undefined
     */
    getLocaleCookie(): any {
        return this.getCookieObjectValue(get(this.localeCookieNames, 'akamai.locale'), get(this.localeCookieNames, 'default.locale'));
    }

    /**
     * Check to see if english is the selected language
     *
     * @returns
     */
    isLocaleEnglish() {
        const locale = this.getLocale();

        // If we can't pull the value from the cookie then return -1
        if (!locale) {
            return -1;
        }

        return locale === (LOCALES.en_US || LOCALES.en_US_normalized) ? 1 : 0;
    }

    /**
     * Checks if guest visits the page using a Latin American locale.
     */
    isLACDRegion() {
        return this.LACDLocales.includes(this.getLocale());
    }

    /**
     * Checks if guest is from one of the Hong Kong locales.
     * HKDL locales includes en_US because they want currency to appear for all locales.
     */
    isHKDLLocale() {
        return this.HKDLLocales.includes(this.getNormalizedLocale());
    }

    /**
     * Check to see if this is a default english locale
     */
    isDefaultLocale() {
        return this.defaultLocales.includes(this.getNormalizedLocale());
    }

    /**
     * Get the lang and locale from the full locale string (e.g., `en_US`, `es-us`, etc.)
     */
    getLocaleParts(): LocaleParts | null {
        // Get the current guest locale
        const localeString = this.getNormalizedLocale();

        if (!localeString) {
            return;
        }

        // Split into parts (thanks, normalization! 😁)
        const localeSplit = localeString.split('-');

        // Get the lang and locale
        // If we have a locale string that doesn't fit the "mormal" pattern (e.g., `jp`, `pt`)
        // then we'll just use it for both lang and locale values
        const lang = localeSplit[0];
        const locale = localeSplit.length > 1 ? localeSplit[1] : localeSplit[0];

        return {
            language: lang,
            locale
        };
    }

    /**
     * Normalize the locale string to `xx-xx` format to make it easier to parse
     */
    getNormalizedLocale(): string {
        const locale = this.getLocale();

        return locale ? locale.toLowerCase().replace('_', '-') : LOCALES.en_US_normalized;
    }

    /**
     * Gets the cookie value as object.
     * Checks the fallback cookie name if the fist one doesn't exist.
     *
     * @param cookieName the cookie name.
     * @param defaultCookieName the fallback cookie name.
     */
    private getCookieObjectValue(cookieName, defaultCookieName): any {
        let result;
        let cookieValue = this.cookieService.get(cookieName);
        cookieValue = cookieValue ?
            cookieValue : this.cookieService.get(defaultCookieName);

        // Make sure there's something in the cookie to parse first
        if (isEmpty(cookieValue)) {
            return result;
        }

        try {
            result = JSON.parse(cookieValue);
        } catch (e) {
            this.loggerService
                .error('locale-service:::Error parsing the locale cookie: is not a valid object');
        }

        return result;
    }

}
