import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { SeoService } from '@wdpr/ra-angular-seo-metadata';
import { EMPTY } from 'rxjs';
import { catchError, map, shareReplay, take } from 'rxjs/operators';

import { LoadingService } from '@finder/shared/services/loading-service/loading-service.service';
import { FinderApiConnectorService } from '@finder/shared/services/finder-api-connector/finder-api-connector.service';
import { DESTINATION_NAME_CONSTANTS, LODGING_PATHS } from '@finder/shared/constants/app.constants';
import { ConfigService } from '@finder/core/config.service';
import { FinderSeoService } from '@finder/shared/services/finder-seo/finder-seo.service';
import { FinderThemeService } from '@finder/shared/services/theme-service/finder-theme.service';
import { TravelAgentService } from '@finder/shared/services/travel-agent/travel-agent.service';

@Injectable()
export class ListResolver implements Resolve<any> {
    private route$ = {};

    constructor(
        private loadingService: LoadingService,
        private finderApi: FinderApiConnectorService,
        private configService: ConfigService,
        private seoService: SeoService,
        private finderSeoService: FinderSeoService,
        private themeService: FinderThemeService,
        private travelAgentService: TravelAgentService,
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        // Here we call the services to get the data ready for the list page

        // Reset of Error state to avoid getting Error message stuck after back button pressed GIT-31197
        this.loadingService.setErrorState(null);
        this.loadingService.setLoadingState(false);

        // If we try to go to a trade page but we don't have the session cookie, show an error.
        const tradeError = this.travelAgentService.showErrorIfCookieIsMissing();
        if (tradeError) {
            return;
        }

        // List pages are always default theme
        this.themeService.setDefaultTheme();

        const siteId = this.configService.getValue('siteId');
        const routeType = this.checkLodgingRoute(route.params, siteId);
        const destinationName = DESTINATION_NAME_CONSTANTS[siteId];

        // Set the typical explorer entity API call
        let listData$ = this.finderApi.getListAncestorEntities(routeType);

        // If this is a custom URL news page, then we need to change the API call to use watcher
        if (routeType === 'news') {
            listData$ = this.finderApi.getWatcherIndexedList('/type/news-standard', 'alias', 'entity');
        }

        // Let the route replay from the last request.
        if (this.route$[routeType]) {
            return this.resolveRoute(this.route$[routeType]);
        }

        this.route$[routeType] = listData$
            .pipe(
                catchError((error) => {
                    this.loadingService.setErrorState(error);
                    this.loadingService.setLoadingState(true);

                    // This doesn't really do anything other an stop the error from a
                    // method that wants an Observable returned to it
                    return EMPTY;
                }),
                // // When we navigate back to this page we'll return the last result from the request
                shareReplay({ bufferSize: 1, refCount: true }),
                map((result: any) => {
                    if (result.locations) {
                        result.locations.unshift({
                            id: -1,
                            title: result.labels?.multiSelect?.parkLocation
                        });
                    }

                    // Check for metadata in response
                    // If we have it, cool - if not, explicitly load the seo data
                    if (result.meta) {
                        this.seoService.setSeoData(result.meta);
                    } else {
                        this.finderSeoService.getPageKeyAndSetSeo();
                    }

                    // Some entities only show a map, no list; see GIT-43297
                    if (LODGING_PATHS[siteId].routes.includes(routeType)) {
                        result.mapOnly = true;
                    }

                    // Set some properties for "news" listings
                    if (routeType === 'news') {
                        result.title = `${destinationName} News`;
                        result.disableMultiSelect = true;
                        result.disableMap = true;
                        result.disableFilters = true;
                        result.containerCss = 'no-map';
                        result.loaded = true;
                    }

                    this.loadingService.setLoadingState(true);

                    return result;
                }),
                // take(1)
            );

        return this.resolveRoute(this.route$[routeType]);
    }

    private resolveRoute(route$) {
        return new Promise((resolve, reject) => {
            route$.subscribe(result => resolve(result));
        });
    }

    // Lodging routes (/hotels for DLR, /resorts for WDW) have "sub paths"
    // that display non-Disney-owned offerings. Because these options are
    // distinguished by a URL path and require a different set of Explorer
    // options, this method spoofs a different "entity type" for each brand
    private checkLodgingRoute(params, siteId) {
        // If the params object's 'type' value indicates it's the non-Disney-owned
        // items, we return a "mocked" entity type that requests the specific
        // kind of resort/hotel from Explorer
        // WDW: path is "/resorts/more" and Explorer values are under 'mga-resorts'
        // DLR: path is "/hotels/good-neighbor" and Explorer values are under 'gnh-hotels'
        if (LODGING_PATHS[siteId].routes.includes(params['type'])) {
            return Object.hasOwn(params, LODGING_PATHS[siteId].subPath) ?
                LODGING_PATHS[siteId].explorerEntityName :
                params['type'];
        } else {
            return params['type'];
        }
    }

}
