import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import get from 'lodash-es/get';
import isUndefined from 'lodash-es/isUndefined';

@Injectable()
export class FeatureDetectionService {

    constructor(@Inject(DOCUMENT) private document: Document) { }

    /**
     * Check to see if the browser supports the css feature object-fit
     */
    hasObjectFit() {
        /**
         * [PRO-355376] Safari fails to detect objectFit with has. Normally it's returned
         * with a value of "".
         * has(this.document.documentElement.style, 'objectFit')
         * // CH: true,   value: ""
         * // FF: true,   value: ""
         * // SA: false,  value: ""
         * // IE: false,  value: undefined
         *
         * We have to use a combination of methods to properly support safari.
         */
        return !isUndefined(get(this.document.documentElement.style, 'objectFit'));
    }
}
