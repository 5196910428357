import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { EMPTY, of } from 'rxjs';
import { catchError, mergeMap, take } from 'rxjs/operators';

import { LoadingService } from '@finder/shared/services/loading-service/loading-service.service';
import { FinderApiConnectorService } from '@finder/shared/services/finder-api-connector/finder-api-connector.service';
import { FinderSeoService } from '@finder/shared/services/finder-seo/finder-seo.service';
import { TravelAgentService } from '@finder/shared/services/travel-agent/travel-agent.service';

@Injectable()
export class SearchResolver implements Resolve<any> {

    constructor(
        private loadingService: LoadingService,
        private finderApi: FinderApiConnectorService,
        private finderSeoService: FinderSeoService,
        private travelAgentService: TravelAgentService,
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        // Here we call the services to get the data ready
        // for the search page

        // Reset of Error state to avoid getting Error message stuck after back button pressed GIT-31197
        this.loadingService.setErrorState(null);
        this.loadingService.setLoadingState(false);

        // If we try to go to a trade page but we don't have the session cookie, show an error.
        const tradeError = this.travelAgentService.showErrorIfCookieIsMissing();
        if (tradeError) {
            return;
        }

        const routeSearchQuery = route.queryParams['searchQuery'];

        return new Promise((resolve, reject) => {
            this.finderApi.getSearchQuery(routeSearchQuery, 0)
                .pipe(
                    catchError((error) => {
                        this.loadingService.setErrorState(error);
                        this.loadingService.setLoadingState(true);

                        // Is needed resolve the promise so the url redirection will be carried out
                        // when we want to show our error messaging page
                        resolve(error);

                        // This doesn't really do anything other an stop the error from a
                        // method that wants an Observable returned to it
                        return EMPTY;
                    }),
                    take(1)
                ).subscribe(result => {
                    // Set SEO
                    this.finderSeoService.getPageKeyAndSetSeo();

                    // Disable loading spinner
                    this.loadingService.setLoadingState(true);

                    resolve(result);
                });
        });
    }
}
