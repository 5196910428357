/**
 * Safe JSON.parse
 *
 * @param value - The value to be parsed
 */
export const safeJSONParse = (value): any | SafeJSONParseErrorResult => {
    try {
        return JSON.parse(value);
    } catch (e) {
        return {
            error: true,
            message: e.message,
            originalValue: value,
        } as SafeJSONParseErrorResult;
    }
};

export interface SafeJSONParseErrorResult {
    error: boolean;
    message: string;
    originalValue: any;
}
