import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, Subject, asapScheduler, defer, from, of, throwError } from 'rxjs';
import { catchError, filter, map, retry, switchMap, take } from 'rxjs/operators';
import get from 'lodash-es/get';

import { API_CONSTANTS, CONFIG_CONSTANTS, LOGIN_URL } from '@finder/shared/constants/app.constants';
import { ConfigService } from '@finder/core/config.service';
import getApiBase from '@finder/shared/utils/string/getApiBase';
import { WindowRef } from '@finder/shared/services/window-ref/window-ref.service';
import { IRedirectParams } from './authentication.interfaces';
import { OneIdService } from '@finder/shared/services/one-id/one-id.service';
import { Guest, GuestProfile, OneIdResponse } from '@finder/shared/services/one-id/one-id.interface';

const apiUrlKey = CONFIG_CONSTANTS.apiUrlKey;
const authEndpoint = API_CONSTANTS.authEndpoint;
const authStatusEndpoint = API_CONSTANTS.authStatusEndpoint;
const defaultVersionKey = CONFIG_CONSTANTS.defaultVersionKey;
const jwtCookieName = API_CONSTANTS.jwtCookieName;

const loggerBaseMessage = 'AUTHENTICATION SERVICE -';
const publicTokenCookieName = 'finderPublicTokenExpireTime';

/**
 * Gets an auth cookie from the api
 */
@Injectable()
export class AuthenticationService {
    private oneIdService: OneIdService;
    private oneIdServiceReady$ = new BehaviorSubject(null);

    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private logger: LoggerService,
        private cookie: CookieService,
        private windowRef: WindowRef,
        private ondIdServiceResolver: OneIdService
    ) {
        defer(() => from(this.ondIdServiceResolver as any)).pipe(
            take(1),
        ).subscribe({
            next: (service: OneIdService) => {
                this.oneIdService = service;
                this.oneIdServiceReady$.next(true);
            }
        });
    }

    redirectToLogin(redirectParams: Partial<IRedirectParams> = {}, params?: any) {
        const nativeWindow = this.windowRef.nativeWindow;
        let url = this.getLoginUrlPath(nativeWindow, redirectParams, params);
        if (redirectParams.cancelUrl) {
            // the following regExp will catch a trailing slash if exist
            url = url.replace(/\/?$/, `&cancelUrl=${redirectParams.cancelUrl}`);
        }
        nativeWindow.open(url, '_self');
    }

    getLoginUrlPath(nativeWindow, redirectParams: Partial<IRedirectParams> = {}, params = {}) {
        // Check for existing params and add anything we pass along
        const paramsObj = new URLSearchParams(nativeWindow.location.search);
        if (params) {
            Object.keys(params).forEach(key => {
                paramsObj.append(key, params[key]);
            });
        }
        // Encode the params so they're passed along properly
        let queryParams = paramsObj.toString();
        if (queryParams) {
            queryParams = encodeURIComponent(`?`) + queryParams;
        }
        let returnUrl = redirectParams.returnUrl || nativeWindow.location.pathname;
        returnUrl = encodeURIComponent(returnUrl);

        return `${LOGIN_URL}?returnUrl=${returnUrl}${queryParams}`;
    }

    /**
     * Gets an auth cookie
     */
    getAuthToken(
        version: string = this.config.getValue(defaultVersionKey),
        baseUrl: string = this.config.getValue(apiUrlKey)
    ): Observable<any> {
        if (this.isPublicTokenValid()) {
            return of(true);
        }
        const apiBase = getApiBase(baseUrl, version);
        const url = `${apiBase}/${authEndpoint}`;

        return this.http.post(url, {})
            .pipe(
                map((response: any) => {
                    if (response && response.result && response.result.expires_in) {
                        this.setTokenCookie(response.result.expires_in);
                    }

                    return response;
                }),
                catchError((err) => throwError(() => new Error(err))),
                retry(4)
            );
    }

    /**
     * removes the token cookie
     */
    removeTokenCookie(): void {
        this.cookie.delete(publicTokenCookieName, '/');
    }

    /**
     * Checks if the current token is expired
     * based on the existence of the token cookie.
     */
    isPublicTokenValid(): boolean {
        let isValid = false;
        const expireTimeCookie = this.cookie.get(publicTokenCookieName);
        if (expireTimeCookie) {
            isValid = Number(expireTimeCookie) - new Date().getTime() > 0;
            if (!isValid) {
                // remove the cookie if token is expired.
                this.removeTokenCookie();
            }
        }

        return isValid;
    }

    /**
     * Retrieves the login status of the guest. If specified, can return the guest's SWID.
     * By default, all login checks are secured. Non secure login checks relies on JWT cookie which could be altered.
     *
     * @param getSWID If this param is set, returns the guest's SWID, otherwise false.
     * @param forceSecure If this param is set to true forces secure login check.
     */
    isLoggedIn(getSWID: boolean = false, forceSecure: boolean = true): Observable<boolean | string> {
        let SWID;
        const loggedIn$ = new Subject<string | boolean>();
        const loggedInObservable = loggedIn$.asObservable();

        const requestLoginStatus = () => {
            // Wait for the service to become available
            this.oneIdServiceReady$.asObservable()
                .pipe(
                    filter(result => result),
                    take(1),
                    switchMap(() => this.oneIdService.getLoggedInStatus())
                )
                .subscribe({
                    next: (response) => {
                        const isLoggedIn = get(response, 'isLoggedIn', false);
                        const isSecure = get(response, 'isSecure', false);

                        if (isLoggedIn && isSecure) {
                            SWID = get(response, 'swid', false);
                            loggedIn$.next(getSWID ? SWID : !!SWID);

                            return;
                        }
                        loggedIn$.next(false);
                    },
                    error: (error: HttpErrorResponse) => {
                        // If we don't need a secure check for the swid just return false at this point
                        if (!forceSecure) {
                            loggedIn$.next(false);
                            loggedIn$.complete();

                            return;
                        }

                        this.logger.error(`${loggerBaseMessage} Authentication Checking FAILED`);
                        loggedIn$.error(error);
                    }
                });
        };

        if (forceSecure) {
            requestLoginStatus();

            return loggedInObservable;
        }

        // This will allow the subject to return an observable we can use during this
        // sync operation so the async can also run.
        asapScheduler.schedule(() => {
            /**
             * We shouldn't use the pep_jwt_token to get the swid. This is going away. Just
             * grab the swid from the getGuest method.
             */
            this.oneIdServiceReady$.asObservable()
                .pipe(
                    filter(result => result),
                    take(1),
                    switchMap(() => from(this.oneIdService.getGuest()))
                )
                .subscribe({
                    next: (guest: OneIdResponse<Guest>) => {
                        const profile: GuestProfile = guest.data.profile;
                        loggedIn$.next(profile.swid);
                        loggedIn$.complete();
                    },
                    error: err => {
                        /**
                         * If the guest is not logged in it will call the status endpoint as
                         * last resort.
                         */
                        requestLoginStatus();
                    }
                });
        });

        return loggedInObservable;
    }

    /**
     * Returns the guest's SWID if it's logged-in.
     */
    getSWID(forceSecure: boolean = true): Observable<boolean | string> {
        return this.isLoggedIn(true, forceSecure);
    }

    /**
     * Checks if the user is logged in and if its not
     * redirects to the login page.
     */
    validateLogin(forceSecure: boolean = true, redirectParams?: Partial<IRedirectParams>, params?: any): Observable<any> {
        const validLogin$ = new Subject<string>();

        const openLightbox = () => {
            this.oneIdServiceReady$.asObservable()
                .pipe(
                    filter(result => result),
                    take(1),
                    switchMap(() => this.oneIdService.loginWithLightbox())
                )
                .subscribe({
                    next: (swid) => {
                        validLogin$.next(swid);
                        validLogin$.complete();
                    },
                    error: (error) => {
                        validLogin$.error(error);
                        validLogin$.complete();
                    }
                });
        };

        this.getSWID(forceSecure).subscribe({
            next: (swid: any) => {
                if (swid) {
                    validLogin$.next(swid);
                    validLogin$.complete();
                } else {
                    openLightbox();
                }
            },
            error: (err) => {
                openLightbox();
            }
        });

        return validLogin$.asObservable();
    }

    getJwtBody() {
        let jwtBody = {};
        const jwtCookie = this.cookie.get(jwtCookieName);
        if (jwtCookie) {
            try {
                jwtBody = JSON.parse(atob(jwtCookie.split('.')[1]));
            } catch (e) {
                this.logger.error(`${loggerBaseMessage} PEP JWT Cookie isn't valid.`);
            }
        }

        return jwtBody;
    }

    /**
     * Sets a cookie that will expire 1 min before the
     * actual token expires.
     */
    private setTokenCookie(expireTime): void {
        let expireIn = Number(expireTime);
        // expire the token 1 min sooner
        // to make sure we renew the token at a safe moment.
        expireIn = expireIn > 60 ? expireIn - 60 : expireIn;

        let expireDate = new Date().getTime();
        expireDate += expireIn * 1000;

        this.cookie.set(
            publicTokenCookieName,
            expireDate.toString(),
            null, // this cookie will expire with the browser session
            '/'
        );
    }
}
