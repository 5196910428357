import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { FooterConfig } from './footer.class';

@Injectable({
    providedIn: 'root'
})
export class  FooterService {
    private footerSubject = new Subject<FooterConfig>();

    constructor() { }

    watchFooter(): Observable<FooterConfig> {
        return this.footerSubject.asObservable();
    }

    emitFooter(footerConfig: FooterConfig) {
        this.footerSubject.next(footerConfig);
    }

    restoreDefault() {
        this.footerSubject.next(new FooterConfig());
    }
}
