/* istanbul ignore next */
/**
 * @description     Gets the web API base URL from the provided base and version. The motivation
 *                  for creating this utility helper was to allow for the elimination of a version
 *                  when running the local static data service.
 * @param base      the web API base.
 * @param version   (optional) the web API version.
 */
const getApiBase = (base: string, version?: string): string => {
    let result = base;
    if (version) {
        // Strip any slashes
        version = version.replace(/\//g, '');

        // Merge the values, but make sure we don't wind up with a double slash in the path
        result = (result + '/').replace(/\/\/$/, '/') + version;
    }

    // Return the string stripped of any trailing slash
    return result.replace(/\/$/, '');
};

export default getApiBase;
