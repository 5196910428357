import { Injectable, Injector } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import { ConfigService } from '@finder/core/config.service';
import { CONFIG_CONSTANTS } from '@finder/shared/constants/app.constants';

@Injectable()
export class TranslationHelperService {

    constructor(private injector: Injector) {}

    skipTranslation(req: HttpRequest<any>, splitValue: string = '', isDefaultLocale: boolean = false) {
        const configService = this.injector.get(ConfigService);
        const allowedLocations = ['wdw'];
        const skipTranslation = [
            // non finder call
            !req.url.includes(splitValue),
            // site does not require translation
            !allowedLocations.includes(configService.getValue(CONFIG_CONSTANTS.siteIdKey)),
            // english/GDPR locale
            isDefaultLocale,
            // authz call
            req.url.includes('authz'),
        ];

        // Do not translate if any condition is met
        if (skipTranslation.includes(true)) {
            return true;
        }

        return false;
    }

}
