import { InjectionToken } from '@angular/core';

/**
 * Parses the templatized HTML string topull out the anchor text and href.
 * PhantomJS doesn't have DOMParser, so we'll just skip this for coverage.
 */
/* istanbul ignore next */
export const getAnchorFromHtmlString = (data): any => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data, 'text/html');
    if (!doc) {
        return {};
    }

    const anchor = doc.querySelector('a');

    if (!anchor) {
        return {};
    }

    return { href: anchor.href, text: (anchor.innerText || '').trim() };
};

export type GetAnchorFromHtmlStringProvider = (data: any) => any;
export const GET_ANCHOR_FROM_HTML_STRING =
    new InjectionToken('GET_ANCHOR_FROM_HTML_STRING', {
        factory: () => getAnchorFromHtmlString
    });
