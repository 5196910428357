import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { PathLocationStrategy } from '@angular/common';

import { AppModule } from 'app/app.module';
import { environment } from 'environments/environment';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));

// eslint-disable-next-line @typescript-eslint/naming-convention
const _orig_prepareExternalUrl = PathLocationStrategy.prototype.prepareExternalUrl;
