export const THEME_DEFAULT = 'Hyperion';
export const THEME_COOKIE = 'demo-theme';
export const THEME_LIST = [
    'AC',
    'AP',
    'Aulani',
    'CC',
    'D100',
    'DAK',
    'DHS',
    'EPCOT',
    'Eve',
    'Genie',
    'GotG',
    'GSC',
    'Hyperion',
    'Magical-Firsts',
    'MBP',
    'Mochi',
    'MK',
    'SWGE',
    'Toontown',
    'Tron',
    'VIP'
];

// If you want the header/footer to use dark mode for any theme, add it below
export const THEME_DARK_MODE = {
    gsc: 'gscTheme',
};
