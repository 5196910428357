import { Injectable } from '@angular/core';
import { LocaleHelperService } from '@finder/shared/services/locale-service/locale-helper.service';
import { WindowRef } from '@finder/shared/services/window-ref/window-ref.service';

@Injectable()
export class WebLinkValidationService {

    constructor(
        private localeHelper: LocaleHelperService,
        private windowRef: WindowRef
    ) {}

    validateWebLink(webLink): void {
        if (!webLink) {
            // if there is no webLink in service just return.
            return;
        }
        webLink = this.normalizeHref(webLink);
        const location = this.windowRef.nativeWindow.location;
        const currentPath = this.normalizeHref(location.pathname);

        /**
         * Check to see if the current browser uri is contained within the webLink property.
         * This may include a modal deep link.
         * e.g.,:
         *  webLink: /dining/hollywood-studios/50s-prime-time-cafe
         *  currentPath: /dining/hollywood-studios/50s-prime-time-cafe/availability-modal
         */
        if (!currentPath.includes(webLink)) {
            // webLink is not within the current path, so need to redirect
            // Get proper URL
            const properUrl = new URL(
                `${webLink}${location.search || ''}${location.hash || ''}`,
                location.origin
            );

            // Externally navigate to URL via replace() to ensure Akamai redirects take
            // effect. Previous version manipulated URL history with replaceState() but
            // this kept the redirects in-SPA which caused issues; see GIT-42238
            this.windowRef.nativeWindow.location.replace(properUrl.href);
        }
    }

    /**
     * Turns an HREF into a "normalized" path
     * Absolute links:
     *  Input:   https://domain.com/this/that/
     *  Output:  /this/that
     * Relative links:
     *  Input:   /this/that/
     *  Output:  /this/that
     */
    private normalizeHref(webLink: string): string {
        // trim (for safety)
        webLink = webLink.trim();

        // remove domain
        if (webLink.startsWith('http')) {
            webLink = new URL(webLink).pathname;
        }

        // remove locale
        webLink = this.localeHelper.stripLocale(webLink);

        return webLink;
    }
}
