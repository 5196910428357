<div class="promo-tile {{background}}-bg">
    <div class="promo-tile-content personalized-content">
        <div class="item icon">
            <finder-font-icon [name]="icon" [title]="icon" size="inherit"></finder-font-icon>
        </div>
        <div class="item text">
            <h4 class="title" [innerHTML]="title | safeHtml"></h4>
            <div class="subtitle" [ngClass]="{'no-subtitle': !subtitle}">
                <div [outerHTML]="subtitle | safeHtml"></div>
                <finder-icon-tooltip *ngIf="tooltip"
                    [id]="tooltip.id"
                    [name]="tooltip.name"
                    [size]="tooltip.size"
                    [tooltip]="tooltip.text"
                    [position]="tooltip.position">
                </finder-icon-tooltip>
            </div>
        </div>
        <finder-button *ngIf="link" [href]="link" class="item button" [theme]="btnThemeClass">
            {{buttonText}}
        </finder-button>
    </div>
</div>
