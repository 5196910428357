import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SeoModule } from '@wdpr/ra-angular-seo-metadata';
import { PageKeyModule } from '@wdpr/ra-angular-page-key';
import { AnalyticsModule } from '@wdpr/angular-analytics';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectionModule } from '@wdpr/ra-angular-device-detection';
import { FEATURE_TOGGLE_CONFIG, FeatureToggleModule } from '@wdpr/ra-angular-feature-toggle';
// REMOVING P13N UNTIL WE MOVE TO ANGULAR !%, DUE TO DEPENDENCIES ISSUES
// import { PersonalizationModule, P13N_LOADER, P13N_DECISION_ATTRS_LOADER, P13N_BASE } from '@wdpr/ngx-personalization';
import { UrlSerializer } from '@angular/router';
import { GeolocationService, GeolocationModule } from '@wdpr/ra-angular-geolocation';

import { AnimationService } from '@finder/shared/components/animations/animation-service/animation.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthenticationService } from '@finder/shared/services/authentication/authentication.service';
import { AuthInterceptor } from '@finder/shared/interceptors/auth-interceptor/auth-interceptor.service';
import { CalendarsService } from '@finder/features/calendars/services/calendar.service';
import { ComponentsModule } from '@finder/shared/components/components.module';
import { CONFIG_CONSTANTS, SEO_OPTIONS, SITE_ID } from '@finder/shared/constants/app.constants';
import { ConfigService } from '@finder/core/config.service';
import { CoreModule } from '@finder/core/core.module';
import { CustomUrlSerializer } from '@finder/shared/utils/url/customUrlSerializer';
import { DebugLogService } from '@finder/shared/services/debug-log/debug-log.service';
import { DineToFinderErrorModalModule } from '@finder/shared/components/dine-to-finder-error-modal/dine-to-finder-error-modal.module';
import { ExplorerServiceConnectorService } from '@finder/shared/services/explorer-svc-connector/explorer-svc-connector.service';
import { DiningCalendarDaysService } from '@finder/shared/services/dining-calendar-days/dining-calendar-days.service';
import { FinderAnalyticsService } from '@finder/shared/services/finder-analytics/finder-analytics.service';
import { FinderApiConnectorService } from '@finder/shared/services/finder-api-connector/finder-api-connector.service';
import { FinderPersonalizationService } from '@finder/shared/services/finder-analytics/finder-personalization.service';
import { FinderThemeService } from '@finder/shared/services/theme-service/finder-theme.service';
import { geoConfig } from '@finder/common/geo-config.constants';
import { HeaderOverrideInterceptor } from '@finder/shared/interceptors/header-overrides-interceptor/header-overrides.interceptor';
import { InterstitialService } from '@finder/shared/services/interstitial-service/interstitial-service.service';
import { LanguageBundleConnectorService } from '@finder/shared/services/language-bundle/language-bundle-connector.service';
import { LayoutService } from '@finder/shared/services/layout/layout.service';
import { LAZY_MODULES } from '@finder/shared/services/lazy-loader/tokens';
import { lazyArrayToObj } from '@finder/shared/services/lazy-loader/lazy-bundles';
import { LazyLoaderService } from '@finder/shared/services/lazy-loader/lazy-loader-service';
import { ListServiceConnectorService } from '@finder/shared/services/list-svc-connector/list-svc-connector.service';
import { LoadingService } from '@finder/shared/services/loading-service/loading-service.service';
import { LocaleHelperService } from '@finder/shared/services/locale-service/locale-helper.service';
import { LocaleInterceptor } from '@finder/shared/interceptors/locale/locale-interceptor.service';
import { LocaleService } from '@finder/shared/services/locale-service/locale-service.service';
import { OneIdFactory } from '@finder/shared/services/one-id/one-id.factory';
import { OneIdService } from '@finder/shared/services/one-id/one-id.service';
import { ReservationServiceConnectorService } from '@finder/shared/services/reservation-svc-connector/reservation-svc-connector.service';
import { RouteStateService } from '@finder/shared/services/route-state-service/route-state-service';
import { SearchServiceConnectorService } from '@finder/shared/services/search-svc-connector/search-svc-connector.service';
import { SiteIdInterceptor } from '@finder/shared/interceptors/site-id/site-id-interceptor.service';
import { TranslationHelperService } from '@finder/shared/interceptors/translation/translation-helper.service';
import { TranslationInterceptor } from '@finder/shared/interceptors/translation/translation-interceptor.service';
import { TravelAgentService } from '@finder/shared/services/travel-agent/travel-agent.service';
import { ViewportDetection } from '@finder/shared/services/viewport-detection/viewport-detection.service';
import { WebLinkValidationService } from '@finder/shared/services/web-link-validation/web-link-validation.service';
import { WindowRef } from '@finder/shared/services/window-ref/window-ref.service';
import { TokenInterceptor } from './shared/interceptors/token/token-interceptor.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let __webpack_public_path__: string;

const APP_PROVIDERS = [
    AnimationService,
    AuthenticationService,
    CalendarsService,
    CookieService,
    DiningCalendarDaysService,
    ExplorerServiceConnectorService,
    FinderAnalyticsService,
    FinderApiConnectorService,
    FinderPersonalizationService,
    FinderThemeService,
    GeolocationService,
    LanguageBundleConnectorService,
    ListServiceConnectorService,
    LoadingService,
    InterstitialService,
    LayoutService,
    LocaleHelperService,
    LocaleService,
    ReservationServiceConnectorService,
    RouteStateService,
    SearchServiceConnectorService,
    TranslationHelperService,
    TravelAgentService,
    ViewportDetection,
    WebLinkValidationService,
    WindowRef,
];
@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        AnalyticsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        ComponentsModule,
        CoreModule,
        DeviceDetectionModule,
        DineToFinderErrorModalModule,
        FeatureToggleModule,
        GeolocationModule.configure(geoConfig),
        HttpClientModule,
        PageKeyModule,
        // PersonalizationModule,
        SeoModule,
        ServiceWorkerModule.register('/advanced-finder/web-workers/ngsw-worker.js', {
            //enabled: environment.production,
            enabled: false,
            scope: '/',
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
    ],
    providers: [
        {
            provide: UrlSerializer,
            useClass: CustomUrlSerializer
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (config: ConfigService, auth: AuthenticationService) => () =>
                config.load()
                    .then((result: any) => {
                        const baseUrl = config.getValue(CONFIG_CONSTANTS.apiUrlKey);
                        const version = config.getValue(CONFIG_CONSTANTS.defaultVersionKey);
                        const cdnList = config.getValue(CONFIG_CONSTANTS.cdnListKey);
                        const cdnPath = config.getValue(CONFIG_CONSTANTS.cdnPathKey);
                        // Set __webpack_public_path__ so the chunks js bundles
                        // for lazy loading has the correct CDN domain.
                        __webpack_public_path__ =
                            (cdnList && cdnList.length > 0 ? cdnList[0] : '') + cdnPath;
                        // ask for token early when the app initializes.
                        auth.getAuthToken(version, baseUrl).subscribe();

                        return Promise.resolve();
                    })
                    .catch((err) => err),
            deps: [ConfigService, AuthenticationService],
            multi: true,
        },
        {
            provide: 'LOGASAURUS_URL',
            useValue: '/advanced-finder/log',
        },
        APP_PROVIDERS,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LocaleInterceptor,
            multi: true,
        },
        // Per https://myjira.disney.com/browse/GIT-33664, removing SPA override for T.com
        // in prepration for Small World
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TranslationInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SiteIdInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: 'SEO_OPTIONS',
            useFactory: (config: ConfigService) => {
                if (config.getSiteId() === SITE_ID.hkdl) {
                    SEO_OPTIONS.includeMetaTag.canonical = true;
                }

                return SEO_OPTIONS;
            },
            deps: [ConfigService],
        },
        {
            provide: FEATURE_TOGGLE_CONFIG,
            useFactory: (config: ConfigService) => {
                const webApiUrl = `${config.getValue('webApiUrl')}/${config.getValue('webApiDefaultVersion')}`;

                return {
                    appTogglesName: 'appToggles',
                    featureToggleUrl: webApiUrl + '/toggles',
                };
            },
            deps: [ConfigService],
        },
        {
            provide: 'PAGE_KEY_URL_ENDPOINT',
            useValue: '/advanced-finder/page-key/',
        },
        {
            provide: 'PAGE_KEY_TEMPLATE',
            useValue: [
                'siteId', 'path',
            ],
        },
        // REMOVING P13N UNTIL WE MOVE TO ANGULAR 15, DUE TO DEPENDENCIES ISSUES
        // {
        //     provide: P13N_LOADER,
        //     useFactory: (finderAnalyticsService: FinderAnalyticsService) => () =>
        //         finderAnalyticsService.getGuestModel().toPromise(),
        //     deps: [FinderAnalyticsService],
        // },
        // {
        //     provide: P13N_DECISION_ATTRS_LOADER,
        //     useFactory: (finderAnalyticsService: FinderAnalyticsService) => () =>
        //         finderAnalyticsService.getDecisionAttributes(),
        //     deps: [FinderAnalyticsService],
        // },
        // {
        //     provide: P13N_BASE,
        //     useValue: {
        //         configuration: {
        //             CEA: {
        //                 decisionAttributesLoaderTimeout: 500,
        //             },
        //         },
        //     },
        // },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderOverrideInterceptor,
            multi: true
        },
        LazyLoaderService,
        {
            provide: LAZY_MODULES,
            useFactory: lazyArrayToObj
        },
        {
            provide: OneIdService,
            useFactory: OneIdFactory,
            deps: [WindowRef, DebugLogService],
        },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
