import { Inject, Injectable } from '@angular/core';
import { PageKeyService } from '@wdpr/ra-angular-page-key';
import { Observable, BehaviorSubject } from 'rxjs';
import { IsValidDateString, IS_VALID_DATE_STRING } from '../../utils/date/isValidDateString';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class FinderPageKeyService {
    private pageKey$ = new BehaviorSubject('');
    private fallbackPageKey = '';

    constructor(
        private pageKeyService: PageKeyService,
        @Inject(IS_VALID_DATE_STRING) private isValidDateString: IsValidDateString,
    ) {
        this.init();
    }

    pageKeyReady(): Observable<any> {
        return this.pageKey$.asObservable();
    }

    get() {
        return this.pageKey$.getValue();
    }

    clearPageKey() {
        this.pageKey$.next('');
    }

    setFallbackPageKey(url: string) {
        /**
         * We don't always have a fallback URL.
         *
         * Also relative urls will pass through. The original response from the detail request
         * will have the absolute path. Internal redirects will only have a relative value.
         */
        if (!url || !url.startsWith('http')) {
            return;
        }

        const parsedUrl = new URL(url);
        const pathParts = parsedUrl.pathname.split('/');

        // Toss the first value
        pathParts.shift();

        if (pathParts.length > 0) {
            this.fallbackPageKey = pathParts[0];
        }
    }

    getFallbackPageKey() {
        return this.fallbackPageKey;
    }

    stripTrailingPageKeyDate(pageKey: string, asString: boolean = false, keepSiteId = false) {
        // Split the page key value on "dots", ignoring any trailing dot
        // Then, splice off the first part of the array post-split as it is the siteId
        /**
         * NOTE
         * Split the whole thing and just look at the last part. Then
         * put everything back together.
         */
        const pageKeyParts = pageKey.replace(/\.$/, '').split('.');

        // The SEO service needs the siteId but not a possible date at the end
        if (!keepSiteId) {
            pageKeyParts.shift();
        }

        // check if the last part of the pagekey is a date; if so, remove it as it will break the SEO lookup
        if (this.isValidDateString(pageKeyParts.at(-1))) {
            pageKeyParts.pop();
        }

        return asString ? pageKeyParts.join('.') : pageKeyParts;
    }

    getParts() {
        const pageKeyParts = this.getPageKeyParts();

        const result: any = {
            type: pageKeyParts.at(0),
            friendlyId: pageKeyParts.at(-1)
        };

        // Check if there is the parkName
        // The format of the page key should be one of two:
        // type.parkName.friendlyId or type.friendlyId
        result.parkName = pageKeyParts.length === 3 ? pageKeyParts[1] : '';

        return result;
    }

    getPageKeyParts() {
        return this.stripTrailingPageKeyDate(this.get());
    }

    private init() {
        this.pageKeyService
        .pipe(
            map(pk => {
                this.pageKey$.next(pk.key);
            })
        )
        .subscribe();
    }
}
