import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SystemErrorComponent } from '@finder/shared/components/system-error/system-error.component';
import { SafeHtmlPipeModule } from '@finder/shared/utils/pipes/safe-html/safe.html.module';

@NgModule({
    imports: [
        CommonModule,
        SafeHtmlPipeModule,
    ],
    declarations: [
        SystemErrorComponent,
    ],
    exports: [
        SystemErrorComponent,
    ],
})
export class SystemErrorModule {}
