import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import isEmpty from 'lodash-es/isEmpty';

import {
    OneIdResponse,
    OneIdNewsletter,
    OneIdNewsletterMarketingEntry,
    OneIdNewsletterLegalEntry,
    OneIdNewsletterRequest,
    Guest,
    OneIdOptions,
    OneIdInstance,
    GuestProfile
} from './one-id.interface';
import { CLOSE, LOGIN } from './one-id.constants';
import { ONE_ID_INSTANCE } from '@finder/shared/services/one-id/one-id.factory';
import { DebugLogService } from '@finder/shared/services/debug-log/debug-log.service';

export type OneIdEvents
    = 'age-gated' | 'close' | 'create' | 'error' | 'init' | 'login' | 'logout' | 'low-trust'
    | 'opt-in' | 'primary-account' | 'reauth' | 'refresh' | 'update';

const NEWSLETTER_SUBSCRIPTION_STATUS_COOKIE = 'newslettersSubscriptionStatus';

@Injectable()
export class OneIdService {
    private newslettersCache: OneIdResponse<OneIdNewsletter>;
    private guestProfile: OneIdResponse<Guest>;

    constructor(
        @Inject(ONE_ID_INSTANCE) private oid: Partial<OneIdInstance>,
        private debugLog: DebugLogService,
    ) {}

    getGuest(): Promise<OneIdResponse<Guest>> {
        return new Promise((resolve, reject) => {
            const errMsg = { 'msg': 'Guest not logged in'};
            /**
             * The reason we're now caching the guest profile is that a 2nd call
             * to the endpoint is causing Angular to render components before
             * they're ready. If we return the private response things work
             * perfect.
             */

            // Check to see if we have a profile from a previous call
            if (this.guestProfile) {
                resolve(this.guestProfile);

                return;
            }

            // Go get the profile, cache the result and resolve the promise
            this.oid.getGuest().then((result: any) => {
                // The result can come back null. Check first
                if (!result) {
                    return reject(errMsg);
                }

                // Conform to the generic
                this.guestProfile = {
                    data: result
                };

                resolve(this.guestProfile);
            }).catch(() => reject(errMsg));
        });
    }

    getGuestNewsletters(): Promise<OneIdResponse<OneIdNewsletter[]>> {
        return this.oid.getGuestNewsletters();
    }

    optIn(email, campaignId, options: OneIdOptions = {}) {
        return this.getInlineNewsletters(campaignId, options)
            .then((response: OneIdResponse<OneIdNewsletter>) => {
                const payload = this.buildInlineNewsletterRequest(campaignId, email, response);
                this.setInlineNewsletters(payload, options);

                return response;
            });
    }

    /* istanbul ignore next */
    on(event: OneIdEvents, listener: () => void) {
        this.oid.on(event, listener);
    }

    /* istanbul ignore next */
    off(event: OneIdEvents, listener?: () => void) {
        this.oid.off(event, listener);
    }

    /**
     * Retrieves a list of configured newsletter campaigns; additionally, legal guidelines are provided if required.
     * This method returns a promise, that, when fulfilled, provides the integrator with data needed to render
     * a newsletter campaign to a user.
     */
    getInlineNewsletters(campaignId, options = {}, forceRefresh = false): Promise<OneIdResponse<OneIdNewsletter>> {
        return new Promise((resolve, reject) => {
            /* istanbul ignore if */
            if (!forceRefresh && this.newslettersCache) {
                resolve(this.newslettersCache);
            }

            this.oid?.getInlineNewsletters(campaignId, options)
                .then((response: OneIdResponse<OneIdNewsletter>) => {
                    this.newslettersCache = response;
                    resolve(response);
                });
        });
    }

    launchNewsletters(campaignId, options: any = {}): Promise<void> {
        // Make sure the options are formatted properly
        let optionalConfig;
        if (!isEmpty(options) && !options.optionalConfig) {
            optionalConfig = {
                optionalConfigs: {
                    reporting: options,
                }
            };
        } else {
            optionalConfig = options;
        }

        return this.oid.launchNewsletters(campaignId, optionalConfig);
    }

    getCookieKey(campaignId = 'unknown'): string {
        return `${NEWSLETTER_SUBSCRIPTION_STATUS_COOKIE}-${campaignId}`;
    }

    // TODO - Add logic here that calls a different endpoint for GDPR/EMEA
    // I cannot do this right now because OneID is not working
    signUp(campaignId, options: any = {}): Promise<void> {
        this.debugLog.info('***** oid', this.oid);

        return this.launchNewsletters(campaignId, options);
    }

    getLoggedInStatus(): Observable<object> {
        const status$ = new Subject<object>();
        const guestStatus = {
            isLoggedIn: false,
            isSecure: false,
            swid: null
        };

        // Let's make these calls at the same time
        Promise.all(
            [
                this.oid.getLoggedInStatus(),
                this.getGuest(),
                this.oid.getTrustStateStatus()
            ]
        ).then(([status, guestData, isSecure]) => {
            // The results will be boolean|null
            guestStatus.isLoggedIn = status;

            // Check before continuing
            if (!guestStatus.isLoggedIn) {
                status$.error(false);

                throw new Error('no guest data');
            }

            // Assign the swid
            guestStatus.swid = guestData.data.profile.swid;

            // Assign the trust status
            guestStatus.isSecure = isSecure === 1;

            status$.next(guestStatus);
        }).catch((error) => {
            status$.error(false);
        });

        return status$.asObservable();
    }

    loginWithLightbox(): Observable<string> {
        const login$ = new Subject<string>();

        const returnSwid = (result: OneIdResponse<Guest>) => {
            clearEvents();

            // The guest is logged in. Return their swid
            const profile: GuestProfile = result.data.profile;
            login$.next(profile?.swid);
            login$.complete();
        };

        const closeModal = () => {
            clearEvents();

            login$.error({ msg: 'Lightbox was closed' });
        };

        const clearEvents = () => {
            this.off(CLOSE, closeModal);
            this.off(LOGIN, clearEvents);
        };

        /**
         * The "login" event should just clear the listeners because the page will refresh.
         * If we don't the "close" event that happens after the guest logs in will clear
         * the modal part of the URI.
         *
         * We listen to the "close" event so if the guest closes the lightbox the modal
         * part of the URI is removed.
         */
        this.on(LOGIN, clearEvents);
        this.on(CLOSE, closeModal);

        /**
         * Try to get the guest information. If the guest isn't logged in
         * then it will trigger the error case and the login lightbox is
         * opened.
         */
        this
            .getGuest()
            .then(returnSwid)
            .catch(err => this.oid.launchLogin());

        /**
         * For some reason once `this.oid.getGuest()` returns from the callback the
         * src/app/shared/components/modal/modal.component.ts: open method won't wait until
         * the "this.childComponentRef.instance.data" assignment before creating the component.
         * This leads to an empty "data" property that causes errors when the modal is rendered.
         *
         * If I use stub Observers and Promises the modal creation works fine.
         *
         * The way around this is to cache the `getGuest` response and return that for future calls.
         * Now the modal will render properly. `getGuest` is called when we make the initial data request
         * for the page as part of the Shuri/Hawkeye pipeline updates.
         */

        return login$.asObservable();
    }

    // eslint-disable-next-line max-len
    private buildInlineNewsletterRequest(campaignId, email, response: OneIdResponse<OneIdNewsletter>): OneIdNewsletterRequest {
        const marketing = response.data.marketing.map((entry: OneIdNewsletterMarketingEntry) => ({
            code: entry.code,
            subscribed: true
        }));

        const legal = response.data.legal.map((entry: OneIdNewsletterLegalEntry) => ({
            code: entry.code,
            accepted: true
        }));

        return {
            campaignId,
            email,
            marketing,
            legal,
        };
    }

    private setInlineNewsletters(data: OneIdNewsletterRequest, options: OneIdOptions) {
        return this.oid.setInlineNewsletters(data, options);
    }
}
