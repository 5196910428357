import { Component, Input } from '@angular/core';

@Component({
    selector: 'finder-icon-tooltip',
    templateUrl: './icon-tooltip.component.html',
    styleUrls: ['./icon-tooltip.component.scss']
})
export class IconTooltipComponent {
    @Input() name: string;
    @Input() title: string;
    @Input() size: string;
    @Input() color: string;
    @Input() tooltip: string;
    @Input() position: string;
    @Input() id: Readonly<string>;

    constructor() { }
}
