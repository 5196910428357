import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CONFIG_CONSTANTS, API_CONSTANTS } from '@finder/shared/constants/app.constants';
import { ConfigService } from '@finder/core/config.service';
import getApiBase from '@finder/shared/utils/string/getApiBase';
import { SearchSelection } from '@finder/features/search/interfaces/search-selection.interface';

@Injectable()
export class SearchServiceConnectorService {

    private baseUrl: string;
    private version: string;
    private siteId: string;

    constructor(
        private http: HttpClient,
        private config: ConfigService
    ) {
        this.baseUrl = this.config.getValue(CONFIG_CONSTANTS.apiUrlKey);
        this.version = this.config.getValue(CONFIG_CONSTANTS.defaultVersionKey);
        this.siteId = this.config.getValue(CONFIG_CONSTANTS.siteIdKey);
    }

    /**
     * Get Search results for a given term.
     *
     * @param searchTerm the query to search
     * @param pageNumber the current page number used for pagination
     * @param swid the user swid
     * @param version web api version
     * @returns an observable
     */
    public getSearchQuery(searchTerm: string, pageNumber: number, swid?: string | boolean, version?: string): Observable<any> {
        let url = this.apiBaseUrl(API_CONSTANTS.searchQueryEndpoint, this.baseUrl, version);
        const swidParam = swid ? `&swid=${swid}` : '';
        url = `${url}/${this.siteId}/?term=${encodeURIComponent(searchTerm)}&page=${pageNumber}${swidParam}`;

        return this.http.get(url);
    }

    /**
     * Post Search Selection for analytics history of the user
     *
     * @param searchId id of the search, changes on pagination or term
     * @param documentId the current page number used for pagination
     * @param swid the user swid
     * @returns an observable
     */
    public postSearchSelectionHistory({searchId, documentId, title, position}: SearchSelection, swid: string | boolean) {
        let url = this.apiBaseUrl(API_CONSTANTS.searchSelectionHistoryEndpoint, this.baseUrl);
        url = `${url}/${this.siteId}`;

        const body = {
            documentId,
            searchId,
            position,
            title,
            ...(swid) && {swid}
        };

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            keepalive: true
        });
    }

    /**
     * Constructs the options and endpoint URL
     *
     * @param endpoint  endpoint
     * @param baseUrl   base URL
     * @param version   api version -- defaulted to v1
     */
    private apiBaseUrl(endpoint: string, baseUrl: string, version: string = this.version): string {
        const apiBase = getApiBase(baseUrl, version);

        return `${apiBase}/${endpoint}`;
    }

}
