import { InjectionToken } from '@angular/core';

/**
 * @description Get a Date and validate if it is the current date or a future date
 * @param date  Date
 */
export const isCurrentOrFutureDate = (date: Date): boolean => {
    if (!date) {
        return false;
    }
    const today = new Date();
    today.setHours(0,0,0,0);

    const dateObj = new Date(date);
    dateObj.setHours(0,0,0,0);
    // Get the time difference
    const dateDifference =  dateObj.getTime() - today.getTime();

    // Divide the time difference by # of milliseconds in a day
    // 1000 milliseconds in a second.
    // 60 seconds * 60 minutes = 3600 seconds in an hour.
    // 24 hours in a day.
    const diffDays = Math.ceil(dateDifference / (1000 * 3600 * 24));

    return diffDays >= 0;
};

export type IsCurrentOrFutureDate = (date: Date) => boolean;
export const IS_CURRENT_OR_FUTURE_DATE = new InjectionToken(
    'IS_CURRENT_OR_FUTURE_DATE',
    {
        factory: () => isCurrentOrFutureDate,
    }
);
