import {UrlSerializer, UrlTree, DefaultUrlSerializer} from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomUrlSerializer implements UrlSerializer {
    parse(url: string): UrlTree {
        const dus = new DefaultUrlSerializer();

        return dus.parse(url);
    }

    serialize(tree: UrlTree): any {
        const dus = new DefaultUrlSerializer();
        const path = dus.serialize(tree);
        const pathArr = path.split('?');
        const joinSeparator = pathArr.length > 1 ? '?' : '';
        const containsHash = pathArr[0].indexOf('#') !== -1;
        const containsSlashHash = pathArr[0].indexOf('/#') !== -1;

        if (pathArr[0].slice(-1) !== '/' && !containsHash) {
            // If the last character isn't '/' and doesn't contain '#', then add the '/'
            pathArr[0] += '/';
        } else if (containsHash && !containsSlashHash) {
            // Make sure that the "trailing" slash is added to the URL when a hash is added
            pathArr[0] = pathArr[0].replace('#', '/#');
        }

        return pathArr.join(joinSeparator);
    }
}
