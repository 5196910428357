import getImageSize from './getImageSize';

const getDefaultImageSVGDataUri = (imageUrl, color = '000'): string => {
    const { width, height } = getImageSize(imageUrl);

    // eslint-disable-next-line max-len
    return `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='${width}' height='${height}' viewBox='0 0 ${width} ${height}'%3E%3Crect fill='%23${color}' width='${width}' height='${height}'/%3E%3C/svg%3E`;
};

export default getDefaultImageSVGDataUri;
