import {
    Injectable,
    Inject,
    ViewContainerRef,
    Injector,
    createNgModule,
    NgZone,
} from '@angular/core';
import { DOCUMENT, registerLocaleData } from '@angular/common';
import { Observable, of } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ConfigService } from '@finder/core/config.service';
import { CONFIG_CONSTANTS } from '@finder/shared/constants/app.constants';
import { LazyModulesProvider, LAZY_MODULES } from './tokens';
import { LoadCssResourceProvider, LOAD_CSS_RESOURCE } from '@finder/shared/utils/dom/loadCss';
import { LOAD_SCRIPT_RESOURCE, LoadScriptResourceProvider } from '@finder/shared/utils/dom/loadScript';

@Injectable({
    providedIn: 'root'
})
export class LazyLoaderService {
    private cdnBaseUrl = '';
    private env;
    private resourceRegistry = new Set();

    // For testing purposes
    get registryValues() {
        return this.resourceRegistry.values();
    }

    constructor(
        private config: ConfigService,
        private injector: Injector,
        private zone: NgZone,
        @Inject(LAZY_MODULES) private lazyModules: LazyModulesProvider,
        @Inject(DOCUMENT) private document: Document,
        @Inject(LOAD_CSS_RESOURCE) private loadCssResource: LoadCssResourceProvider,
        @Inject(LOAD_SCRIPT_RESOURCE) private loadScriptResource: LoadScriptResourceProvider,
    ) {
        this.env = this.config.getValue(CONFIG_CONSTANTS.envKey);
        if (this.env !== CONFIG_CONSTANTS.developmentEnv) {
            const cdnList = this.config.getValue(CONFIG_CONSTANTS.cdnListKey);
            const cdnPath = this.config.getValue(CONFIG_CONSTANTS.cdnPathKey);
            const cdnDomain = cdnList && cdnList.length > 0 ? cdnList[0] : '';
            this.cdnBaseUrl = cdnDomain + cdnPath;
        } else {
            this.cdnBaseUrl = '/';
        }
    }

    lazyLoadCssResource(src: string, useCdnBase = true) {
        // Check to make sure we don't add the same src over and over
        if (this.resourceRegistry.has(src)) {
            return;
        }

        // Add to registry
        this.addResourceToRegistry(src);

        // Setup URL
        const url = useCdnBase ? this.cdnBaseUrl + src : src;

        // Add src to doc
        this.loadCssResource(this.document, url);
    }

    lazyLoadScriptResource(src: string, useCdnBase = true): Observable<boolean> {
        // Check to make sure we don't add the same src over and over
        if (this.resourceRegistry.has(src)) {
            return of(true);
        }

        // Setup URL
        const url = useCdnBase ? this.cdnBaseUrl + src : src;

        // Add src to doc
        return this.loadScriptResource(this.document, url, this.zone)
            .pipe(finalize(() => {
                // Add to registry
                this.addResourceToRegistry(src);
            }));
    }

    lazyLoadModule(name: string, container: ViewContainerRef | any, data: any = {}) {
        return this.lazyModules[name]().then(tempModule => {
            // For Ivy
            const moduleRef = createNgModule(tempModule, this.injector);

            return this.createComponent(container, data, moduleRef);
        });
    }

    addResourceToRegistry(src) {
        this.resourceRegistry.add(src);
    }

    removeThemeFromRegistry(theme) {
        const match = Array.from(this.resourceRegistry)
            .find((res: string) => res.includes(theme));
        if (match) {
            this.resourceRegistry.delete(match);
        }
    }

    /**
     * Imports and register the default locale file lazily
     * from angular/common folder following the data and localeId in params.
     * We need to add every used local in webpackInclude to avoid bring all
     * locales in the folder due how dynamic import works.
     * See more: https://webpack.js.org/api/module-methods/#magic-comments
     */
    lazyLoadLocale(localePath: string, localeId: string): Promise<any> {
        return import(
            // eslint-disable-next-line max-len
            /* webpackInclude: /(es-AR|es-CL|es-CO|es-MX|es-PE|pt|en-CA|fr-CA|en-GB|en-150|en|es-US|zh-Hant|zh-Hans|ja|ko|th|id|ms)\.mjs$/ */
            `@/../@angular/common/locales/${localePath}.mjs`)
            .then(module => registerLocaleData(module.default, localeId))
            .catch((err) => console.log(err));
    }

    private createComponent(container: ViewContainerRef | any, data: any, moduleRef) {
        const entryComponent = (moduleRef.instance.constructor as any).entry;

        // if there is no container param just return the
        // component factory
        if (!container) {
            return entryComponent;
        }
        const componentRef = container.createComponent(
            entryComponent,
            {ngModuleRef: moduleRef}
        );

        Object.assign(componentRef.instance, data);

        return componentRef;
    };
}
