export const DATE_CONSTANTS = {
    timeZone: 'T02:00:00.000-07:00'
};

// Any locale date formats should be in lower case without dashes or underscores
export const DATE_FORMAT_CONSTANTS = {
    // see https://devhints.io/moment for explanation of the moment formatting values
    shortDateFormat: 'ddd, MMM D, YYYY',
    fullDateFormat: 'dddd, MMMM D, YYYY',
    scheduleDateFormat: 'YYYY-MM-DD',
    timeOutputFormat: 'h:mm A',
    timeParseFormat: 'HH:mm:ss',

    // WDW formats
    ptbrFullDateFormat: 'dddd, D [de] MMMM [de] YYYY',

    // HKDL formats
    idFullDateFormat: 'dddd, D MMMM YYYY',
    jaFullDateFormat: 'YYYY年 MMMM D日 dddd',
    koFullDateFormat: 'dddd, YYYY년 MMMM D일',
    msFullDateFormat: 'dddd, D MMMM YYYY',
    thFullDateFormat: 'dddd D MMMM YYYY',
    zhhkFullDateFormat: 'dddd, YYYY年M月D日',
    zhcnFullDateFormat: 'dddd, YYYY年M月D日',

    idShortDateFormat: 'ddd, D MMMM YYYY',
    jaShortDateFormat: 'YYYY年 MMMM D日 ddd',
    koShortDateFormat: 'ddd, YYYY년 MMMM D일',
    msShortDateFormat: 'ddd, D MMMM YYYY',
    thShortDateFormat: 'ddd D MMMM YYYY',
    zhhkShortDateFormat: 'ddd, YYYY年M月D日',
    zhcnShortDateFormat: 'ddd, YYYY年M月D日',

    jaFiveDayCalColFormat: 'dddd, D日',
    koFiveDayCalColFormat: 'dddd, D일',
    zhhkFiveDayCalColFormat: 'dddd, D日',
    zhcnFiveDayCalColFormat: 'dddd, D日'
};


export const DATE_REGEX_PATTERNS = {
    simpleIsoDate: /\d{4}-\d\d-\d\d/, //YYYY-MM-DD
};
