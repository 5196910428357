<div class="container">
    <div class="message-container" >
        <div class="avatar error-{{status}}"></div>
        <div class="message">
            <div *ngIf="bundle">
                <h2 class="title" [innerHtml]="bundle[status]?.title || bundle[unknown]?.title | safeHtml"></h2>
                <p class="copy" [innerHtml]="bundle[status]?.copy || bundle[unknown]?.copy | safeHtml"></p>
            </div>
            <div *ngIf="!bundle">
                <!-- Fallback in case we can't get any language bundle -->
                <h2 class="title">We're Working on It</h2>
                <p class="copy">
                    This page is temporarily unavailable.
                    Rest assured, we'll fix the issue soon, so please try again later.
                </p>
            </div>
        </div>
    </div>
</div>
