import { Injectable, Injector } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpEvent,
    HttpRequest,
    HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { LocaleService } from '@finder/shared/services/locale-service/locale-service.service';
import { LOCALE_DEFAULT } from '@finder/shared/constants/app.constants';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {

    constructor(private injector: Injector) {}

    /**
     * Add the locale to every request
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const localeService = this.injector.get(LocaleService);
        // Get locale from cookie, and if it's not defined, use the default
        const locale = localeService.getLocale() || LOCALE_DEFAULT;

        req = req.clone({
            setHeaders: {
                'Accept-Language': locale
            }
        });

        return next.handle(req);
    }
}
