import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import isEmpty from 'lodash-es/isEmpty';

import { CONFIG_CONSTANTS, API_CONSTANTS, DESTINATION_ID_CONSTANTS } from '@finder/shared/constants/app.constants';
import { ConfigService } from '@finder/core/config.service';
import getApiBase from '@finder/shared/utils/string/getApiBase';
import { GetDateStringProvider, GET_DATE_STRING } from '@finder/shared/utils/string/getDateString';

const apiUrlKey = CONFIG_CONSTANTS.apiUrlKey;
const siteIdKey = CONFIG_CONSTANTS.siteIdKey;
const defaultVersionKey = CONFIG_CONSTANTS.defaultVersionKey;

@Injectable()
export class ExplorerServiceConnectorService {

    private baseUrl: string;
    private version: string;
    private siteId: string;

    constructor(
        private http: HttpClient,
        private config: ConfigService,
        @Inject(GET_DATE_STRING) private getDateString: GetDateStringProvider,
    ) {
        this.baseUrl = this.config.getValue(apiUrlKey);
        this.version = this.config.getValue(defaultVersionKey);
        this.siteId = this.config.getValue(siteIdKey);
    }

    /**
     * Makes a GET request to explorer-svc api endpoint
     *
     * @param id        facility ID
     * @param version   endpoint version (optional)
     * @param startDate date to request (optional - defaults to current date)
     */
    public getFacility(id: string, version?: string, startDate?: string, swid?: string | boolean): Observable<any> {
        // Add a header of where we're going to the request
        const httpHeaders: HttpHeaders = new HttpHeaders({
            urlFriendlyId: id
        });
        const date =
            isEmpty(startDate) ?
                this.getDateString(new Date()) :
                startDate;

        let url = this.constructUrl(API_CONSTANTS.detailsEndpoint, this.baseUrl, id, date, this.siteId, version);

        if (swid) {
            url += `?swid=${swid}`;
        }

        return this.http.get(url, { headers: httpHeaders});
    }

    /**
     * Makes a request for future schedule information
     *
     * @param id            facility ID
     * @param startDate     date to request (optional - defaults to current date)
     * @param hasMealPeriod Request meal period data
     * @param version       endpoint version (optional)
     */
    public getSchedules(
        id: string,
        startDate: string = this.getDateString(new Date()),
        hasMealPeriod: boolean = false,
        version?: string
    ): Observable<any> {
        let url = this.constructUrl(
            API_CONSTANTS.detailsScheduleEndpoint,
            this.baseUrl,
            id,
            startDate,
            this.siteId,
            version
        );

        if (hasMealPeriod) {
            url += '?hasMealPeriod=true';
        }

        return this.http.get(url);
    }

    /**
     * Makes a request for future meal periods schedule information
     *
     * @param id            facility ID
     * @param startDate     date to request (optional - defaults to current date)
     * @param version       endpoint version (optional)
     */
    public getMealPeriodSchedules(
        id: string,
        startDate: string = this.getDateString(new Date()),
        version?: string
    ): Observable<any> {
        const url = this.constructUrl(
            API_CONSTANTS.detailsMealPeriodScheduleEndpoint,
            this.baseUrl,
            id,
            startDate,
            this.siteId,
            version
        );

        return this.http.get(url);
    }

    /**
     * Makes a request to get the dining availabilty.
     *
     * @param id            facility ID
     * @param partySize     the party size
     * @param searchDate    the date to search
     * @param params        the params for the request
     * @param version       endpoint version (optional)
     */
    public getDiningAvailability(
        swid: string,
        siteId: string,
        id: string,
        productType: string,
        partySize: number,
        searchDate: string,
        params: any,
        version?: string
    ): Observable<any> {
        params = params || {};
        const apiBaseUrl = this.constructBaseUrl(
            API_CONSTANTS.diningAvailabilityEndpoint,
            this.baseUrl,
            version
        );
        const queryParams = new URLSearchParams();

        if (params.searchTime) {
            queryParams.set('searchTime', params.searchTime);
        } else {
            queryParams.set('mealPeriod', params.mealPeriod);
        }

        if (params.restaurantId) {
            queryParams.set('restaurantId', params.restaurantId);
        }
        if (params.specialNeeds) {
            queryParams.set('specialNeeds', params.specialNeeds);
        }

        const url = `${apiBaseUrl}/${swid}/${siteId}/${id}/${productType}/${partySize}/${searchDate}/?${queryParams}`;

        return this.http.get(url);
    }

    /**
     * Makes a request to get the dining availabilty list.
     *
     * @param searchDate the date to search
     * @param partySize the party size
     * @param searchParams the time to search
     * @param version endpoint version (optional)
     * @returns an observable
     */
    getDiningAvailabilityList(
        swid: any,
        searchDate: string,
        partySize: number,
        searchParams: any,
        version?: string
    ): Observable<any> {
        const destinationId = DESTINATION_ID_CONSTANTS[this.siteId];
        const apiBaseUrl = this.constructBaseUrl(
            API_CONSTANTS.diningAvailabilityEndpointList,
            this.baseUrl,
            version
        );

        const queryString = new URLSearchParams(searchParams);
        const url = `${apiBaseUrl}/${swid}/${this.siteId}/${destinationId}/${searchDate}/${partySize}/?${queryString}`;

        return this.http.get(url);
    }

    /**
     * Gets list ancestor entities for given type(s) for the current `siteId`
     *
     * @param types         Array of entity types
     * @param version       endpoint version (optional)
     */
    getListAncestorEntities(type: string, startDate = new Date(), swid?: any, version?: string): Observable<any> {
        const date = this.getDateString(startDate);
        const apiBase = this.constructBaseUrl(
            API_CONSTANTS.listAncestorEntitiesEndpoint,
            this.baseUrl,
            version
        );

        const destinationId = DESTINATION_ID_CONSTANTS[this.siteId];
        let url = `${apiBase}/${this.siteId}/${destinationId}/${date}/${type}`;

        if (swid) {
            url += `?swid=${swid}`;
        }

        return this.http.get(url);
    }

    /**
     * @name            getCalendarData
     * @description     Gets the calendar data for the current park id.
     * @param           mode Calendar mode: month | five-day
     * @param           startDate Start Date
     * @param           version Endpoint version (optional - defaults to v1 on inner services)
     * @param           swid Guest SWID
     * @returns         an observable
     */
    getCalendarData(mode: string, date: string = this.getDateString(new Date()), version?: string): Observable<any> {
        const apiBase = this.constructBaseUrl(
            API_CONSTANTS.calendarData,
            this.baseUrl,
            version
        );
        const destinationId = DESTINATION_ID_CONSTANTS[this.siteId];
        const url = `${apiBase}/${this.siteId}/${destinationId}/${date}/${mode}`;

        return this.http.get(url);
    }

    /**
     * Get the monthly availability for a resort
     *
     * @param siteId        {string} siteId
     * @param entityId      {string} entityId
     * @param date          {string} date of request
     * @param numOfMonths   {number} number of months to return
     * @param version       {string} api version
     * @returns Observable
     */
    public getMonthlyAvailability(siteId: string,
        entityId: string,
        date: string,
        numOfMonths: number,
        roomAvailability?: boolean,
        version?: string
    ): Observable<any> {
        const apiBase = this.constructBaseUrl(
            API_CONSTANTS.monthlyAvailabilityEndpoint,
            this.baseUrl,
            version
        );
        const url = `${apiBase}/${siteId}/${entityId}/${date}/${numOfMonths}?`;
        const params = new URLSearchParams();

        // Add a new param if we're looking for room types
        if (roomAvailability) {
            params.set('roomAvailability', 'true');
        }

        return this.http.get(url + params);
    }

    public getPlaceholderAvailability(
        siteId: string,
        startDate: string,
        endDate: string,
        requestedDate: string,
        numOfMonths: number,
        excludedDates?: string,
        sailingDates?: string,
        version?: string
    ) {
        const apiBase = this.constructBaseUrl(
            API_CONSTANTS.monthlyAvailabilityEndpoint,
            this.baseUrl,
            version
        );
        const url = `${apiBase}/${siteId}/${startDate}/${endDate}/${requestedDate}/${numOfMonths}?`;
        const params = new URLSearchParams();

        // Check to see if there are any dates we should leave out
        if (excludedDates) {
            params.set('excludedDates', excludedDates);
        }

        // Check for sailing dates
        if (sailingDates) {
            params.set('sailingDates', sailingDates);
        }

        return this.http.get(url + params);
    }

    private constructBaseUrl(endpoint: string, baseUrl: string, version: string = this.version): string {
        const apiBase = getApiBase(baseUrl, version);

        return `${apiBase}/${endpoint}`;
    }

    /**
     * Constructs endpoint URL
     *
     * @param endpoint  explorer-svc endpoint
     * @param baseUrl   base URL
     * @param id        facility ID
     * @param date      date to request
     * @param siteId    site ID
     * @param version   api version -- defaulted to v1
     */
    private constructUrl(
        endpoint: string,
        baseUrl: string,
        id: string,
        date: string,
        siteId: string,
        version: string = this.version
    ): string {
        const apiBaseUrl = this.constructBaseUrl(endpoint, baseUrl, version);

        return `${apiBaseUrl}/${siteId}/${id}/${date}/`;
    }
}
