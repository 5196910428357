import { Type } from '@angular/core';

/**
 * Writing the bundles using dynamic import with path and loadChildren properties we trick webpack
 * to think these are going to be use as a router configuration and it will create js bundles for each of them.
 */
export const lazyBundles: { path: string; loadChildren: () => Promise<Type<any>> }[] = [
    {
        path: 'finder-detail',
        loadChildren: () => import('../../../features/detail/detail.module').then(m => m.DetailModule)
    },
    {
        path: 'finder-campaign',
        loadChildren: () => import('../../../features/campaign/campaign.module').then(m => m.CampaignModule)
    },
    {
        path: 'finder-list',
        loadChildren: () => import('../../../features/list/list.module').then(m => m.ListModule)
    },
    {
        path: 'finder-search',
        loadChildren: () => import('../../../features/search/search.module').then(m => m.SearchModule)
    },
    {
        path: 'finder-calendar',
        loadChildren: () => import('../../../features/calendars/calendars.module').then(m => m.CalendarsModule)
    },
    {
        path: 'finder-map',
        loadChildren: () => import('../../components/map/map.module').then(m => m.MapModule)
    },
    {
        path: 'debug-panel',
        loadChildren: () => import('../../components/debug-panel/debug-panel.module').then(m => m.DebugPanelModule)
    },
    {
        path: 'discounts-modal',
        // If we break this line webpack doesn't want to create the bundle
        // so I had to disable max-length rule here
        // eslint-disable-next-line max-len
        loadChildren: () => import('../../components/discounts-modal/discounts-modal.module').then(m => m.DiscountsModalModule)
    },
];

export const lazyArrayToObj = () =>
    lazyBundles.reduce((result: any, bundle: any) => {
        result[bundle.path] = bundle.loadChildren;

        return result;
    }, {});
