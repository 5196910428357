import { Component } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';

@Component({
    selector: 'finder-mobile-tooltip',
    templateUrl: './mobile-tooltip.component.html',
    styleUrls: ['./mobile-tooltip.component.scss']
})
export class MobileTooltipComponent {
    parentComponent: ModalComponent;
    data: any;

    constructor() { }
}
