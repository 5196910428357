import { InjectionToken } from '@angular/core';
import { DATE_REGEX_PATTERNS } from '../../constants/date.constants';

/**
 * @description Get a string and validate if it is a valid date with format YYYY-MM-DD
 * @param date  string
 */
export const isValidDateString = (date: string): boolean => {
    if (!date) {
        return false;
    }

    // Regex to validate date format equal to YYYY-MM-DD
    const isISODate = new RegExp(DATE_REGEX_PATTERNS.simpleIsoDate).test(date);
    if (!isISODate) {
        return false;
    }

    const dateObj = new Date(date);
    if (isNaN(dateObj.valueOf())) {
        return false;
    }

    return true;
};

export type IsValidDateString = (date: string) => boolean;
export const IS_VALID_DATE_STRING =
    new InjectionToken('IS_VALID_DATE_STRING', {
        factory: () => isValidDateString
    });
