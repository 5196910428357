import { Component, OnInit, Input, ViewChild, ViewContainerRef } from '@angular/core';

export const FINDER_PROMO_CSS_CLASS = {
    background: {
        primary: 'primary',
        secondary: 'secondary',
        light: 'light',
    },
    buttonTheme: {
        secondary: 'secondary',
        reversed: 'reversed',
    }
};

@Component({
    selector: 'finder-promo',
    templateUrl: './promo.component.html',
    styleUrls: ['./promo.component.scss']
})
export class PromoComponent implements OnInit {

    @Input() icon: string;
    @Input() title: string;
    @Input() subtitle: string;
    @Input() buttonText: string;
    @Input() link: string;
    @Input() background: string;
    @Input() tooltipText: string;

    @ViewChild('tooltipInsert', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;

    btnThemeClass: string;
    tooltip: any;

    constructor() { }

    ngOnInit() {
        const lightBackground = this.background === FINDER_PROMO_CSS_CLASS.background.light;

        // if the background is light, use btn with solid background
        this.btnThemeClass = lightBackground ?
            FINDER_PROMO_CSS_CLASS.buttonTheme.secondary : FINDER_PROMO_CSS_CLASS.buttonTheme.reversed;

        if (this.tooltipText) {
            this.createTooltip();
        }
    }

    createTooltip() {
        // @TODO - this should probably be configurable, but the mocks only show
        // a single way. So rather than worry about it now, we'll just put in defaults.
        this.tooltip = {
            id: 'promo-tooltip',
            size: 's',
            name: 'information',
            position: 'top-right',
            text: this.tooltipText
        };
    }
}
