import { of } from 'rxjs';
import { delay, take } from 'rxjs/operators';
import { SchedulerLike } from 'rxjs/internal/types';
import { asyncScheduler } from 'rxjs/internal/scheduler/async';

export const timeout = (callback, ms, scheduler: SchedulerLike = asyncScheduler) => of(true)
        .pipe(
            delay(ms, scheduler),
            take(1)
        )
        .subscribe(() => {
            callback();
        });
