import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import has from 'lodash-es/has';

import { LoadingServiceErrorState } from './loading-service.interface';
@Injectable()
export class LoadingService {

    private loadedState: boolean;
    private errorState: LoadingServiceErrorState;
    private loadedStateSubject = new BehaviorSubject<boolean>(false);
    private errorStateSubject = new Subject<LoadingServiceErrorState>();

    constructor() { }

    /**
     * Sets the internal state value
     *
     * @param state - state to set
     */
    setLoadingState(state: boolean) {
        this.loadedState = state;
        this.loadedStateSubject.next(this.loadedState);
    }

    /**
     * Gets an Observable for the curent state
     *
     * @return current state Observable
     */
    getLoadingState(): Observable<boolean> {
        return this.loadedStateSubject.asObservable();
    }

    /**
     * Sets the internal state value
     *
     * @param state - state to set
     */
    setErrorState(state: LoadingServiceErrorState) {
        // This shouldn't be a valid condition. setErrorState is expecting
        // something with this interface: LoadingServiceErrorState, in which status is not optional.
        // There is a comment in the original PR saying we need it. So instead of removing it,
        // lets just ignore it.
        /* istanbul ignore next */
        if (state && has(state, 'status') === false) {
            // Fix for Timeout Interceptor so we can actually throw a 504
            if (state.name.toLowerCase().includes('timeout')) {
                state.status = 504;
            }
        }
        this.errorState = state;
        this.errorStateSubject.next(this.errorState);
    }


    /**
     * Gets an Observable for the curent state
     *
     * @return current state Observable
     */
    getErrorState(): Observable<LoadingServiceErrorState> {
        return this.errorStateSubject.asObservable();
    }
}
