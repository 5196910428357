import { SyndicatedOptions, BrandSyndicatedOptions } from '@finder/shared/components/syndicated-layout/syndicated-options.interface';
import { LOCALE_DEFAULT, LOCALE_NORMALIZED_DEFAULT } from '@finder/shared/constants/app.constants';

const cachedPath = '/cached/';
const defaultLocale = LOCALE_DEFAULT;
const defaultLanguage = LOCALE_NORMALIZED_DEFAULT;
const wdwDefaultAffiliation = 'STD_GST';
const dlrDefaultAffiliation = 'STD_GST-CAL_RESIDENT-SOCAL_RESIDENT';
const dclDefaultAffiliation = 'STD_GST';
const hkdlDefaultAffiliation = 'STD_GST';
const wdwSiteId = 'wdw';
const dlrSiteId = 'dlr';
const dclSiteId = 'dcl';
const hkdlSiteId = 'hkdl';
const vbSiteId = 'vb';
const hhSiteId = 'hh';

// WDW
const wdwHeaderDefaultOptions: SyndicatedOptions = {
    container: 'headerWrapper',
    showSignInSignOut: 1,
    languageSelector: 1,
    responsive: 1,
    affiliation: wdwDefaultAffiliation,
    locale: defaultLocale,
    language: defaultLanguage,
    siteOverride: wdwSiteId
};

const wdwFooterDefaultOptions: SyndicatedOptions = {
    container: 'footerWrapper',
    responsive: 1,
    affiliation: wdwDefaultAffiliation,
    locale: defaultLocale,
    language: defaultLanguage,
    siteOverride: wdwSiteId
};

// DLR
const dlrHeaderDefaultOptions: SyndicatedOptions = {
    container: 'headerWrapper',
    showSignInSignOut: 1,
    languageSelector: 1,
    responsive: 1,
    affiliation: dlrDefaultAffiliation,
    locale: defaultLocale,
    language: defaultLanguage,
    siteOverride: dlrSiteId,
    source: 'navigation-app'
};

const dlrFooterDefaultOptions: SyndicatedOptions = {
    container: 'footerWrapper',
    responsive: 1,
    affiliation: dlrDefaultAffiliation,
    locale: defaultLocale,
    language: defaultLanguage,
    siteOverride: dlrSiteId,
    source: 'navigation-app'
};

// DCL
const dclHeaderDefaultOptions: SyndicatedOptions = {
    container: 'headerWrapper',
    showSignInSignOut: 1,
    languageSelector: 1,
    responsive: 1,
    affiliation: dclDefaultAffiliation,
    locale: defaultLocale,
    language: defaultLanguage,
    siteOverride: dlrSiteId,
    source: 'navigation-app'
};

const dclFooterDefaultOptions: SyndicatedOptions = {
    container: 'footerWrapper',
    responsive: 1,
    affiliation: dclDefaultAffiliation,
    locale: defaultLocale,
    language: defaultLanguage,
    siteOverride: dclSiteId,
    source: 'navigation-app'
};

// HKDL
const hkdlHeaderDefaultOptions: SyndicatedOptions = {
    container: 'headerWrapper',
    showSignInSignOut: 1,
    languageSelector: 1,
    responsive: 1,
    affiliation: hkdlDefaultAffiliation,
    locale: defaultLocale,
    language: defaultLanguage,
    siteOverride: hkdlSiteId,
    source: 'navigation-app'
};

const hkdlFooterDefaultOptions: SyndicatedOptions = {
    container: 'footerWrapper',
    responsive: 1,
    affiliation: hkdlDefaultAffiliation,
    locale: defaultLocale,
    language: defaultLanguage,
    siteOverride: hkdlSiteId,
    source: 'navigation-app'
};

// VB
const vbHeaderDefaultOptions: SyndicatedOptions = {
    container: 'headerWrapper',
    languageSelector: 1,
    responsive: 1,
    showSignInSignOut: 1,
    version: 3,
    siteOverride: vbSiteId,
    locale: defaultLocale,
    language: defaultLanguage
};

const vbFooterDefaultOptions: SyndicatedOptions = {
    container: 'footerWrapper',
    responsive: 1,
    siteOverride: vbSiteId,
    locale: defaultLocale,
    language: defaultLanguage
};

// HH
const hhHeaderDefaultOptions: SyndicatedOptions = {
    container: 'headerWrapper',
    languageSelector: 1,
    responsive: 1,
    showSignInSignOut: 1,
    version: 3,
    siteOverride: hhSiteId,
    locale: defaultLocale,
    language: defaultLanguage
};

const hhFooterDefaultOptions: SyndicatedOptions = {
    container: 'footerWrapper',
    responsive: 1,
    siteOverride: hhSiteId,
    locale: defaultLocale,
    language: defaultLanguage
};

const brandHeaderDefaultOptions: BrandSyndicatedOptions = {
    wdw: wdwHeaderDefaultOptions,
    dlr: dlrHeaderDefaultOptions,
    dcl: dclHeaderDefaultOptions,
    hkdl: hkdlHeaderDefaultOptions,
    vb: vbHeaderDefaultOptions,
    hh: hhHeaderDefaultOptions
};

const brandFooterDefaultOptions: BrandSyndicatedOptions = {
    wdw: wdwFooterDefaultOptions,
    dlr: dlrFooterDefaultOptions,
    dcl: dclFooterDefaultOptions,
    hkdl: hkdlFooterDefaultOptions,
    vb: vbFooterDefaultOptions,
    hh: hhFooterDefaultOptions
};

export { brandHeaderDefaultOptions, brandFooterDefaultOptions, cachedPath };
