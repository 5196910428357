import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import moment from 'moment';

const MAX_DAYS = 180;
const BACKWARDS_WEEKS_LOCALES = ['zh-cn', 'zh-hk'];

/**
 * Class that creates calendar data and provide
 * methods to change between months and select dates.
 */
@Injectable()
export class CalendarService {
    weekDays: string[];
    fullNameWeekDays: string[];
    calendarData: any[] = [];
    currentDate: moment.Moment;
    selectedDate: moment.Moment;
    calendarDate: moment.Moment;
    selectedMonth: string;
    selectedMonthNumber: string;
    selectedYear: string;
    weekDaysLength: number;
    currentDaysInMonth: number;
    maxDate: moment.Moment;

    private calendarIsOpen = false;
    private calendarIsOpenSubject = new Subject<boolean>();
    private calendarDataSubject = new BehaviorSubject<any[]>([]);
    private highlightedDates: Set<string>;
    private formattedDates: Map<number,string>;

    constructor() { }

    /**
     * Initializes the calendar with config values.
     *
     * @param weekDaysLength the week days length to show.
     * @param selectedDate the date selected.
     * @returns an Array of Array weeks.
     */
    initCalendar(
        weekDaysLength: number = 1,
        selectedDate: Date = new Date(),
        maxDays: number = MAX_DAYS,
        highlightedDates: string[] = []
    ) {
        const momentSelectedDate = moment(selectedDate);
        this.selectedDate = momentSelectedDate.isValid() ? momentSelectedDate : moment();
        this.weekDaysLength = weekDaysLength;
        this.getWeekDays();
        this.currentDate = moment();
        this.calendarDate = this.selectedDate.clone().date(1);
        const today = moment().startOf('day'); // this will set the hours to 12:00 am today.
        this.maxDate = today.add(maxDays, 'day');
        this.highlightedDates = new Set(highlightedDates);
        this.formattedDates = new Map();

        this.createCalendar(this.calendarDate);
    }

    /**
     * Creates a calendar for the next month.
     */
    nextMonth(): void {
        this.createCalendar(this.calendarDate.add(1, 'month'));
    }

    /**
     * Creates a calendar for the previous month.
     */
    previousMonth(): void {
        this.createCalendar(this.calendarDate.subtract(1, 'month'));
    }

    /**
     * Select a date in the calendar.
     *
     * @param day the day number.
     * @return the date selected.
     */
    selectCalendarDate(day: number): Date {
        // Set the month before the day to avoid months with different num of days issue
        this.selectedDate.month(this.calendarDate.month());
        this.selectedDate.date(day);
        this.selectedDate.year(this.calendarDate.year());

        return this.selectedDate.toDate();
    }

    /**
     * Check if the day passed by parameters is selected in the calendar.
     *
     * @param day the day number.
     */
    isSelectedDate(day: number): boolean {
        return this.selectedDate.date() === day &&
            this.selectedDate.month() === this.calendarDate.month() &&
            this.selectedDate.year() === this.calendarDate.year();
    }

    /**
     * Check if the day passed by parameters is selected in the calendar.
     *
     * @param day the day number.
     */
    isHighlightedDate(day: number): boolean {
        const date = this.formattedDates.get(day);

        return date && this.highlightedDates.has(date);
    }

    /**
     * returns true if the date is before the max date allowed
     * in the calendar.
     *
     * @param day the day
     * @param maxDate the max date for the calendar
     */
    isBeforeMaxDate(day: number, maxDate: Date): boolean {
        let dayObject = this.calendarDate.toDate();
        dayObject = new Date(dayObject.getFullYear(), dayObject.getMonth(), day, 0, 0, 0, 0);
        const maxDateObj = new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate(), 0, 0, 0, 0);

        return dayObject.getTime() <= maxDateObj.getTime();
    }

    /**
     * Check if the day is and old day.
     *
     * @param day the day number.
     */
    isOldDay(day: number, currentDate: moment.Moment = this.currentDate): boolean {
        if (!day) {
            return true;
        }
        if (this.calendarDate.year() > currentDate.year()) {
            return false;
        }
        if (this.calendarDate.year() === currentDate.year() &&
            this.calendarDate.month() > currentDate.month()) {
            return false;
        }
        if (this.calendarDate.year() === currentDate.year() &&
            this.calendarDate.month() === currentDate.month() &&
            day >= currentDate.date()) {
            return false;
        }

        return true;
    }

    isCurrentMonth(): boolean {
        return this.currentDate.month() === this.calendarDate.month();
    }

    getWeeksCount(): number {
        let rowCount = 0;
        this.calendarData.forEach(element => {
            rowCount = element ? rowCount + 1 : rowCount;
        });

        return rowCount;
    }

    /**
     * Returns the Selected Date in the Calendar.
     */
    getSelectedDate(): moment.Moment {
        return this.selectedDate;
    }

    isDateEnabled(day: number): boolean {
        return !this.isOldDay(day) && this.isBeforeMaxDate(day, this.maxDate.toDate());
    }

    isPreviousMonthEnabled(): boolean {
        const firstDayOfMonth = this.calendarDate.clone().date(1);

        return firstDayOfMonth.diff(this.currentDate, 'days') >= 0;
    }

    isNextMonthEnabled(): boolean {
        const firstDayOfNextMonth = this.calendarDate.clone().date(this.calendarDate.daysInMonth() + 1);

        return this.maxDate.diff(firstDayOfNextMonth, 'days') >= 0;
    }

    openCalendar(): void {
        if (this.calendarIsOpen) {
            return;
        }
        this.calendarIsOpen = true;
        this.calendarIsOpenSubject.next(this.calendarIsOpen);
    }

    closeCalendar(): void {
        if (!this.calendarIsOpen) {
            return;
        }
        this.calendarIsOpen = false;
        this.calendarIsOpenSubject.next(this.calendarIsOpen);
    }

    getOpenStateObservable(): Observable<boolean> {
        return this.calendarIsOpenSubject.asObservable();
    }

    /**
     * @name    getCalendarDataObservable
     * @description Return the new calendar data through an observable so the template knows when to redraw
     * @returns     Observable
     */
    getCalendarDataObservable(): Observable<any[]> {
        return this.calendarDataSubject.asObservable();
    }

    /**
     * Creates a calendar based on the selected date.
     *
     * @param selectedDate the selected date.
     * @returns an Array of Array weeks.
     */
    private createCalendar(selectedDate: moment.Moment) {
        const calendarData: any[] = [];
        const firstDayOfMonth = selectedDate.clone().date(1);
        // isoWeekday will return 1 for monday and 7 for sunday
        // with 'isoWeekday() % 7' we convert it to 0 sunday until 6 saturday.
        const dayOfTheWeek = firstDayOfMonth.clone().isoWeekday() % 7;
        this.currentDaysInMonth = firstDayOfMonth.clone().daysInMonth();

        let day = 1;
        let lastWeekNumber = 6;
        let firstWeek = true;
        let weekIndex = 0;
        let week;
        let j;

        this.selectedMonth = selectedDate.format('MMMM');
        this.selectedMonthNumber = selectedDate.format('MM');
        this.selectedYear = selectedDate.format('YYYY');

        for (let i = 0; i < lastWeekNumber; i++) {
            week = [];
            j = firstWeek ? dayOfTheWeek : 0;
            for (j; j < 7; j++) {
                const formattedDate = selectedDate.clone().set('date', day).format('YYYY-MM-DD');
                this.formattedDates.set(day, formattedDate);
                firstWeek = false;
                week[j] = day;
                day++;
                if (day > this.currentDaysInMonth) {
                    lastWeekNumber = i;
                    break;
                }
            }
            calendarData[weekIndex] = week;
            weekIndex++;
        }

        // Emit the new calendar data
        this.calendarDataSubject.next(calendarData);

        // Set the new calendar data on the service object
        this.calendarData = calendarData;
    }

    /**
     * Generates an array of week days.
     */
    private getWeekDays(): void {
        const isBackwardsWeek = BACKWARDS_WEEKS_LOCALES.indexOf(moment.locale()) !== -1;
        const weekDaysLength = isBackwardsWeek ? this.weekDaysLength * -1 : this.weekDaysLength;
        this.fullNameWeekDays = moment.weekdays();
        this.weekDays = this.fullNameWeekDays
            .map(weekday => {
                if (weekDaysLength < 0) {
                    // if we are in chinese locales like zh-cn we need to start
                    // extracting from the last element of the weekday name.
                    return weekday.slice(weekDaysLength);
                } else {
                    return weekday.slice(0, weekDaysLength);
                }
            });
    }
}
