export const USER_SELECTION_STORAGE = {
    dateFormatString: 'MM/DD/YYYY',
    timeFormat: 'HH:mm',
    keys: {
        selectedDate: 'finderSelectedDate',
        dateFormat: 'finderDateSavedFormat',
        selectedTime: 'finderSelectedTime',
        selectedPartySize: 'finderSelectedPartySize'
    }
};
