import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { LazyLoaderService } from '@finder/shared/services/lazy-loader/lazy-loader-service';
import { REMOVE_CSS_THEMES, RemoveCssThemesProvider } from '@finder/shared/utils/dom/removeCss';
import { ConfigService } from '@finder/core/config.service';
import { CONFIG_CONSTANTS } from '@finder/shared/constants/app.constants';
import { THEME_DEFAULT } from '@finder/features/theme-engine/theme-engine.constants';


@Injectable()
export class FinderThemeService {

    private currentTheme;
    private themeChanged = new BehaviorSubject<string>(null);
    private bodyClasses = [];

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(REMOVE_CSS_THEMES) private removeCssThemes: RemoveCssThemesProvider,
        private config: ConfigService,
        private lazyLoader: LazyLoaderService,
    ) { }

    /**
     * Sets the current theme
     *
     * @param theme {string} theme to set
     */
    setCurrentTheme(theme: string) {
        const themeName = `${theme}-theme`;

        // Clean up previous changes to the page
        this.removeClassesFromBody();

        if (!this.currentTheme || this.currentTheme !== themeName) {
            const assetPath = `themes/${themeName}.css`;
            const isProd = this.config.isDevelopment() === false;
            // The production path is first. Then local dev
            const cssPath = isProd ?
                `assets/${assetPath}` :
                `/advanced-finder/${assetPath}?${this.config.getValue(CONFIG_CONSTANTS.cacheBuster)}`;

            // Remove old theme
            if (this.currentTheme) {
                // remove from body classes
                this.removeCssThemes(this.document, theme);

                // Remove from lazy loader resource registry
                this.lazyLoader.removeThemeFromRegistry(theme);
            }

            // Add new theme
            this.lazyLoader.lazyLoadCssResource(cssPath, isProd);
        }

        // Add style to body
        this.addClassToBody(themeName);

        // Set theme
        this.currentTheme = themeName;

        // Emit
        this.themeChanged.next(this.currentTheme);
    }

    addClassToBody(className) {
        // Add the new class to the body
        this.document.body.classList.add(className);

        // Store the class name so it can be removed later
        this.bodyClasses.push(className);
    }

    /**
     * Set the default theme
     */
    setDefaultTheme() {
        this.setCurrentTheme(THEME_DEFAULT.toLowerCase());
    }

    /**
     * Returns Observable with theme change events
     *
     * @return Observable<string>
     */
    themeChange(): Observable<string> {
        return this.themeChanged.asObservable();
    }

    getCurrentTheme() {
        return this.currentTheme;
    }

    private removeClassesFromBody() {
        // Pass the array of classes as arguments to remove from the element
        this.document.body.classList.remove(...this.bodyClasses);

        // Reset the array
        this.bodyClasses.length = 0;
    }
}
