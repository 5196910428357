import { Injectable } from '@angular/core';
import { merge } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { ModalComponent } from './modal.component';

@Injectable({
    providedIn: 'root'
})
export class ModalOpenQueueService {

    queue: ModalComponent[] = [];

    constructor() { }

    /**
     * Add a modal to the open queue and set up a listener when it dismisses or closes
     *
     * @param modal - modal trying to open.
     */
    add(modal: ModalComponent) {
        // if the queue is empty, we open the modal directly
        if (this.queue.length === 0) {
            modal.applyOpenState();
        }
        // add the current modal to the queue
        this.queue.push(modal);
        // setup a listener when the modal dismisses or closes
        merge(
            modal.afterDismiss(),
            modal.afterClose()
        )
        .pipe(
            map(() => modal),
            take(1)
        )
        .subscribe((m) => this.processQueue(m));
    }

    /**
     * ProcessQueue receives a modal which just has closed or dismissed, removes it from the queue
     * and open the next one if exists
     *
     * @param modal - modal which has just closed.
     */
    private processQueue(modal: ModalComponent) {
        const index = this.queue.findIndex(m => m === modal);
        const nextInQueue = this.queue[index + 1];
        // if there is a modal waiting in the queue, we open it
        if (nextInQueue) {
            nextInQueue.applyOpenState();
        }
        // remove the modal from the queue
        this.queue.splice(index, 1);
    }
}
