import { Directive, ElementRef, AfterViewInit } from '@angular/core';
import { LocaleService } from '@finder/shared/services/locale-service/locale-service.service';
import { LOCALE_HREF_PREFIX_IGNORELIST } from '@finder/shared/constants/app.constants';
import { LocaleHelperService } from '@finder/shared/services/locale-service/locale-helper.service';

@Directive({
    selector: '[finderLocale]'
})
export class LocaleDirective implements AfterViewInit {
    private ignoreLocales = LOCALE_HREF_PREFIX_IGNORELIST;

    constructor(
        private el: ElementRef,
        private localeService: LocaleService,
        private localeHelperService: LocaleHelperService
    ) { }

    ngAfterViewInit(): void {
        this.checkHref(this.el);
    }

    private checkHref(el: ElementRef) {
        const href = el.nativeElement.attributes.href;
        const locale = this.localeService.getLocale();
        // Add the locale to the URL
        if (
            locale &&
            this.ignoreLocales.indexOf(this.localeHelperService.getNormalizedLocale(locale)) === -1 &&
            href.value.indexOf('/') === 0 &&
            href.value.indexOf(locale) !== 1
        ) {
            href.value = `/${locale}${href.value}`;
        }
    }
}
