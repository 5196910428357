import { Animation } from '@finder/shared/components/animations/animation/animation.interface';

export const getSlideUpAnimation = (data: Animation, isMobile: boolean = false) => {
    const scalingRatio = isMobile ? (data.scale || 0.5) : 1;
    const { height, frames, width, source, duration } = data;
    const animationDelay = data.delay || 1000; // milliseconds
    const scaledWidth = Math.floor(width * scalingRatio);
    const scaledheight = Math.floor(height * scalingRatio);

    return {
        spriteStyles: {
            height: `${scaledheight}px`,
            'background-image': `url("${source}")`
        },
        wrapperStyles: {
            height: `${scaledheight / parseInt(frames, 10)}px`,
            width: `${scaledWidth}px`
        },
        animateStyles: {
            transition: `transform ${duration}ms steps(${frames}) ${animationDelay}ms`,
            transform: `translateY(-100%)`
        }
    };
};
