import { Animation } from '@finder/shared/components/animations/animation/animation.interface';

export const getPopInLeft = (data: Animation, isMobile: boolean = false, parentHeight: number) => {
    const { height, frames, width, source, duration, repeat, easing, inset, delay } = data;
    const scalingRatio = parentHeight ?
        getScalingRatio(parentHeight, height, frames) :
        isMobile ? (data.scale || 0.5) : 1;
    const animationDelay = delay || '500'; // milliseconds
    const scaledWidth = Math.floor(width * scalingRatio);
    const scaledheight = Math.floor(height * scalingRatio);
    const componentInset = inset || 'auto';
    const componentInsetMobile = data.insetMobile || componentInset;

    const settings: any = {
        componentStyles: {
            height: `${scaledheight}px`,
            width: `${scaledWidth}px`,
            inset: `${componentInset}`,
        },
        spriteStyles: {
            height: `${scaledheight}px`,
            width: `${scaledWidth}px`,
            'background-image': `url("${source}")`
        },
        wrapperStyles: {
            height: `${scaledheight / parseInt(frames, 10)}px`,
            width: `${scaledWidth}px`,
            left: `0px`
        },
        animateStyles: {
            'animation-delay': `${animationDelay}ms`,
            'animation-name': 'pop-in-left',
            'animation-duration': `${duration}ms`,
            'animation-timing-function': (parseInt(frames, 10) > 1) ?
                `steps(${frames})` :
                `${easing}`,
            'animation-iteration-count': repeat,
            'animation-fill-mode': 'forwards',
        }
    };

    if (componentInsetMobile !== componentInset) {
        settings.componentStyles.insetMobile = componentInsetMobile;
    }

    return settings;
};

const getScalingRatio = (parentHeight, height, frames) => {
    // buzz will need to be bigger that the container and
    // show a little overflow
    const animationOffset = 70;
    const scaledHeight = (height / parseInt(frames, 10)) - animationOffset;

    return parentHeight / scaledHeight;
};
