import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injector, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HeaderOverridesService } from '@finder/shared/services/header-overrides/header-overrides.service';
import { TravelAgentService } from '@finder/shared/services/travel-agent/travel-agent.service';
import { TRAVEL_AGENT, ANALYTICS_PAGE_KEY_HEADER_NAME } from '@finder/shared/constants/app.constants';
import { WindowRef } from '@finder/shared/services/window-ref/window-ref.service';
import { ConfigService } from '@finder/core/config.service';
import { LocaleHelperService } from '@finder/shared/services/locale-service/locale-helper.service';

@Injectable()
export class HeaderOverrideInterceptor implements HttpInterceptor {

    constructor(
        private injector: Injector,
        private travelAgentService: TravelAgentService,
        private winRef: WindowRef,
        private configService: ConfigService,
        private localeHelper: LocaleHelperService,
    ) {}

    /**
     * Add any override headers to the request
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headerOverrideService = this.injector.get(HeaderOverridesService);
        const headers = {};
        const urlFriendlyId = req.headers.get('urlFriendlyId');

        // Filter out just the active headers
        const activeHeaders = headerOverrideService.getHeaders().filter(header => header.enabled);

        // If we are on Travel Agent flow, we pass a header so the API can handle it properly
        if (this.travelAgentService.isValidTravelAgentUrl()) {
            activeHeaders.push({
                enabled: true,
                header: TRAVEL_AGENT.header,
                value: this.travelAgentService.getTravelAgentStore()
            });
        }

        // At this point, pageKey service doesn't have the key we need to pass to our API call,
        // So we build it. This will work for listing/search/calendar pages. For any other type, we have logic
        // on the API side to build it (that is because we don't know the url until we process explorer svc response)
        const pathName = this.localeHelper.stripLocale(this.winRef.nativeWindow.location.pathname);
        let pagePath = `${this.configService.getValue('siteId')}${pathName}`;

        /**
         * The news page is a Watcher request that leads to another Watcher page.
         * The Watcher pages need to know where they're coming from. We use the page-key
         * to do this. When transitioning from the List => Article the wrong page-key is
         * sent. This allows us to create the correct page key.
         * Our normal list pages also hit this logic but it doesn't affect the page because
         * they're not custom pages.
         */
        if (urlFriendlyId && !pagePath.includes(urlFriendlyId)) {
            pagePath += `/${urlFriendlyId}`;
        }

        if (pagePath.slice(-1) === '/') {
            pagePath = pagePath.slice(0, -1);
        }

        pagePath = pagePath.replace(/\//g, '.');

        /**
         * We need to make sure that the page key doesn't have more than 4 items when locale is removed
         * eg: wdw.dining.port-orleans-resort-french-quarter.scat-cats-club.celebrate-soulfully-cooking-with-soul
         * this is an example of a bad page key generated when navigating inside the spa. There are two
         * urlFriendlyIds in the key. Just keep the last one
         */
        const pageKeyArray = pagePath.split('.');
        if (pageKeyArray.length > 4) {
            const [siteId, type, area, ...remainder] = pageKeyArray;
            pagePath = `${siteId}.${type}.${area}.${remainder.pop()}`;
        }

        activeHeaders.push({
            enabled: true,
            header: ANALYTICS_PAGE_KEY_HEADER_NAME,
            value: pagePath
        });

        // Add the headers to the request
        activeHeaders.forEach(header => {
            headers[header.header] = header.value;
        });

        // Add headers to the request
        req = req.clone({
            setHeaders: headers
        });

        return next.handle(req);
    }
}
