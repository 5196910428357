/**
 * Extracts the width and height from a string url
 * with a predefined format.
 * Example:
 * input - url: https://stage.cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/170/96/75/dam/wdpro-assets/dino-sue/dino-sue-00.jpg
 * return - {
 *    width: '170',
 *    height: '96',
 * }
 *
 * @param url the image url from where to extract the sizes.
 * @returns an object with a width and height property, if it cannot extract the size
 * from the url will return default sizes width = 100% height = 100%
 */
const getImageSize = (url) => {
    const defaultSizes = {
        width: '100%',
        height: '100%',
    };

    if (!url) {
        return defaultSizes;
    }

    const regex = new RegExp('/mwImage/(.*?)/dam/');
    const match = url.match(regex);

    if (!match) {
        return defaultSizes;
    }

    const [, group] = url.match(regex);

    const sizes = group.split('/');

    if (sizes.length !== 4) {
        return defaultSizes;
    }

    const [, width, height] = sizes;

    return {
        width,
        height,
    };
};

export default getImageSize;

