import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, skipWhile, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ConfigService } from '@finder/core/config.service';
import { ExplorerServiceConnectorService } from '@finder/shared/services/explorer-svc-connector/explorer-svc-connector.service';
import { LanguageBundleConnectorService } from '@finder/shared/services/language-bundle/language-bundle-connector.service';
import { CONFIG_CONSTANTS, API_CONSTANTS } from '@finder/shared/constants/app.constants';
import { LocaleHelperService } from '@finder/shared/services/locale-service/locale-helper.service';
import { ReservationServiceConnectorService } from '@finder/shared/services/reservation-svc-connector/reservation-svc-connector.service';
import { SearchServiceConnectorService } from '@finder/shared/services/search-svc-connector/search-svc-connector.service';
import { AuthenticationService } from '@finder/shared/services/authentication/authentication.service';
import { SearchSelection } from '@finder/features/search/interfaces/search-selection.interface';

/**
 * @class WebApiServiceConnectorService
 * Manages Calls to api services
 */
@Injectable()
export class FinderApiConnectorService {

    constructor(
        private explorerService: ExplorerServiceConnectorService,
        private http: HttpClient,
        private config: ConfigService,
        private bundleService: LanguageBundleConnectorService,
        private localeHelperService: LocaleHelperService,
        private reservationService: ReservationServiceConnectorService,
        private searchService: SearchServiceConnectorService,
        private authenticationService: AuthenticationService
    ) {}

    /**
     * Get facility info
     *
     * @param id facility ID
     * @param version api version (optional - defaults to v1 on inner services)
     */
    // TODO - proper test coverage
    /* istanbul ignore next */
    getDetailsBySlugId(id: string, version?: string): Observable<any> {
        return this.authenticationService.getSWID().pipe(
            switchMap((swid: string | boolean) => this.explorerService.getFacility(id, undefined, undefined, swid)),
            catchError(() => this.explorerService.getFacility(id))
        );
    }

    // TODO - proper test coverage
    /* istanbul ignore next */
    getSchedules(id: string, startDate?: string, hasMealPeriod?: boolean): Observable<any> {
        return this.explorerService.getSchedules(id, startDate, hasMealPeriod);
    }

    getMealPeriodSchedules(id: string, startDate?: string): Observable<any> {
        return this.explorerService.getMealPeriodSchedules(id, startDate);
    }

    /**
     * Get dining availability times.
     *
     * @param id entity id
     * @param productType the product type to search availabiltiy
     * @param partySize the party size
     * @param searchDate the search date
     * @param params additional params.
     * @returns an observable.
     */
    getDiningAvailability(
        swid: string,
        siteId: string,
        id: string,
        productType: string,
        partySize: number,
        searchDate: string,
        params: any
    ): Observable<any> {
        return this.explorerService.getDiningAvailability(swid, siteId, id, productType, partySize, searchDate, params);
    }

    /**
     * Get dining availability entity list.
     *
     * @param siteId site ID
     * @param destinationId facility ID
     * @param searchDate the date to search
     * @param partySize the party size
     * @param searchParams the time to search
     * @returns an observable.
     */
    getDiningAvailabilityList(
        swid: any,
        searchDate: string,
        partySize: number,
        searchParams: any
    ): Observable<any> {
        return this.explorerService.getDiningAvailabilityList(swid, searchDate, partySize, searchParams);
    }

    getVersion(): Observable<any> {
        const url = this.config.getValue(CONFIG_CONSTANTS.apiUrlKey);

        return this.http.get(`${url}/${API_CONSTANTS.webApiVersionEndpoint}`);
    }

    getLanguageBundle(version?: string): Observable<any>  {
        return this.localeHelperService.uriLocaleLoaded()
            .pipe(
                // we wait until the locale is fully loaded
                skipWhile((localeLoaded) => !localeLoaded),
                switchMap(() => this.bundleService.getBundle(version))
            );
    }

    getListAncestorEntities(type: string, version?: string): Observable<any>  {
        return this.authenticationService.getSWID().pipe(
            switchMap((swid: string | boolean) => this.explorerService.getListAncestorEntities(type, undefined, swid, version)),
            catchError(() => this.explorerService.getListAncestorEntities(type, undefined, false, version))
        );
    }

    /**
     * Gets search results from backend, based on a search term, and page number
     *
     * @param searchTerm the query to search
     * @param pageNumber the current page number used for pagination
     * @param version web api version
     * @returns an observable
     */
    getSearchQuery(searchQuery: string, pageNumber: number, version?: string): Observable<any>  {
        return this.authenticationService.getSWID().pipe(
            switchMap((swid: string | boolean) => this.searchService.getSearchQuery(searchQuery, pageNumber, swid, version)),
            // On the case Auth Service isn't working,
            // we try to call directly search service without SWID
            catchError(() => this.searchService.getSearchQuery(searchQuery, pageNumber, false, version))
        );
    }

    /**
     * Post search selection into analytics, based on a search id, document id, and swid
     *
     * @param searchId id of the search, changes on pagination or term
     * @param documentId the current page number used for pagination
     * @returns an observable
     */
    postSearchSelection(options: SearchSelection)  {
        this.authenticationService.getSWID(false)
            .subscribe({
                next: (swid) => this.searchService.postSearchSelectionHistory(options, swid),
                error: (error) => this.searchService.postSearchSelectionHistory(options, false)
            });
    }

    /**
     * @name            getCalendarData
     * @description     Get Calendar data from Explorer Service
     * @param           mode Calendar mode: month | five-day
     * @param           startDate Start Date
     * @param           version Api version (optional - defaults to v1 on inner services)
     * @returns         an Observable
     */
    getCalendarData(mode: string, startDate?: string, version?: string) {
        return this.explorerService.getCalendarData(mode, startDate, version);
    }

    /**
     * Get Conflicting items for a reservation time.
     *
     * @param swid the swid
     * @param date the date to check
     * @param time the time to check
     * @param productType the product type
     * @param guestIdentifier the guest identifier
     * @param version web api version
     * @returns an observable
     */
    getConflictingItems(
        swid: string,
        date: string,
        time: string,
        productType: string,
        guestIdentifier: string,
        version?: string
    ): Observable<any> {
        return this.reservationService.getConflictingItems(swid, date, time, productType, guestIdentifier, version);
    }

    getWatcherIndexedList(path, category = 'content', publishType = 'ContentPage') {
        const apiBase = this.getApiBase();
        const watcher = this.config.getValue(CONFIG_CONSTANTS.watcher);
        const watcherJson = `${apiBase}/watcher/${category}/${watcher.target}/${publishType}/indexed?path=${path}`;

        return this.http.get(watcherJson) as Observable<any>;
    }

    getCssOverridesUrl(siteId: string): string {
        const apiBase = this.getApiBase();

        return `${apiBase}/${API_CONSTANTS.themesOverridesEndpoint}/${siteId}/theme.css?`;
    }

    getDemoData(component: string): Observable<any> {
        const apiBase = this.getApiBase();

        return this.http.get(`${apiBase}/demo-data/${component}/`);
    }

    private getApiBase() {
        const baseUrl = this.config.getValue(CONFIG_CONSTANTS.apiUrlKey);
        const version = this.config.getValue(CONFIG_CONSTANTS.defaultVersionKey);

        return `${baseUrl}/${version}`;
    }
}
