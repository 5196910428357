import { Inject, Injectable } from '@angular/core';
// REMOVING P13N UNTIL WE MOVE TO ANGULAR 15, DUE TO DEPENDENCIES ISSUES
// import { P13nContentService } from '@wdpr/ngx-personalization';
import { LoggerService } from '@wdpr/ra-angular-logger';
import each from 'lodash-es/each';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import pickBy from 'lodash-es/pickBy';
import startsWith from 'lodash-es/startsWith';
import values from 'lodash-es/values';

import { ConfigService } from '@finder/core/config.service';
import { LocaleService } from '@finder/shared/services/locale-service/locale-service.service';
import sortByKeys from '@finder/shared/utils/object/sortByKeys';
import { FINDER_PROMO_CSS_CLASS } from '@finder/shared/components/promo/promo.component';
import { FinderPageKeyService } from '@finder/shared/services/finder-page-key/finder-page-key.service';
import { GetAnchorFromHtmlStringProvider, GET_ANCHOR_FROM_HTML_STRING } from '@finder/shared/utils/string/getAnchorFromHtmlString';

const LOG_PREFIX = 'FINDER-PERSONALIZATION-SVC --';
const PROMO_TILE_BLOCKS = { // @TODO - Get this from somewhere else? Config?
    wdw: [
        'wdw-site-pzn-cta1',
        'wdw-site-pzn-cta2',
        'wdw-site-pzn-cta3'
    ]
};

@Injectable()
export class FinderPersonalizationService {

    constructor(
        private pageKeyService: FinderPageKeyService,
        private config: ConfigService,
        // REMOVING P13N UNTIL WE MOVE TO ANGULAR 15, DUE TO DEPENDENCIES ISSUES
        // private p13nContentService: P13nContentService,
        private localeService: LocaleService,
        private logger: LoggerService,
        @Inject(GET_ANCHOR_FROM_HTML_STRING) public getAnchorFromHtmlString: GetAnchorFromHtmlStringProvider,
    ) {}

    /* istanbul ignore next */
    loadP13n() {
        const pageKey = this.pageKeyService.get();

        // [PRO-375913] - We ONLY want to personalize US English
        if (!this.localeService.isLocaleEnglish()) {
            return;
        }

        // If the brand doesn't support p13n then don't bother proceeding
        if (this.isP13nEnabled() === false) {
            return;
        }
        // REMOVING P13N UNTIL WE MOVE TO ANGULAR 15, DUE TO DEPENDENCIES ISSUES
        // this.p13nContentService.load(pageKey)
        //     .catch(err => this.logger.warn(`No personalization found for pageKey ${pageKey}:`, err));
    }

    /**
     * Map the original response data to the structure we want for the consuming promo
     * tiles component.
     */
    mapP13nResponse(data: any) {
        const result = [];
        const iconMap = get(data, 'iconMap', {});
        const siteId = this.config.getValue('siteId');

        // These tend to come thru in order, but we'll sort them just to be sure
        const locations = sortByKeys(get(data, 'locations', {}));

        // Iterate the keys and build out the formatted results we want
        each(PROMO_TILE_BLOCKS[siteId], (key) => {
            const loc = locations[key];
            if (!loc) {
                return;
            }

            const contentBlock = loc.personalizedContentBlocks;

            // We want to double-check and make sure that there is some content in the block
            if (!contentBlock || Object.keys(contentBlock).length === 0) {
                return;
            }

            // If we have content, let's get the first key's 'descriptions' content
            const content = contentBlock[Object.keys(contentBlock)[0]];
            const descriptions = content.descriptions;

            // Everything is built on the expectation of data - if we don't have it, get out...
            if (!descriptions || isEmpty(descriptions)) {
                return;
            }

            // Get the value of content from the 'ctaXXX' key under the 'descriptions' object
            const descriptionCta = values(
                pickBy(descriptions, (descVal, descKey) => startsWith(descKey, 'cta'))
            )[0];

            // If we cannot find the description (or if it doesn't exist), bug out...
            if (!descriptionCta) {
                this.logger.warn(`${LOG_PREFIX} Unable to find content for key: '${key}'`);

                return;
            }

            // We also need to grab the anchor out of the template - it is the only place to get it
            const anchorData = this.getAnchorFromHtmlString(descriptionCta.text);

            const promo: any = {
                id: content.id,
                contentId: content.contentId,
                displayName: content.displayName,
                icon: this.getPromoIcon(iconMap, content.id),
                title: descriptionCta.sections.header,
                background: descriptionCta.sections.background || this.getBackground(key)
            };

            // Not all the CTA's have body text, and we don't want a value of `undefined`
            // so we do this check before setting the value.
            if (descriptionCta.sections.body) {
                promo.subtitle = descriptionCta.sections.body;
            }

            // If there is anything wrong with parsing the template (or if it is a unit test without
            // support for DomParse in phantomjs) then we want to do the same thing as we did above
            // so we don't have 'undefined' values.
            if (anchorData && anchorData.text && anchorData.href) {
                promo.buttonText = anchorData.text;
                promo.link = anchorData.href;
            }

            result.push(promo);
        });

        return result;
    }

    /**
     * Checks if p13n is enabled by brand and locale.
     * Config example format:
     * {
     *     wdw: [ 'en_US' ],
     *     dlr: []
     * }
     */
    /* istanbul ignore next */
    private isP13nEnabled() {
        // Get the config (see above for format)
        const p13n = this.config.getValue('personalizationEnabled');

        // Using the current brand, grab the array of enabled locales (if any)
        const enabledLocales: string[] = p13n[this.config.getValue('siteId')] || [];

        // Check to see if the current locale is in the array of brand-enabled locales
        return enabledLocales.indexOf(this.localeService.getLocale()) > -1;
    }

    private getPromoIcon(iconMap, name) {
        // Strip the '-cta#' off the end to match to the icon map
        const promoName = name.replace(/-cta\d{1}$/, '');

        // Return the mapped icon or the fallback default if no icon mapped
        return iconMap[promoName] || iconMap.default;
    }

    /**
     * Gets the background promo CSS class name based on the content key
     */
    /* istanbul ignore next */
    private getBackground(key: any) {
        const num = key.substr(-1);

        // By default we'll just assume we use the primary, since sometimes there is only 1
        // CTA returned
        let bg = FINDER_PROMO_CSS_CLASS.background.primary;

        if (num === '2') {
            // If '2' then secondary
            bg = FINDER_PROMO_CSS_CLASS.background.secondary;
        } else if (num !== '1') {
            // If the number is not '1' or '2' then use the light bg
            bg = FINDER_PROMO_CSS_CLASS.background.light;
        }

        return bg;
    }
}
