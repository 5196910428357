import { Component, Input, OnInit } from '@angular/core';
import { CampaignStickyCtaInterface } from './campaign-sticky-cta.interface';


@Component({
    selector: 'finder-campaign-sticky-cta',
    templateUrl: './campaign-sticky-cta.component.html',
    styleUrls: ['./campaign-sticky-cta.component.scss']
})

export class CampaignStickyCtaComponent implements OnInit {

    @Input() stickyCtaData: CampaignStickyCtaInterface;

    buttonClass: string;

    constructor() {}

    ngOnInit() {
        this.setButtonClass();
    }

    setButtonClass() {
        this.buttonClass = this.stickyCtaData?.button?.alignment ? this.stickyCtaData?.button?.alignment : '';
    }
}
