import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, mergeMap, switchMap, take } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { defer, from, Observable, of } from 'rxjs';
import get from 'lodash-es/get';

import { OneIdService } from '@finder/shared/services/one-id/one-id.service';
import { Guest, OneIdResponse } from '@finder/shared/services/one-id/one-id.interface';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private injector: Injector) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Only update requests going to the detail API

        if (!req.url.includes('details-entity-simple') && !req.url.includes('personal-schedule-item') ) {
            // No header changes required
            return next.handle(req);
        }

        // The OneID service comes back as a promise
        const oneIdServiceResolver: any = this.injector.get(OneIdService);

        // Convert promise to observable and get the guest data
        return defer(() => from(oneIdServiceResolver)).pipe(
            // We only want the first response
            // "take" will complete on error
            take(1),
            // We now have the OneID service. Get the guest info
            switchMap((oneIdService: OneIdService) => from(oneIdService.getGuest())),
            /**
             * The guest is more than likely not logged in. Return undefined
             * to mergeMap and finish
             */
            catchError(err => of(void 0)),
            mergeMap((guestData: OneIdResponse<Guest>) => {
                if (!guestData) {
                    return next.handle(req);
                }

                /**
                 * If we have the id_token send that with the swid.
                 * If we don't send the refresh_token with the swid.
                 * Be careful of header size sent to the API
                 */
                const accessToken = get(guestData, 'data.token.access_token');
                const idToken = get(guestData, 'data.token.id_token');
                const refreshToken = get(guestData, 'data.token.refresh_token');
                if (idToken) {
                    req = req.clone({
                        setHeaders: {
                            'Authorization': accessToken,
                            'X-Disney-Internal-Id-Token': idToken,
                            'X-Disney-Internal-Swid': get(guestData, 'data.token.swid')
                        }
                    });
                } else if (refreshToken) {
                    /**
                     * Per Mark Lewis we should avoid this because outside of the OneID scope
                     * this will nuke the token.
                     */
                    req = req.clone({
                        setHeaders: {
                            'X-Disney-Internal-Refresh-Token': refreshToken,
                            'X-Disney-Internal-Swid': get(guestData, 'data.token.swid')
                        }
                    });
                }

                return next.handle(req);
            })
        );
    }
}
