import { NgModule } from '@angular/core';

import { LoadingSpinnerComponent } from './loading-spinner.component';

@NgModule({
    imports: [],
    declarations: [
        LoadingSpinnerComponent,
    ],
    providers: [],
    exports: [
        LoadingSpinnerComponent,
    ]
})
export class LoadingSpinnerModule {}
