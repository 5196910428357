import { InjectionToken } from '@angular/core';
import moment from 'moment';

import { DATE_FORMAT_CONSTANTS } from '@finder/shared/constants/date.constants';

/**
 * @description Outputs the date provided (or today if no date provided) as a formatted string.
 *              Date format: dddd, MMMM D, YYYY
 * @param date  the date to convert
 * @param       [locale=undefined] optional locale
 */
export const getFullDateString = (date: Date = new Date(), locale?: string): string => {
    let format = DATE_FORMAT_CONSTANTS.fullDateFormat;
    if (locale) {
        // Replace - and _ in the locale with nothing and look for the constant
        format = DATE_FORMAT_CONSTANTS[`${locale.toLowerCase().replace(/[^a-z]+/, '')}FullDateFormat`] || format;
    }

    return moment(date).format(format);
};

export type GetFullDateStringProvider = (date: Date, locale?: string) => string;
export const GET_FULL_DATE_STRING =
    new InjectionToken('GET_FULL_DATE_STRING', {
        factory: () => getFullDateString
    });
