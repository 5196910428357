import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import isEmpty from 'lodash-es/isEmpty';

@Injectable()
export class RouteStateService {

    private pathParamState = new BehaviorSubject<any>({});

    constructor() { }

    updatePathParamState(pathParam) {
        this.pathParamState.next(pathParam);
    }

    pathParam() {
        return this.pathParamState.asObservable()
            .pipe(
                filter((params) => !isEmpty(params)),
                distinctUntilChanged()
            );
    }
}
