import { Animation } from '@finder/shared/components/animations/animation/animation.interface';

export const getPopUpSprite = (data: Animation, isMobile: boolean = false) => {
    const scalingRatio = isMobile ? (data.scale || 0.5) : 1;
    const {
        height,
        frames,
        width,
        source,
        duration,
        repeat,
        popupDuration,
        easing,
        inset,
    } = data;
    const animationDelay = data.delay || 1000; // milliseconds
    const scaledWidth = Math.floor(width * scalingRatio);
    const scaledheight = Math.floor(height * scalingRatio);
    const componentInset = inset || 'auto';
    const componentInsetMobile = data.insetMobile || componentInset;

    const settings: any = {
        componentStyles: {
            height: `${scaledheight / parseInt(frames, 10)}px`,
            width: `${scaledWidth}px`,
            inset: `${componentInset}`,
        },
        spriteStyles: {
            height: `${scaledheight}px`,
            'background-image': `url("${source}")`
        },
        wrapperStyles: {
            height: `${scaledheight / parseInt(frames, 10)}px`,
            width: `${scaledWidth}px`,
            animation: `pop-up ${popupDuration || 0}ms ${easing}`,
        },
        animateStyles: {
            'animation-delay': `${animationDelay}ms`,
            'animation-name': 'slide-up',
            'animation-duration': `${duration || 0}ms`,
            'animation-timing-function': `steps(${frames})`,
            'animation-iteration-count': repeat,
            'animation-direction': 'normal',
        }
    };

    if (componentInsetMobile !== componentInset) {
        settings.componentStyles.insetMobile = componentInsetMobile;
    }

    return settings;
};
