import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoggerModule } from '@wdpr/ra-angular-logger';
import { CorrelationModule } from '@wdpr/ra-angular-correlation';
import { ErrorHandlerModule } from '@wdpr/ra-angular-error-handler';
import { DeviceDetectionModule } from '@wdpr/ra-angular-device-detection';
import { CdnModule } from '@wdpr/ra-angular-cdn';
import { GeolocationModule } from '@wdpr/ra-angular-geolocation';

import { ConfigService } from '@finder/core/config.service';
import { geoConfig } from '@finder/common/geo-config.constants';
import { SessionStorage } from '@finder/shared/services/session-storage/session-storage.service';

@NgModule({
    imports: [
        CommonModule,
        CorrelationModule,
        LoggerModule,
        ErrorHandlerModule,
        CdnModule,
        DeviceDetectionModule,
        GeolocationModule.configure(geoConfig)
    ],
    providers: [
        SessionStorage,
        ConfigService,
        {
            provide: 'CORRELATION_CONFIG',
            useFactory: (config: ConfigService) => {
                config.load(); // Need to call to ensure we have loaded the config data

                return {
                    wdprConversationCookieName: config.getValue('convIdCookieName'),
                    wdprConversationHeader: config.getValue('convIdHeaderName'),
                    wdprCorrelationHeader: config.getValue('corrIdHeaderName'),
                    wdprEndpointFilter: '/advanced-finder/log/?'
                };
            },
            deps: [ConfigService],
        },
    ]
})
export class CoreModule { }
