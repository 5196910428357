import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonComponent } from './button.component';
import { LinkIdDirectiveModule } from '@finder/shared/directives/link-id/link-id.module';
import { LocaleDirectiveModule } from '@finder/shared/directives/locale/locale.module';
import { FontIconModule } from '../font-icon/font-icon.module';

@NgModule({
    imports: [
        CommonModule,
        LinkIdDirectiveModule,
        LocaleDirectiveModule,
        FontIconModule,
    ],
    declarations: [
        ButtonComponent,
    ],
    providers: [],
    exports: [
        ButtonComponent,
    ]
})
export class ButtonModule {}
