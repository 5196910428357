import { InjectionToken } from '@angular/core';

/**
 * Load a css resource into the document head
 *
 * @param document the document
 * @param src resource src
 */
export const loadCssResource = (document, src: string): void => {
    const cssLinkPreloader = document.createElement('link');
    cssLinkPreloader.rel = 'preload';
    cssLinkPreloader.as = 'style';
    cssLinkPreloader.href = src;
    document.head.appendChild(cssLinkPreloader);

    const cssLink = document.createElement('link');
    cssLink.rel = 'stylesheet';
    cssLink.href = src;
    document.head.appendChild(cssLink);
};

export type LoadCssResourceProvider = (document: any, src: string) => void;
export const LOAD_CSS_RESOURCE =
    new InjectionToken('LOAD_CSS_RESOURCE', {
        factory: () => loadCssResource
    });
