<finder-layout
    [error]="dataError"
    [languageBundle]="languageBundle?.systemError"
    [loaded]="dataLoaded"
>
    <div class="header">
        <finder-syndicated-header
            *ngIf="useSyndicated"
            [url]="syndicatedLayoutConfigs['baseUrl'] + syndicatedLayoutConfigs['header'].path"
            [version]=" syndicatedLayoutConfigs['header'].siteConfigs[siteId]?.version"
            [siteId]="siteId"
            [environment]="environment"
        >
        </finder-syndicated-header>
    </div>
    <div class="content" role="main">
        <ng-container #rootComponents></ng-container>
        <router-outlet></router-outlet>
    </div>
    <div class="footer">
        <finder-syndicated-footer
            *ngIf="useSyndicated"
            [url]="syndicatedLayoutConfigs['baseUrl'] +  syndicatedLayoutConfigs['footer'].path"
            [version]="syndicatedLayoutConfigs['footer'].siteConfigs[siteId]?.version"
            [siteId]="siteId"
            [environment]="environment"
        >
        </finder-syndicated-footer>
    </div>
    <ng-template #customHeaderTemplate class="header"></ng-template>
    <ng-template #customFooterTemplate class="footer"></ng-template>
</finder-layout>
