/**
 * Converts object key values into a query params string.
 *
 * @param object the object to process.
 * @returns returns a string with the params.
 * @example
 *
 * const object = {
 *     key1: 'value1',
 *     key2: 'value2'
 * };
 *
 * const params = toQuerystring(object);
 * console.log(params);
 * // => ?key1=value1&key2=value2
 */
export const toQuerystring = (object: any): string => {
    const params = Object.keys(object).map(key => key + '=' + object[key]);

    return '?' + params.join('&');
};
