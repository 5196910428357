import { Injectable } from '@angular/core';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { BehaviorSubject } from 'rxjs';

import { WindowRef } from '@finder/shared/services/window-ref/window-ref.service';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    private localStorage: Storage;
    private change$ = new BehaviorSubject(null);

    get change() {
        return this.change$.asObservable();
    }

    constructor(
        private windowRef: WindowRef,
        private logger: LoggerService,
    ) {
        this.localStorage = this.windowRef.nativeWindow.localStorage;
    }

    get(key: string): any {
        try {
            return JSON.parse(this.localStorage.getItem(key));
        } catch (err) {
            this.logger.error(`Local storage 'get' error:`, err);
        }
    }

    set(key: string, value: any): boolean {
        try {
            // Set the value
            this.localStorage.setItem(key, JSON.stringify(value));

            // Update subject
            this.change$.next({ type: 'set', key, value });

            // Return
            return true;
        } catch (err) {
            this.logger.error(`Local storage 'set' error:`, err);

            return false;
        }
    }

    remove(key: string): boolean {
        try {
            // Remove value
            this.localStorage.removeItem(key);

            // Update subject
            this.change$.next({ type: 'remove', key });

            // Return
            return true;
        } catch (err) {
            this.logger.error(`Local storage 'remove' error:`, err);

            return false;
        }
    }
}
