import { Component, Input } from '@angular/core';
import { LayoutService } from '@finder/shared/services/layout/layout.service';

@Component({
    selector: 'finder-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
    @Input() error: any;
    @Input() languageBundle: any;
    @Input() loaded = false;

    constructor(
        public layoutService: LayoutService,
    ) { }
}
