import { Injectable, Inject } from '@angular/core';
import { ILocationMultiSelectOption } from '@finder/features/list/components/multi-select/multi-select-option.interface';
import { WindowRef } from '@finder/shared/services/window-ref/window-ref.service';
import { Facet } from '@finder/shared/services/filter-emitter-service/facet.class';
import { DOCUMENT } from '@angular/common';

const FILTERS_HASH_PATTERN = '#\/([^/]+)\/?';
const SORT_HASH_PATTERN = '#(?:\/.+)?\/sort=([^/]+)\/';
const ENTITY_ID_HASH_PATTERN = '#(?:\/.+)?\/id=([^/]+)\/';

@Injectable({
    providedIn: 'root'
})
export class FiltersHashService {

    constructor(
        private winRef: WindowRef,
        @Inject(DOCUMENT) private document: Document,
    ) { }

    updateHash(facets: Facet[], locations?: string[], sortOption?: string, entityId?: string) {
        let hash = '#/';

        if (locations && locations.length) {
            hash = hash.concat(locations.join(','));
            if (facets.length) {
                hash = hash.concat(',');
            }
        }
        hash = hash.concat(`${facets.map(facet => facet.id).join(',')}/`);

        // Only add the sort param if we have a sorting selected option and we are not on map view.
        if (sortOption && !entityId) {
            hash = hash.concat(`sort=${sortOption}/`);
        }

        if (entityId) {
            hash = hash.concat(`id=${entityId}/`);
        }
        // add resiliency against double slashes or an empty hash
        hash = hash.replace(/\/\//g, '/').replace(/#\/$/g, '');
        let pathName = this.winRef.nativeWindow.location.pathname;

        if (!hash.endsWith('/') && !pathName.startsWith('/')) {
            pathName = `/${pathName}`;
        }

        const updatedUrl = pathName.replace(pathName, pathName + hash).replace(/\/\//g, '/');

        history.replaceState({}, this.document.title, updatedUrl);
        this.winRef.nativeWindow.location.hash = hash;
    }

    checkHash(facets: any[], locations?: ILocationMultiSelectOption[], sortOptions?: any[]) {
        const { appliedLocations, appliedFacets } = this.checkFiltersFromHash(facets, locations);
        const sortOption = this.checkSortFromHash(sortOptions);
        const entityId = this.checkEntityIdFromHash();

        return {
            locations: appliedLocations,
            facets: appliedFacets,
            sortOption,
            entityId
        };
    }

    private checkFiltersFromHash(facets: any[], locations: ILocationMultiSelectOption[]) {
        const result = {
            appliedFacets: [],
            appliedLocations: []
        };

        if (!facets && !locations) {
            return result;
        }

        const hashSegment = this.getHashSegment(this.createRegExp(FILTERS_HASH_PATTERN));
        if (!hashSegment) {
            return result;
        }

        const hashElements = hashSegment[1].split(',');

        hashElements.forEach(element => {
            if (locations) {
                const location = locations.find(l => l.urlFriendlyId === element);
                if (location) {
                    result.appliedLocations.push(location.key);
                }
            }
            if (facets) {
                const facet = facets.find(f => f.urlFriendlyId === element || f.id === element);
                if (facet) {
                    result.appliedFacets.push(facet);
                }
            }
        });

        return result;
    }

    private checkSortFromHash(sortOptions: any) {
        const hashSegment = this.getHashSegment(this.createRegExp(SORT_HASH_PATTERN));
        if (!hashSegment) {
            return;
        }

        return sortOptions.find(option => option.hash === hashSegment[1]);
    }

    private checkEntityIdFromHash() {
        const hashSegment = this.getHashSegment(this.createRegExp(ENTITY_ID_HASH_PATTERN));

        return hashSegment && hashSegment[1];
    }

    private createRegExp(pattern: string) {
        return new RegExp(pattern, 'g');
    }

    private getHashSegment(regex: RegExp) {
        return regex.exec(this.winRef.nativeWindow.location.hash);
    }
}
