<div
    class="modal modal-overlay" #modalLayer
    [@modalState]="status"
    (@modalState.start)="animationStarted($event)"
    (@modalState.done)="animationDone($event)"
    (click)="backgroundDismiss($event)"
    style="display: none;"
    #modalOverlay>
    <div class="modal-content-wrapper">
        <div class="modal-content {{theme}}"
            [id]="'modal-content-' + id"
            role="dialog"
            aria-modal="true"
            [attr.aria-labelledby]="'modal-title-' + id">
            <div class="logo-title-wrapper">
                <div class="logo-wrapper" *ngIf="config?.data?.picture">
                    <finder-picture [picture]="config.data.picture"></finder-picture>
                </div>
                <div class="title-wrapper" *ngIf="!hideDesktopHeader">
                    <h3 [ngClass]="{'small-header': config.title?.length > MAX_TITLE_LENGTH }"
                        [id]="'modal-title-' + id"
                        class="title" #modalTitle
                        role="heading"
                        [innerHtml]="config.title">
                    </h3>
                </div>
            </div>

            <!--@TODO Update labels to use translations instead. -->
            <button *ngIf="!hideDesktopHeader"
                class="btn btn-close"
                [attr.aria-label]="config.data.labels?.buttonClose?.ariaLabel"
                (click)="dismiss()"
                (keydown.tab)="setCloseFocus()"
                #modalCloseBtn>
                <finder-font-icon name="icon_close-reversed" size="m"></finder-font-icon>
            </button>
            <button *ngIf="!config.hideDismissButton"
                class="btn btn-back"
                [attr.aria-label]="config.data.labels?.buttonClose?.ariaLabel"
                (click)="dismiss()">
                <finder-font-icon name="previous" size="m"></finder-font-icon>
            </button>
            <hr aria-hidden="true" class="gradient"/>
            <div class="body" [ngClass]="{'fullsize': config.fullSize}">
                <ng-template #modalContent></ng-template>
            </div>
            <div class="subtitle-wrapper" *ngIf="!hideDesktopHeader && config?.subtitle">
                <h3 class="subtitle"
                    [innerHtml]="config.subtitle">
                </h3>
            </div>
        </div>
    </div>
</div>
<div tabindex="0"></div>
