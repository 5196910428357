import { InjectionToken } from '@angular/core';
import isArray from 'lodash-es/isArray';

import { TRAVEL_AGENT } from '@finder/shared/constants/app.constants';

/**
 * Checks the URL to know if we are on a Travel Agent flow
 *
 * @param url string
 * @example
 *  /attractions/ => false
 *  /reservations/dta-packages-std/attractions/ => true
 *  /reservations/dta-packages-std-ca/attractions/ => true
 *  @returns boolean - true if it's a travel agent url, false if not
 */
export const isTravelAgent = (path: any) => {
    if (!path) {
        return false;
    }

    if (isArray(path) && path.length > 1) {
        path = `${path[0]}/${path[1]}`;
    }

    const paths = [
        TRAVEL_AGENT.store,
        TRAVEL_AGENT.caStore,
        TRAVEL_AGENT.wdwTicketsStore,
        TRAVEL_AGENT.dlrTicketsStore,
        TRAVEL_AGENT.storeCast,
        TRAVEL_AGENT.storeTaap,
        TRAVEL_AGENT.caStoreTaap
    ];

    const isTradePath = paths.find(tradePath => path.includes(tradePath)) ? true : false;

    return path.includes(`${TRAVEL_AGENT.prefix}/`) && isTradePath;
};

export type IsTravelAgentProvider = (url: string) => boolean;
export const IS_TRAVEL_AGENT =
    new InjectionToken('IS_TRAVEL_AGENT', {
        factory: () => isTravelAgent
    });
