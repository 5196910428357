import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CalendarResolver } from '@finder/shared/resolvers/calendar.resolver';
import { DynamicResolver } from '@finder/shared/resolvers/dynamic.resolver';
import { DynamicRouteMatcher } from '@finder/shared/route-matchers/dynamic-route.matcher';
import { ListResolver } from '@finder/shared/resolvers/list.resolver';
import { ListRouteMatcher } from '@finder/shared/route-matchers/list-route.matcher';
import { MyIdGuard } from '@finder/shared/guards/myId/myId.guard';
import { SearchResolver } from '@finder/shared/resolvers/search.resolver';

const routes: Routes = [
    {
        path: 'advanced-finder/demo',
        loadChildren: () => import('./features/demo/demo.module').then(m => m.DemoModule),
        canActivate: [MyIdGuard],
    },
    {
        path: ':locale/advanced-finder/debug',
        loadChildren: () => import('./features/debug/debug.module').then(m => m.DebugModule),
        canActivate: [MyIdGuard],
    },
    {
        path: 'advanced-finder/debug',
        loadChildren: () => import('./features/debug/debug.module').then(m => m.DebugModule),
        canActivate: [MyIdGuard],
    },
    {
        path: 'advanced-finder/eve',
        loadChildren: () => import('./features/eve/eve.module').then(m => m.EveModule),
        canActivate: [MyIdGuard],
    },
    {
        path: 'advanced-finder/theme-engine',
        loadChildren: () => import('./features/theme-engine/theme-engine.module').then(m => m.ThemeEngineModule),
        canActivate: [MyIdGuard],
    },
    {
        path: ':locale/ai-search',
        loadChildren: () => import('./features/ai-search/ai-search.module').then(m => m.AiSearchModule),
        canActivate: [MyIdGuard],
        pathMatch: 'full',
    },
    {
        path: 'ai-search',
        loadChildren: () => import('./features/ai-search/ai-search.module').then(m => m.AiSearchModule),
        canActivate: [MyIdGuard],
        pathMatch: 'full',
    },
    {
        path: ':locale/search',
        loadChildren: () => import('./features/search/search.module').then(m => m.SearchModule),
        pathMatch: 'full',
        data: {
            pageType: 'search'
        },
        resolve: { search: SearchResolver },
    },
    {
        path: 'search',
        loadChildren: () => import('./features/search/search.module').then(m => m.SearchModule),
        pathMatch: 'full',
        data: {
            pageType: 'search'
        },
        resolve: { search: SearchResolver },
    },
    {
        path: ':locale/calendars',
        loadChildren: () => import('./features/calendars/calendars.module').then(m => m.CalendarsModule),
        data: {
            pageType: 'calendars'
        },
    },
    {
        path: 'calendars',
        loadChildren: () => import('./features/calendars/calendars.module').then(m => m.CalendarsModule),
        data: {
            pageType: 'calendars'
        },
    },
    {
        path: ':reservations/:store/calendars',
        loadChildren: () => import('./features/calendars/calendars.module').then(m => m.CalendarsModule),
        data: {
            pageType: 'calendars'
        },
    },
    {
        path: 'park-calendars',
        loadChildren: () => import('./features/calendars/calendars.module').then(m => m.CalendarsModule),
        data: {
            pageType: 'calendars'
        }
    },
    {
        path: ':locale/park-calendars',
        loadChildren: () => import('./features/calendars/calendars.module').then(m => m.CalendarsModule),
        data: {
            pageType: 'calendars'
        },
    },
    {
        matcher: ListRouteMatcher,
        loadChildren: () => import('./features/list/list.module').then(m => m.ListModule),
        pathMatch: 'full',
        data: {
            pageType: 'list'
        },
        resolve: { list: ListResolver },
    },
    {
        matcher: DynamicRouteMatcher,
        loadChildren: () => import('./features/dynamic-loader/dynamic-loader.module').then(m => m.DynamicLoaderModule),
        data: {
            pageType: 'dynamic'
        },
        resolve: { svc: DynamicResolver },
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [
        ListResolver,
        SearchResolver,
        CalendarResolver,
        DynamicResolver,
    ]
})
export class AppRoutingModule { }
