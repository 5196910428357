import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {
    private _header = true;
    get header() {
        return this._header;
    }

    private _footer = true;
    get footer() {
        return this._footer;
    }

    constructor() { }

    toggleHeader(show = true) {
        this._header = show;
    }

    toggleFooter(show = true) {
        this._footer = show;
    }
}
