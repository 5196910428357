import { Directive, Output, EventEmitter, ElementRef, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { WindowRef } from '@finder/shared/services/window-ref/window-ref.service';

@Directive({
    selector: '[finderIntersection]'
})
export class FinderIntersectionDirective implements AfterViewInit, OnDestroy {
    @Input() rootMargin = '10px';
    @Input() threshold = 0;
    @Input() intersectOnce = false;
    @Input() intersectionEnabled = true;
    @Output() public finderIntersection: EventEmitter<IntersectionObserverEntry> = new EventEmitter();

    elementObserver: IntersectionObserver;

    constructor(
        private elementRef: ElementRef,
        private windowRef: WindowRef
    ) {}

    ngAfterViewInit() {
        // Make sure we should track the element
        if (!this.intersectionEnabled) {
            return;
        }

        // Check for compatibility
        if (!this.isCompatible()) {
            return;
        }

        const config: IntersectionObserverInit = {
            rootMargin: this.rootMargin,
            threshold: this.threshold
        };

        this.elementObserver = new this.windowRef.nativeWindow.IntersectionObserver(entries => {
            entries.forEach((entry: IntersectionObserverEntry) => {
                if (entry.isIntersecting && this.intersectOnce) {
                    this.disconnect();
                }
                this.finderIntersection.emit(entry);
            });
        }, config);

        this.elementObserver.observe(this.elementRef.nativeElement);
    }

    ngOnDestroy() {
        this.disconnect();
    }

    disconnect() {
        if (this.elementObserver) {
            this.elementObserver.unobserve(this.elementRef.nativeElement);
            this.elementObserver.disconnect();
        }
    }

    private isCompatible() {
        return this.windowRef.nativeWindow && 'IntersectionObserver' in this.windowRef.nativeWindow;
    }
}
