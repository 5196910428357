<div class="finder-layout" [ngClass]="{'loading': !loaded}">
    <!-- Header -->
    <header class="finder-header" *ngIf="layoutService.header">
        <ng-content select=".header"></ng-content>
    </header>
    <!-- Loading Spinner -->
    <finder-loading-spinner *ngIf="!loaded"></finder-loading-spinner>
    <!-- Actual Content -->
    <main class="finder-content">
        <finder-system-error  *ngIf="error" [status]="error.status" [bundle]="languageBundle"></finder-system-error>
        <p *ngIf="error?.error?.myIdGated" class="gated-myid-error">
            {{ error.error.myIdGatedMessage }}
        </p>
        <ng-content select=".content"></ng-content>
    </main>
    <!-- Footer -->
    <footer class="finder-footer" *ngIf="layoutService.footer">
        <ng-content select=".footer"></ng-content>
    </footer>
</div>
