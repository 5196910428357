import { InjectionToken } from '@angular/core';

// Get YYYY-MM-DD format from date
export const getYMD = (date: Date) =>
    ([
        date.getFullYear(),
        `0${date.getMonth() + 1}`.slice(-2),
        `0${date.getDate()}`.slice(-2),
    ].join('-'));

export type GetYMDProvider = (date: Date, locale?: string) => string;
export const GET_YMD =
    new InjectionToken('GET_YMD', {
        factory: () => getYMD
    });
