import { UrlSegment, UrlMatchResult } from '@angular/router';

import { LOCALE_REGEXP, MODAL_DEEP_LINKS, TRAVEL_AGENT } from '@finder/shared/constants/app.constants';
import { isTravelAgent } from '@finder/shared/utils/url/isTravelAgent';

/**
 * Routes handled by this matcher:
 *
 * :type/:id
 * :type/:id/:modal
 * :type/:location/:id
 * :type/:location/:id/:modal
 *
 * :locale/:type/:id
 * :locale/:type/:id/:modal
 * :locale/:type/:location/:id
 * :locale/:type/:location/:id/:modal
 *
 * :reservations/:store/:type/:id
 * :reservations/:store/:type/:id/:modal
 * :reservations/:store/:type/:location/:id
 * :reservations/:store/:type/:location/:id/:modal
 *
 * :locale/:reservations/:store/:type/:id
 * :locale/:reservations/:store/:type/:id/:modal
 * :locale/:reservations/:store/:type/:location/:id
 * :locale/:reservations/:store/:type/:location/:id/:modal
 */
export const DynamicRouteMatcher = (url: UrlSegment[]): UrlMatchResult | null => {
    const consumed: UrlSegment[] = [];
    const posParams: { [key: string]: UrlSegment } = {};

    /**
     * At this point we don't have access to the service response.
     * This configuration has not been changed in the last months,
     * that's why we moved it from vendo to the app constants.
     */

    const deepLinksArray = Object.values(MODAL_DEEP_LINKS);

    // Copy the url array to avoid having conflicts.
    const segments = url.slice();

    if (segments.length === 0) {
        return null;
    }

    let modal;
    let params = [];

    // Check if the first segment is a locale.
    if (segments[0].path.match(LOCALE_REGEXP.lacd)) {
        const locale = segments.shift();

        // The locale is the first segment to be set.
        consumed.push(locale);
        posParams['locale'] = locale;
    }

    // Check if the last segment is a modal deep link.
    if (deepLinksArray.includes(segments.at(-1)?.path)) {
        modal = segments.pop();
    }

    if (isTravelAgent(segments)) {
        switch (segments.length) {
            case 3:
                // i.e.: /reservations/dta-packages-std/experience-updates
                params = [TRAVEL_AGENT.prefix, 'store', 'id'];
                break;
            case 4:
                // i.e.: /reservations/dta-packages-std/destinations/hollywood-studios/
                params = [TRAVEL_AGENT.prefix, 'store', 'type', 'id'];
                break;
            case 5:
                // i.e.: /reservations/dta-packages-std/attractions/magic-kingdom/pirates-adventures/
                params = [TRAVEL_AGENT.prefix, 'store', 'type', 'location', 'id'];
                break;
        }
    } else {
        /**
         * We already checked the presence of the locale and the modal deep link in the url.
         * Depending on the size of the remaining segments we try to set either:
         * [ 'id' ], ['type', 'id'] or ['type', 'location', 'id'].
         */
        switch (segments.length) {
            case 1:
                params = ['id'];
                break;
            case 2:
                // i.e.: /destinations/hollywood-studios/
                params = ['type', 'id'];
                break;
            case 3:
                // i.e.: /attractions/magic-kingdom/pirates-adventures/
                params = ['type', 'location', 'id'];
                break;
        }
    }

    for (let i = 0; i < params.length; i++) {
        const param = params[i];
        const current = segments[i];

        consumed.push(current);
        posParams[param] = current;
    }

    // The modal is the last segment to be set.
    if (modal) {
        consumed.push(modal);
        posParams['modal'] = modal;
    }

    return {
        consumed,
        posParams
    };
};
