import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { filter, first, switchMap } from 'rxjs/operators';
import { SeoService } from '@wdpr/ra-angular-seo-metadata';

import { ConfigService } from '@finder/core/config.service';
import { CONFIG_CONSTANTS, SEO_OPTIONS } from '@finder/shared/constants/app.constants';
import getApiBase from '@finder/shared/utils/string/getApiBase';
import { FinderPageKeyService } from '@finder/shared/services/finder-page-key/finder-page-key.service';
import { IsValidDateString, IS_VALID_DATE_STRING } from '../../utils/date/isValidDateString';

@Injectable({
    providedIn: 'root',
})
export class FinderSeoService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private pageKeyService: FinderPageKeyService,
        private seoService: SeoService,
        @Inject(IS_VALID_DATE_STRING) private isValidDateString: IsValidDateString,
    ) { }

    // This is basically copy/pasted from the RA SEO code, but since we need
    // to do the custom retrieval it won't get the SEO for us when we need it :(
    get(pageKey, options) {
        pageKey = this.pageKeyService.stripTrailingPageKeyDate(pageKey, true, true);
        const base = this.config.getValue(CONFIG_CONSTANTS.apiUrlKey);
        const version = this.config.getValue(CONFIG_CONSTANTS.defaultVersionKey);
        const apiBase = getApiBase(base, version);
        const relativeUrl = `${apiBase}${options.serverPath}/${pageKey}.json`;
        const url = options.serverUrl ?
            options.serverUrl + relativeUrl :
            relativeUrl;

        return this.http.get(url);
    }

    getPageKeyAndSetSeo() {
        // Clear out the previous request so we don't request a page we've already visited
        this.pageKeyService.clearPageKey();
        this.pageKeyService.pageKeyReady().pipe(
            // The first emit is an empty string
            filter(result => result !== ''),
            // Listen for the first value then unsubscribe
            first(),
            switchMap((result) => this.get(result, SEO_OPTIONS))).subscribe(seo => {
            this.seoService.setSeoData(seo);
        });
    }
}
