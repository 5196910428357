import sortBy from 'lodash-es/sortBy';
import map from 'lodash-es/map';
import fromPairs from 'lodash-es/fromPairs';

/**
 * Sorts keys in an object.
 *
 * @param object - The object for key sorting
 */
const sortByKeys = (object): any => {
    const keys = Object.keys(object);
    const sortedKeys = sortBy(keys);

    return fromPairs(
        map(sortedKeys, key => [key, object[key]])
    );
};

export default sortByKeys;
