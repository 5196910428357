import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { InterstitialModal } from './interstitial-modal.interface';

@Injectable()
export class InterstitialService {
    private interstitialData = new BehaviorSubject<InterstitialModal>({});


    constructor() { }

    getInterstitialData(): Observable<InterstitialModal> {
        return this.interstitialData.asObservable();
    }

    setInterstitialData(interstitialData: InterstitialModal): void {
        this.interstitialData.next(interstitialData);
    }
}
