import { Component, Input, ElementRef, OnInit } from '@angular/core';
import get from 'lodash-es/get';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { HorizontalRulePosition, HorizontalRule } from './horizontal-rule.interface';
import { ViewportDetection } from '@finder/shared/services/viewport-detection/viewport-detection.service';

@Component({
    selector: 'finder-horizontal-rule',
    templateUrl: './horizontal-rule.component.html',
    styleUrls: ['./horizontal-rule.component.scss'],
})
export class HorizontalRuleComponent implements OnInit {
    @Input() data: any = {};
    @Input() position: HorizontalRulePosition;

    private isMobile: boolean;
    private destroy$ = new Subject<void>();

    hrClass: string;
    hrStyle: HorizontalRule;

    constructor(
        private viewportDetection: ViewportDetection,
        private elementRef: ElementRef,
    ) { }

    ngOnInit() {
        // If there's no data then just stop
        if (!this.data) {
            return;
        }

        // Set the class - if this is a top/bottom rule the data is structured a
        // little differently and takes into account the `position`, because we
        // _could_ have top AND bottom rules for a block, but if this is a component
        // entry then the plain old `hrClass` value may be used
        this.hrClass = get(this.data, ['class', this.position]) || this.data.hrClass;

        this.updateHrImageValues();
        this.setHorizontalRuleProps();

        this.viewportDetection.onViewportResize()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.updateHrImageValues();
                this.setHorizontalRuleProps();
            });

    }

    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy() {
        // Ignore the if just for coverage, there's no need to test unsubscribe
        /* istanbul ignore next */
        this.destroy$.next();
        this.destroy$.complete();
    }

    updateHrImageValues() {
        this.isMobile = this.viewportDetection.isMobile();
        this.hrStyle = (this.isMobile && this.data.mobileHrStyle) ? this.data.mobileHrStyle : this.data.hrStyle;
    }

    private setHorizontalRuleProps() {
        this.getElementReference().style.setProperty(`--hr-rule-background-image`, this.hrStyle?.url ? this.hrStyle.url : 'unset');
        this.getElementReference().style.setProperty(`--hr-rule-width`, this.hrStyle?.width ?? 'auto');
        this.getElementReference().style.setProperty(`--hr-rule-height`, this.hrStyle?.height ?? 'auto');
    }

    /* istanbul ignore next */
    getElementReference() {
        return this.elementRef.nativeElement;
    }
}
