import { Component, Input, Inject, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import get from 'lodash-es/get';
import '@wdpr/wc-travel-agent-session-background-refresh';

import { toQuerystring } from '@finder/shared/utils/object/toQuerystring';
import { SyndicatedOptions } from '@finder/shared/components/syndicated-layout/syndicated-options.interface';
import { brandHeaderDefaultOptions, cachedPath } from '@finder/shared/components/syndicated-layout/syndicated-options';
import { SYNDICATED_HEADER_ANIMATIONS } from '@finder/shared/components/syndicated-layout/syndicated-header/syndicated-header.animations';
import { WindowRef } from '@finder/shared/services/window-ref/window-ref.service';
import { TravelAgentService } from '@finder/shared/services/travel-agent/travel-agent.service';
import { IsParkCalendarsProvider, IS_PARK_CALENDARS_URL } from '@finder/shared/utils/url/isParkCalendarsUrl';

@Component({
    selector: 'finder-syndicated-header',
    templateUrl: './syndicated-header.component.html',
    styleUrls: ['./syndicated-header.component.scss'],
    animations: [...SYNDICATED_HEADER_ANIMATIONS]
})
export class SyndicatedHeaderComponent implements OnInit {
    @Input() url: string;
    @Input() version: number;
    @Input() locale: string;
    @Input() language: string;
    @Input() siteId: string;
    @Input() environment: string;
    @Input() source: string;

    syndicatedHeaderUrl: string;
    defaultOptions: SyndicatedOptions;
    useCached = false; // @TODO validate if the user is not loggedIn to use cache.
    stateName: string;
    // Travel Agent session values
    extendTradeSessionUrl: string;
    extendTradeSessionInterval: number;
    enableBackgroundRefresh = false;

    constructor(
        private winRef: WindowRef,
        private travelAgentService: TravelAgentService,
        @Inject(IS_PARK_CALENDARS_URL) private isParkCalendars: IsParkCalendarsProvider,
    ) {}

    ngOnInit() {
        this.travelAgentService.init();
        // We enable the background refresh for TRADE session if we are on a trade page,
        // and perpetual session is disabled (coming from API response).
        this.enableBackgroundRefresh = this.travelAgentService.isValidTradeFlow() &&
            !this.travelAgentService.getPerpetualSessionEnabled();
        if (this.enableBackgroundRefresh) {
            this.extendTradeSessionUrl = this.travelAgentService.extendTradeSessionUrl;
            this.extendTradeSessionInterval = this.travelAgentService.extendTradeSessionInterval;
        }
        this.defaultOptions = {...brandHeaderDefaultOptions[this.siteId]};
        this.defaultOptions.version = this.version || this.defaultOptions.version;
        this.defaultOptions.locale = this.locale || this.defaultOptions.locale;
        this.defaultOptions.language = this.language || this.defaultOptions.language;
        this.defaultOptions.siteOverride = this.siteId;

        // park-calendars is a very particular page, that is shared for some travel trade clients,
        // and per business requirements, we shouldn't show any links at all, even on the nav bar.
        if (this.isParkCalendars(this.winRef.nativeWindow.location.pathname)) {
            this.defaultOptions.showSections = 'false';
            this.defaultOptions.searchBar = 'false';
            this.defaultOptions.showSignInSignOut = 0;
            this.defaultOptions.languageSelector = 0;
            this.defaultOptions.showDisney = 'false';
            this.defaultOptions.hideNavigation = true;
            this.defaultOptions.logoRedirect = false;
        }

        this.syndicatedHeaderUrl = `${this.url}${this.useCached ? cachedPath : '/'}${toQuerystring(this.defaultOptions)}`;
        this.stateName = 'loaded';
        // @TODO Remove this hack that prevents language modal to switch page's locale.
        // @see https://myjira.disney.com/browse/GIT-9534
        this.setupSyndicatedToggles();
    }

    headerStateDone(event: AnimationEvent) {
        // We want to make sure that the animation is complete and the style has been set
        // by the animation module. Once that is done we can manually remove the style attr.
        setTimeout(() => {
            event['element'].removeAttribute('style');
        });
    }

    /**
     * Setup toggles needed for Syndicated Navbar.
     *
     * @TODO Remove this hack that prevents language modal to switch page's locale.
     * @see https://myjira.disney.com/browse/GIT-9534 see linked ticket for needed devwork.
     * @see https://myjira.disney.com/browse/GIT-9534 for new link to old ticket
     */
    setupSyndicatedToggles() {
        const releaseToggles = [
            'disableLocaleCookie',
            'disableLanguageSelectionCookie'
        ];
        const winRef = this.winRef.nativeWindow;

        /**
         * Only check for 1 sec. We won't need this whole function
         * once the new navigation app is everywhere.
         */
        interval(100)
            .pipe(
                take(10)
            ).subscribe(() => {
                if (get(winRef, 'PEP.Config.Toggles.Release', false)) {
                    releaseToggles.forEach((toggle) => {
                        winRef.PEP.Config.Toggles.Release[toggle] = true;
                    });
                }
            });
    }
}

