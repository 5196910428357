import { UrlSegment, UrlMatchResult } from '@angular/router';

import { ROUTE_EXCEPTIONS, ROUTE_PARKS, ROUTE_TYPE, ROUTE_SECTION } from '@finder/shared/resolvers/list-routing.constants';
import { LOCALE_REGEXP, TRAVEL_AGENT } from '@finder/shared/constants/app.constants';
import { isTravelAgent } from '@finder/shared/utils/url/isTravelAgent';

/**
 * Routes handled by this matcher:
 *
 * :type
 * :type/:section
 * :type/:section/:park
 * :reservations/:store/:type
 * :reservations/:store/:type/:section
 */
export const ListRouteMatcher = (url: UrlSegment[]): UrlMatchResult | null => {
    const consumed: UrlSegment[] = [];
    const posParams: { [key: string]: UrlSegment } = {};
    // Copy the url array to avoid having conflicts.
    const segments = url.slice();

    if (segments.length === 0) {
        return null;
    }

    // Check if the first segment is a locale.
    if (segments[0].path.match(LOCALE_REGEXP.lacd)) {
        const locale = segments.shift();

        // The locale is the first segment to be set.
        consumed.push(locale);
        posParams['locale'] = locale;
    }

    /**
     * We already checked the presence of the locale in the url.
     * Depending on the size of the remaining segments we try to set either:
     * ['type'] or ['type', 'section'] (In case it's a trade path, we also check reservations + store).
     * section can be park, map, or any other param.
     */
    const params = isTravelAgent(segments) ?
        getListTravelAgentParams(segments) :
        getListDefaultParams(segments);

    if (params.length === 0) {
        return null;
    }

    for (let i = 0; i < params.length; i++) {
        const param = params[i];
        const current = segments[i];

        consumed.push(current);
        posParams[param] = current;
    }

    return {
        consumed,
        posParams
    };
};

const getListTravelAgentParams = (segments: UrlSegment[]): string[] => {
    const params = [];

    switch (segments.length) {
        case 3: {
            const type = segments[2].path;
            // i.e.: /reservations/dta-packages-std/attractions/
            if (ROUTE_TYPE.includes(type)) {
                return [TRAVEL_AGENT.prefix, 'store', 'type'];
            }
            break;
        }
        case 4: {
            // i.e.: /reservations/dta-packages-std/attractions/magic-kingdom
            const type = segments[2].path;
            const section = segments[3].path;

            if (!ROUTE_TYPE.includes(type) || ROUTE_EXCEPTIONS.includes([type, section].join('/'))) {
                return params;
            }

            if (ROUTE_PARKS.includes(section)) {
                return [TRAVEL_AGENT.prefix, 'store', 'type', 'park'];
            }

            if (ROUTE_SECTION.includes(section)) {
                return [TRAVEL_AGENT.prefix, 'store', 'type', section];
            }
            break;
        }
        case 5: {
            // i.e.: /reservations/dta-packages-std/attractions/map/magic-kingdom
            const type = segments[2].path;
            const section = segments[3].path;
            const park = segments[4].path;

            if (ROUTE_TYPE.includes(type) && ROUTE_SECTION.includes(section) && ROUTE_PARKS.includes(park)) {
                return [TRAVEL_AGENT.prefix, 'store', 'type', section, 'park'];
            }
            break;
        }
    }

    return params;
};

const getListDefaultParams = (segments: UrlSegment[]): string[] => {
    const params = [];

    switch (segments.length) {
        case 1: {
            const type = segments[0].path;
            // i.e.: /attractions
            if (ROUTE_TYPE.includes(type)) {
                return ['type'];
            }
            break;
        }
        case 2: {
            // i.e.: /attractions/magic-kingdom/ or /attractions/map/
            const type = segments[0].path;
            const section = segments[1].path;

            if (!ROUTE_TYPE.includes(type) || ROUTE_EXCEPTIONS.includes([type, section].join('/'))) {
                return params;
            }

            if (ROUTE_PARKS.includes(section)) {
                return ['type', 'park'];
            }

            if (ROUTE_SECTION.includes(section)) {
                return ['type', section];
            }
            break;
        }
        case 3: {
            // i.e.: /attractions/map/magic-kingdom
            const type = segments[0].path;
            const section = segments[1].path;
            const park = segments[2].path;

            if (ROUTE_TYPE.includes(type) && ROUTE_SECTION.includes(section) && ROUTE_PARKS.includes(park)) {
                return ['type', section, 'park'];
            }
            break;
        }
    }

    return params;
};
