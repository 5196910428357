import { Injectable, Inject, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { DOCUMENT } from '@angular/common';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { TRAVEL_AGENT, CONFIG_CONSTANTS } from '@finder/shared/constants/app.constants';
import { WindowRef } from '@finder/shared/services/window-ref/window-ref.service';
import { ConfigService } from '@finder/core/config.service';
import { LoadingService } from '@finder/shared/services/loading-service/loading-service.service';
import { isTravelAgent } from '@finder/shared/utils/url/isTravelAgent';

/**
 * Class that provides methods to handle travel agent session stuff
 */
@Injectable()
export class TravelAgentService {

    extendTradeSessionInterval: number;
    extendTradeSessionUrl: string;

    private isValidTradeUrl: boolean;
    private isPerpetualSessionEnabled = false;
    private hasSessionCookie: boolean;
    private validTravelAgentFlow: boolean;

    constructor(
        private winRef: WindowRef,
        private cookieService: CookieService,
        private configService: ConfigService,
        private loadingService: LoadingService,
        private logger: LoggerService,
        private zone: NgZone,
        private http: HttpClient,
        @Inject(DOCUMENT) private document: Document,
    ) { }

    init() {
        this.isValidTradeUrl = isTravelAgent(this.winRef.nativeWindow.location.pathname);
        this.hasSessionCookie = this.cookieService.get(TRAVEL_AGENT.cookieName) ? true : false;
        this.validTravelAgentFlow = this.isValidTradeUrl && this.hasSessionCookie;
        this.extendTradeSessionInterval = this.configService.getValue(CONFIG_CONSTANTS.tradeSessionExtendInterval);
        this.extendTradeSessionUrl =
            `${this.configService.getValue(CONFIG_CONSTANTS.apiUrlKey)}${this.configService.getValue(CONFIG_CONSTANTS.tradeSessionUrl)}`;
        if (this.validTravelAgentFlow) {
            this.zone.runOutsideAngular(() => this.document.addEventListener('requestError', this.reloadPage.bind(this), true));
            // We call trade session endpoint here so, if perpetual session is enabled, the cookie takes
            // that expiration time instead of the one from other flows.
            this.http.get(this.extendTradeSessionUrl)
                .pipe(
                    catchError((error) => {
                        this.logger.error(
                            `TRADE EXTEND SESSION CALL ERROR. Reason: ${error.status}|${error.message}`
                        );

                        return throwError(error);
                    }))
                .subscribe((data: any) => {
                    this.isPerpetualSessionEnabled = data.isPerpetualSession ?? false;
                });
        }
    }

    isValidTradeFlow() {
        return this.validTravelAgentFlow;
    }

    isValidTravelAgentUrl() {
        return this.isValidTradeUrl;
    }

    showErrorIfCookieIsMissing() {
        const cookieIsMissing = this.isValidTradeUrl && !this.hasSessionCookie;
        if (cookieIsMissing) {
            this.setSessionError();

            return true;
        }

        return false;
    }

    getTravelAgentStore() {
        const url = this.winRef.nativeWindow.location.pathname;
        const paths = [
            TRAVEL_AGENT.store,
            TRAVEL_AGENT.caStore,
            TRAVEL_AGENT.wdwTicketsStore,
            TRAVEL_AGENT.dlrTicketsStore
        ];

        return paths.find(path => url.includes(`${path}/`)) || '';
    }

    getPerpetualSessionEnabled() {
        return this.isPerpetualSessionEnabled;
    }

    setSessionError() {
        this.logger.error('Travel Agent Error: invalid session');
        this.loadingService.setErrorState({
            name: 'Travel Agent Session Error',
            status: 599
        });
        this.loadingService.setLoadingState(true);
    }

    reloadPage() {
        // remove session cookie if exists because there's an error
        this.cookieService.delete(TRAVEL_AGENT.cookieName, '/');
        // Reload the page to show error
        this.winRef.nativeWindow.location.reload();
    }
}
