import { Injectable } from '@angular/core';

/**
 * Return the global native browser window object
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
const _window = (): any => window;

/**
 * Export a reference of the global native browser window object.
 */
@Injectable({
    providedIn: 'root',
})
export class WindowRef {
    get nativeWindow(): any | Window {
        return _window();
    }
}
