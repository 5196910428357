import { Animation } from '@finder/shared/components/animations/animation/animation.interface';

export const getSlideLRAnimation = (data: Animation, isMobile: boolean = false, parentHeight: number) => {
    const { height, frames, width, source, duration } = data;
    const scalingRatio = parentHeight ?
        getScalingRatio(parentHeight, height, frames) :
        isMobile ? (data.scale || 0.5) : 1;
    const animationDelay = data.delay || '500'; // milliseconds
    const boomerangEffect = typeof data.boomerang === 'string' ? data.boomerang === 'true' : true;
    const scaledWidth = Math.floor(width * scalingRatio);
    const scaledheight = Math.floor(height * scalingRatio);
    const leftPosition = 150; // px

    return {
        spriteStyles: {
            height: `${scaledheight}px`,
            'background-image': `url("${source}")`
        },
        wrapperStyles: {
            height: `${scaledheight / parseInt(frames, 10)}px`,
            width: `${scaledWidth}px`,
            left: `-${(leftPosition * scalingRatio) - 1}px`
        },
        animateContentStyles: {
            animation: `slide-block-lr ${parseInt(duration, 10) * 2}ms`
        },
        animateStyles: {
            'animation-delay': `${animationDelay}ms`,
            'animation-name': boomerangEffect ? 'slide-up-boomerang' : 'slide-up',
            'animation-duration': `${duration}ms`,
            'animation-timing-function': `steps(${frames})`
        }
    };
};

const getScalingRatio = (parentHeight, height, frames) => {
    // buzz will need to be bigger that the container and
    // show a little overflow
    const animationOffset = 70;
    const scaledHeight = (height / parseInt(frames, 10)) - animationOffset;

    return parentHeight / scaledHeight;
};
