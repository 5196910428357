<div class="icon-tooltip">
    <finder-tooltip [id]="id" [tooltip]="tooltip" [position]="position" [title]="title">
        <finder-font-icon
            [name]="name"
            [title]="title"
            [size]="size"
            [color]="color"
        ></finder-font-icon>
    </finder-tooltip>
</div>
