import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

import { MODAL_STATE } from '@finder/shared/components/modal/modal.constants';

export const MODAL_ANIMATIONS =
    [
        trigger('modalState', [
            state(
                `void, ${MODAL_STATE.unloaded}, ${MODAL_STATE.closed}, ${MODAL_STATE.mobileClosed},
                ${MODAL_STATE.hidden}, ${MODAL_STATE.mobileHidden}`,
                style({
                    display: 'none'
                })
            ),
            state(`${MODAL_STATE.opened}, ${MODAL_STATE.mobileOpened}`, style({
                display: 'block'
            })),
            transition(`* => ${MODAL_STATE.opened}`, animate(300, keyframes([
                style({ display: 'block', opacity: 0, offset: 0 }),
                style({ display: 'block', opacity: 1, offset: 1 }),
            ])),
            ),
            transition(`* => ${MODAL_STATE.closed}, * => ${MODAL_STATE.hidden}`, [
                animate(300, keyframes([
                    style({ opacity: 1, offset: 0 }),
                    style({ opacity: 0, offset: 1 }),
                ])),
            ]),
            transition(`* => ${MODAL_STATE.mobileOpened}`, animate(400, keyframes([
                style({ display: 'block', opacity: 1, transform: 'translateX(100%)', offset: 0 }),
                style({ display: 'block', opacity: 1, transform: 'translateX(0%)', offset: 1 }),
            ])),
            ),
            transition(`* => ${MODAL_STATE.mobileClosed}, * => ${MODAL_STATE.mobileHidden}`, [
                style({ overflow: 'hidden' }),
                animate(400, keyframes([
                    style({ opacity: 1, transform: 'translateX(0)', offset: 0 }),
                    style({ opacity: 1, transform: 'translateX(100%)', offset: 1 }),
                ])),
            ]),
        ])
    ];
