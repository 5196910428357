import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

import { GetDateStringProvider, GET_DATE_STRING } from '@finder/shared/utils/string/getDateString';
import { GetFullDateStringProvider, GET_FULL_DATE_STRING } from '@finder/shared/utils/string/getFullDateString';
import { GetYMDProvider, GET_YMD } from '@finder/shared/utils/string/getYMD';
import { DateRange, FormattedDate } from '@finder/features/calendars/calendars.interface';
import { FiltersHashService } from '@finder/features/list/services/filters-hash/filters-hash.service';
import { WindowRef } from '@finder/shared/services/window-ref/window-ref.service';
import { LocaleService } from '@finder/shared/services/locale-service/locale-service.service';
import { IsParkCalendarsProvider, IS_PARK_CALENDARS_URL } from '@finder/shared/utils/url/isParkCalendarsUrl';
import { CALENDAR_CONSTANTS, TRAVEL_AGENT } from '@finder/shared/constants/app.constants';
import { TravelAgentService } from '@finder/shared/services/travel-agent/travel-agent.service';
import { DATE_FORMAT_CONSTANTS } from '@finder/shared/constants/date.constants';

@Injectable()
export class CalendarsService {

    formattedDate: string;
    fullDateString: string;
    selectedDate: Date = new Date();
    dateRange: DateRange;

    private locations = new BehaviorSubject<any>({});
    private date = new BehaviorSubject<Date>(null);
    private loading = new BehaviorSubject<boolean>(false);
    private viewAll = new BehaviorSubject<any>(null);
    private showLinks: boolean;
    private locale: string;

    constructor(
        @Inject(GET_DATE_STRING) private getDateString: GetDateStringProvider,
        @Inject(GET_FULL_DATE_STRING) private getFullDateString: GetFullDateStringProvider,
        @Inject(GET_YMD) private getYMD: GetYMDProvider,
        @Inject(IS_PARK_CALENDARS_URL) private isParkCalendars: IsParkCalendarsProvider,
        private filtersHash: FiltersHashService,
        private winRef: WindowRef,
        private localeService: LocaleService,
        private travelAgentService: TravelAgentService
    ) {
        this.locale = this.localeService.getLocale();
        this.formattedDate = this.getYMD(this.selectedDate);
        this.setDateRange(this.selectedDate);

        const currentPath = this.getCurrentPath();
        // We want to hide all calendar links for trade pages, and for special park-calendars page.
        this.showLinks = !this.travelAgentService.isValidTradeFlow() && !this.isParkCalendars(currentPath);
    }

    emitDate(date: Date) {
        this.date.next(date);
    }

    watchDate() {
        return this.date.asObservable();
    }

    emitLocations(locationsData, appliedFilters = [], selectedDate = this.selectedDate) {
        // Update Selected Date.
        this.selectedDate = selectedDate;
        this.formattedDate = this.getYMD(selectedDate);
        this.fullDateString = this.getFullDateString(selectedDate);

        // Update Date range.
        this.setDateRange(this.selectedDate);
        // Get Location filters.
        const filters = appliedFilters.map(filter => filter.id);
        // Reduce Locations based on the applied filters.
        const locations = locationsData.reduce((result, location) => {
            if (!filters.includes(location.urlFriendlyId)) {
                return result;
            }
            result.push(location);

            return result;
        }, []);

        // Sort the Park Locations based on the order they were clicked.
        locations.sort((location1, location2) => {
            const index1 = filters.indexOf(location1.urlFriendlyId);
            const index2 = filters.indexOf(location2.urlFriendlyId);

            return index1 - index2;
        });

        this.filtersHash.updateHash(appliedFilters);

        this.locations.next(locations);
    }

    watchLocations() {
        return this.locations.asObservable();
    }

    setLoadingState(state) {
        this.loading.next(state);
    }

    getLoadingState() {
        return this.loading.asObservable();
    }

    allowLinksOnPage() {
        return this.showLinks;
    }

    setDateRange(selectedDate: Date) {
        const dates = [];
        const formattedDates: FormattedDate[] = [];
        const currentDate = new Date(selectedDate.getTime());

        const fiveDayDateCalColFormat = DATE_FORMAT_CONSTANTS[`${this.locale.toLowerCase().replace(/[^a-z]+/, '')}FiveDayCalColFormat`] ||
            'dddd, D';

        // Build 5-day range.
        for (let index = 0; index < 5; index++) {
            const fullDate = this.getDateString(currentDate, this.locale, 'dddd, D, MMMM');
            const day = this.getDateString(currentDate, this.locale, fiveDayDateCalColFormat);
            dates.push(this.getYMD(currentDate));
            formattedDates.push({day, fullDate});
            currentDate.setDate(currentDate.getDate() + 1);
        }

        this.dateRange = {
            dates,
            formattedDates
        };

        return this.dateRange;
    }

    watchViewAll() {
        return this.viewAll.asObservable();
    }

    emitViewAll(events) {
        this.viewAll.next(events);
    }

    getCurrentPath() {
        return this.winRef.nativeWindow.location.pathname;
    }

    getCalendarsDefaultPath() {
        // Default url for calendar page starts with /calendars. Then, based on the flow,
        // we decide to change it or not.
        // If we are on trade flow, it should start with /reservations/{store}/calendars
        // If we are on park-calendars path (special calendars page without any links),
        // the default url should be /park-calendars
        let calendarDefaultPath = `/${CALENDAR_CONSTANTS.calendarsDefaultPath}`;

        if (this.travelAgentService.isValidTradeFlow()) {
            const tradeStore = this.travelAgentService.getTravelAgentStore();
            calendarDefaultPath =
                `/${TRAVEL_AGENT.prefix}/${tradeStore}${calendarDefaultPath}`;
        } else if (this.isParkCalendars(this.getCurrentPath())) {
            calendarDefaultPath = `/${CALENDAR_CONSTANTS.parkCalendarsPath}`;
        };

        return calendarDefaultPath;
    }
}
