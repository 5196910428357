import { Injectable } from '@angular/core';
import { Animation } from '@finder/shared/components/animations/animation/animation.interface';
import { getSlideUpAnimation } from '@finder/shared/components/animations/animation-types/slideUp.animation';
import { getSlideLRAnimation } from '@finder/shared/components/animations/animation-types/slideLR.animation';
import { getPopUpSprite } from '@finder/shared/components/animations/animation-types/popUpSprite.animation';
import { getPopInRight } from '@finder/shared/components/animations/animation-types/popInRight.animation';
import { getPopInLeft } from '@finder/shared/components/animations/animation-types/popInLeft.animation';
import { getPopInBottom } from '@finder/shared/components/animations/animation-types/popInBottom.animation';
import { getSimpleSprite } from '@finder/shared/components/animations/animation-types/simpleSprite.animation';

@Injectable()
export class AnimationService {

    ongoingAnimation = false;

    private ongoingAnimationPromise;
    private animations = {
        'slide-up': getSlideUpAnimation,
        'slide-block-lr': getSlideLRAnimation,
        'show-middle-lr': getSlideUpAnimation,
        'pop-up-sprite': getPopUpSprite,
        'pop-in-right': getPopInRight,
        'pop-in-left': getPopInLeft,
        'pop-in-bottom': getPopInBottom,
        'simple-sprite': getSimpleSprite,
    };

    constructor() { }

    /**
     * @name requestSlot
     * @description Allows an animation to be triggered only if there isn't one ongoing already.
     * @param promise A promise to be resolved by the animation directive once animation finishes.
     * @returns Returns true if request succeeded, otherwise false.
     */
    requestSlot(promise: Promise<any>) {
        if (this.ongoingAnimation) {
            return false;
        }
        this.ongoingAnimation = true;
        this.ongoingAnimationPromise = promise;
        this.ongoingAnimationPromise
            .then(() => this.ongoingAnimation = false);

        return true;
    }

    getOngoingAnimationPromise(): Promise<any> {
        return this.ongoingAnimationPromise;
    }

    getAnimation(data: Animation, isMobile: boolean = false, parentHeight: number = 0) {
        return this.animations[data.transition] &&
            this.animations[data.transition](data, isMobile, parentHeight);
    }
}
