import { Injectable, InjectionToken } from '@angular/core';
import get from 'lodash-es/get';
import { map } from 'rxjs/operators';
import { SessionStorage } from '@finder/shared/services/session-storage/session-storage.service';
import { HttpClient } from '@angular/common/http';
import { CONFIG_CONSTANTS } from '@finder/shared/constants/app.constants';
import getApiBase from '@finder/shared/utils/string/getApiBase';

export const STAGE_CDN = 'https://stage.cdn1.parksmedia.wdprapps.disney.com';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    config: Record<string, any>;
    configsPath = 'config';
    constructor(
        private sessionStorage: SessionStorage,
        private http: HttpClient,
    ) {}

    load() {
        return new Promise((resolve, reject) => {
            try {
                const angularConfigValue = this.sessionStorage.get('finderAngularConfig');
                const finderAngularConfig = JSON.parse(angularConfigValue);
                this.config = finderAngularConfig;
                resolve(finderAngularConfig);
            } catch (err) {
                reject(err);
            }
        });
    }

    getValue(key: string): any {
        // Get value of config key:
        return get(this.config, key);
    }

    getSiteId() {
        return get(this.config, CONFIG_CONSTANTS.siteIdKey);
    }

    getCdnUrl() {
        // Check the env values first
        let [ cdnDomain ] = this.getValue(CONFIG_CONSTANTS.cdnListKey) as string[];
        // It will be blank if we're in local dev. Revert to fallback
        if (!cdnDomain) {
            cdnDomain = STAGE_CDN;
        }

        return cdnDomain;
    }

    getApiConfigs(): any {
        const baseUrl = getApiBase(
            this.getValue(CONFIG_CONSTANTS.apiUrlKey),
            this.getValue(CONFIG_CONSTANTS.defaultVersionKey)
        );
        const configsUrl = `${baseUrl}/${this.configsPath}`;

        return this.http.get(configsUrl).pipe(map((data)=> ({data, configsUrl})));
    }

    isDevelopment(): boolean {
        const environment = this.getValue('environment');

        return environment === CONFIG_CONSTANTS.developmentEnv;
    }
}
