import { Component } from '@angular/core';
import { ConfigService } from '@finder/core/config.service';

@Component({
    selector: 'finder-font-icon-loader',
    templateUrl: './font-icon-loader.component.html',
    styleUrls: ['./font-icon-loader.component.scss']
})
export class FontIconLoaderComponent {
    fontFaceScriptUrl: string;
    fontFaceCssUrl: string;

    constructor(private configs: ConfigService) {
        const fontFaceConfig = this.configs.getValue('fontFace');
        if (fontFaceConfig) {
            this.fontFaceScriptUrl = fontFaceConfig.jsUrl;
            this.fontFaceCssUrl = fontFaceConfig.cssUrl;
        }
    }

}
