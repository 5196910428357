import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CssLoaderComponent } from './css-loader.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        CssLoaderComponent,
    ],
    providers: [],
    exports: [
        CssLoaderComponent,
    ]
})
export class CssLoaderModule {}
