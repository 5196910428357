import { Injectable, Injector } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpEvent,
    HttpRequest,
    HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'environments/environment';

@Injectable()
export class SiteIdInterceptor implements HttpInterceptor {

    constructor(
        private injector: Injector
    ) {}

    /**
     * Add the site ID to every request
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // We don't need this for a prod environment, just local dev
        if (environment.production) {
            return next.handle(req);
        }

        // Get the cookie service
        const cookieService = this.injector.get(CookieService);
        if (!cookieService) {
            return next.handle(req);
        }

        // Check if we have the site ID cookie
        const siteIdCookie = cookieService.get('HTTP_X_DISNEY_INTERNAL_SITE');
        if (!siteIdCookie) {
            return next.handle(req);
        }

        // Set the header with the value from the site ID cookie
        req = req.clone({
            setHeaders: {
                'X-Disney-Internal-Site': siteIdCookie,
            }
        });

        return next.handle(req);
    }
}
