import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { FINDER_TOGGLES_COOKIE_NAME } from '@finder/shared/constants/app.constants';
import { safeJSONParse } from '@finder/shared/utils/object/safeJSONParse';

@Injectable({
    providedIn: 'root'
})
export class DebugCheckService {

    constructor(
        private cookieService: CookieService,
    ) {}

    isDebugEnabled() {
        const cookieValue = this.parseCookie();

        return cookieValue.debug === 'true' || cookieValue.debug === true;
    }

    isDebugModalEnabled() {
        const cookieValue = this.parseCookie();

        return cookieValue.debugModal === 'true' || cookieValue.debugModal === true;
    }

    private parseCookie() {
        const cookie = this.cookieService.get(FINDER_TOGGLES_COOKIE_NAME);

        return safeJSONParse(cookie);
    }
}
