import { Component } from '@angular/core';

@Component({
    selector: 'finder-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent {

    constructor() { }

}
