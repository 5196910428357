import { OneIdInstance } from './one-id.interface';

/* istanbul ignore next */
export const oneIdPlaceholder = (): OneIdInstance => ({
    on: (event, listener) => {},
    off: (event, listener) => {},
    getGuest: () => Promise.resolve(null),
    getGuestNewsletters: () => Promise.resolve(null),
    getInlineNewsletters: (campaignId, options) => Promise.resolve(null),
    launchNewsletters: (campaignId, optionalConfig) => Promise.resolve(),
    setInlineNewsletters: (data, options) => Promise.resolve(),
    launchLogin: () => Promise.resolve(null),
    getLoggedInStatus: () => Promise.resolve(null),
    getTrustStateStatus: () => Promise.resolve(null),
});

export const LOGIN = 'login';
export const CLOSE = 'close';
export const INIT = 'init';
export const ERROR = 'error';
