import {
    ApplicationRef,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    Inject,
    Injectable,
    Type,
    ViewContainerRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import omit from 'lodash-es/omit';
import has from 'lodash-es/has';
import get from 'lodash-es/get';

import { ModalComponent } from '@finder/shared/components/modal/modal.component';
import { ModalConfig } from '@finder/shared/components/modal/modal-config.interface';
import { MODAL_STATE } from '@finder/shared/components/modal/modal.constants';
import { WindowRef } from '@finder/shared/services/window-ref/window-ref.service';
import { RouteStateService } from '@finder/shared/services/route-state-service/route-state-service';
import { ModalChildComponent } from './modal-child-component.interface';

/**
 * This service takes care of the process to instantiate modals inside the application.
 * Use this service always as it takes care of accessibility.
 *
 * @class ModalService
 */
// @TODO Once updated to Angular6 or more, use @Injectable({providedIn: ComponentsModule}) to inject the proper scope.
@Injectable()
export class ModalService {
    modals: ComponentRef<ModalComponent>[];

    // Set currentId to -1 by default. The first created modal will have the zero id assigned.
    private currentId = -1;
    private defaultConfig: any = {
        data: {},
        hideOnClose: true,
        dismissOnBackgroundClick: true,
    };

    constructor(
        private routeState: RouteStateService,
        private windowRef: WindowRef,
        @Inject(ApplicationRef) private app,
        @Inject(DOCUMENT) private document: Document,
        @Inject(ComponentFactoryResolver) private componentFactoryResolver,
    ) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.modals = [];
    }

    /**
     * Factory to instantiate modals.
     *
     * @param component - The components to be injected as child inside the modal.
     * @param config - The config for the modal.
     * @returns
     */
    create<T>(component: Type<T> | ComponentFactory<any>, config: ModalConfig): ModalComponent {
        // Instantiate the modal component dynamically an get its reference.
        const viewRefInjector = this.getRootViewContainerRef().injector;
        const modalComponentRef = this.componentFactoryResolver
            .resolveComponentFactory(ModalComponent)
            .create(viewRefInjector);

        // [GIT-4535] Add unique ID to the modal
        if (has(config, 'id')) {
            modalComponentRef.location.nativeElement.setAttribute('id', config.id);
        }

        // Store the reference to the component.
        // @TODO Check closeALlModals do not fail after removing a modal CompRef.
        this.modals.push(modalComponentRef);

        // Get the modal instance and then return it to support method chaining.
        const modalComponentInstance = modalComponentRef.instance;

        // Override values not set.
        config = {...this.defaultConfig, ...config};

        // Setup Modal Component properties.
        modalComponentInstance.id = ++this.currentId;
        modalComponentInstance.childComponent = component;
        modalComponentInstance.setConfig(config);
        // @TODO Modal unique ID goes here

        // Add modal to the root ViewContainerReference (aka. body tag)
        this.getRootViewContainerRef().insert(modalComponentRef.hostView);

        // Removing the modal component from the DOM after closing the modal to prevent memory leaks.
        modalComponentInstance.afterClose().subscribe(() => {
            // Remove the modal component from the DOM to prevent memory leaks.
            modalComponentRef.destroy();
        });

        // Return the modal component instance.
        return modalComponentInstance;
    }

    /**
     *  Extends the defaults configs to be used for all the modals
     */
    extendDefaultConfigs(config: any): void {
        config = config || {};
        this.defaultConfig = {...this.defaultConfig, ...config};
    }

    /**
     * Allows all modals to be closed by force.
     * Use this carefully as it could drive the application to an error state if they don't handle afterClose event.
     *
     * @returns
     */
    closeAllModals() {
        this.modals.forEach((modalInstance) => modalInstance.instance.close());

        return this;
    }

    /**
     * Allows all modals to be dismissed
     *
     * @returns
     */
    dismissAllModals() {
        this.modals.forEach((modalInstance) => modalInstance.instance.dismiss());

        return this;
    }

    /**
     * Remove a modal deeplink if exists
     *
     * @returns
     */
    removeModalDeepLink(): Observable<boolean> {
        return this.routeState.pathParam()
            .pipe(
                map((params) => {
                    const deepLink = get(params, 'modal');
                    if (!deepLink) {
                        return false;
                    }

                    const url = this.windowRef.nativeWindow.location.pathname.replace(deepLink, '').replace(/\/\//g, '/');
                    history.pushState({}, this.document.title, url);

                    this.routeState.updatePathParamState(omit(params, 'modal'));

                    return true;
                }),
                take(1)
            );
    }

    /**
     * check if there is any modal open.
     *
     * @returns
     */
    isModalOpen() {
        const openedState = MODAL_STATE.opened.toLowerCase();
        for (const modal of this.modals) {
            if (modal.instance.is(openedState)) {
                return true;
            }
        }

        return false;
    }
    /**
     * Gives access to the app-root DOM element.
     *
     * @returns
     */
    protected getRootViewContainerRef(): ViewContainerRef {
        return this.app.components[0].instance.viewContainerRef;
    }
}
