import { Injectable } from '@angular/core';
import { WindowRef } from '@finder/shared/services/window-ref/window-ref.service';

@Injectable({
    providedIn: 'root'
})
export class SessionStorage {
    private storage;

    constructor(private windowRef: WindowRef) {
        this.storage = windowRef.nativeWindow.sessionStorage;
    }

    /**
     * will add that key/value to the Storage object,
     * or update that key's value if it already exists.
     *
     * @param key the key
     * @param value the value
     * @returns
     */
    set(key: string, value: any): boolean {
        // setItem() may throw an exception if the storage is full. Particularly,
        // in Mobile Safari (since iOS 5) it always throws when the user enters private mode.
        // (Safari sets the quota to 0 bytes in private mode, unlike other browsers,
        // which allow storage in private mode using separate data containers.)
        try {
            this.storage.setItem(key, value);

            return true;
        } catch (e) {
            return false;
        }
    }

    /**
     * It will return the key's value, or null if the key does
     * not exist in the Storage object.
     *
     * @param key the key.
     * @returns the key's value.
     */
    get(key: string): any {
        return this.storage.getItem(key);
    }

    remove(key: string) {
        this.storage.removeItem(key);
    }
}
