/**
 * Normalize some values and properties that don't function
 * properly in IE11
 *
 * @param event The event object
 */
const normalizeEventPartial = (event) => {
    const newEvent = {
        path: event.path,
        key: event.key
    };

    // IE11 doesn't have the path property
    if (!event.path) {
        newEvent.path = event.composedPath();
    }

    // Clean up the event name. IE11 doesn't prefix Arrow
    if (event.keyCode >= 37 && event.keyCode <= 40 &&
        event.key.indexOf('Arrow') === -1) {
        newEvent.key = `Arrow${event.key}`;
    } else if (event.keyCode === 27) {
        // Change 'Esc' to 'Escape'
        newEvent.key = 'Escape';
    }

    return newEvent;
};

export default normalizeEventPartial;
