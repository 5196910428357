import { Injectable } from '@angular/core';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { HeaderOverride } from './header-overrides.constants';
import { isEmpty, cloneDeep } from 'lodash-es';

const HEADER_OVERRIDES = 'header-overrides';
const LOGGER_MESSAGE = 'Header Overrides Service:';

@Injectable()
export class HeaderOverridesService {
    private headers: Array<HeaderOverride> = [];
    private defaultValue = { headers: [] };

    constructor(private logger: LoggerService) { }

    /**
     * @name            getHeaders
     * @description     Retrieve all the header overrides
     * @returns         Array<HeaderOverride>
     */
    getHeaders(): Array<HeaderOverride> {
        let storageValue = localStorage.getItem(HEADER_OVERRIDES);

        // Set some default values
        if (!storageValue) {
            storageValue = JSON.stringify(this.defaultValue);
            localStorage.setItem(HEADER_OVERRIDES, storageValue);
        }

        // Pull the overrides out of storage
        try {
            const results = JSON.parse(storageValue);
            this.headers = results.headers;
        } catch (err) {
            this.logger.error(`${LOGGER_MESSAGE} could not parse header overrides.`);
        }

        return this.headers;
    }

    /**
     * @name            setHeaders
     * @description     Set new header overrides
     * @param newHeaders Array of HeaderOverride objects
     */
    setHeaders(newHeaders: Array<HeaderOverride>) {
        const storageValue = cloneDeep(this.defaultValue);

        // Ignore values with empty headers or values
        this.headers = newHeaders.filter(header => !isEmpty(header.header) && !isEmpty(header.value));

        // Update the storage object
        storageValue.headers = this.headers;

        // Store the values
        localStorage.setItem(HEADER_OVERRIDES, JSON.stringify(storageValue));
    }

}
