import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CONFIG_CONSTANTS, API_CONSTANTS } from '@finder/shared/constants/app.constants';
import { ConfigService } from '@finder/core/config.service';
import getApiBase from '@finder/shared/utils/string/getApiBase';

const apiUrlKey = CONFIG_CONSTANTS.apiUrlKey;
const siteIdKey = CONFIG_CONSTANTS.siteIdKey;
const defaultVersionKey = CONFIG_CONSTANTS.defaultVersionKey;
const listServiceEndpoint = API_CONSTANTS.listServiceEndpoint;

@Injectable()
export class ListServiceConnectorService {

    private baseUrl: string;
    private version: string;
    private siteId: string;

    constructor(
        private http: HttpClient,
        private config: ConfigService
    ) {
        this.baseUrl = this.config.getValue(apiUrlKey);
        this.version = this.config.getValue(defaultVersionKey);
        this.siteId = this.config.getValue(siteIdKey);
    }

    /**
     * Gets a facility
     *
     * @param urlFriendlyId string
     * @param version       version - endpoint version (optional)
     */
    public getId(urlFriendlyId: string, version?: string): Observable<any> {
        let url = this.constructUrl(listServiceEndpoint, this.baseUrl, this.siteId, version);
        url = `${url}/${urlFriendlyId}/`;

        return this.http.get(url);
    }

    /**
     * Gets all facilities
     *
     * @param version   version - endpoint version (optional)
     */
    public getAll(version?: string): Observable<any> {
        const url = this.constructUrl(listServiceEndpoint, this.baseUrl, this.siteId, version);

        return this.http.get(url);
    }

    /**
     * Constructs the options and endpoint URL
     *
     * @param endpoint  list-svc endpoint
     * @param baseUrl   base URL
     * @param version   api version -- defaulted to v1
     */
    private constructUrl(endpoint: string, baseUrl: string, siteId: string, version: string = this.version): string {
        const apiBase = getApiBase(baseUrl, version);

        return `${apiBase}/${endpoint}/${siteId}`;
    }
}
