
<div class="sticky-cta" *ngIf="stickyCtaData">
    <finder-horizontal-rule *ngIf="stickyCtaData.hrData?.topLine"
        [data]="stickyCtaData.hrData"
        [position]="'top'">
    </finder-horizontal-rule>
    <div class="sticky-cta-content" [ngClass]="buttonClass">
        <div class="sticky-cta-text" *ngIf="stickyCtaData?.text">
            <span [innerHtml]="stickyCtaData.text | safeHtml"></span>
        </div>
        <div class="sticky-cta-button" *ngIf="stickyCtaData?.button">
            <finder-button
                [href]="stickyCtaData.button?.link"
                [linkId]="stickyCtaData.button?.linkId"
                [target]="stickyCtaData.button?.buttonTarget"
                [theme]="stickyCtaData.button?.theme"
            >{{stickyCtaData.button?.label}}</finder-button>
        </div>
    </div>
    <finder-horizontal-rule *ngIf="stickyCtaData.hrData?.bottomLine"
        [data]="stickyCtaData.hrData"
        [position]="'bottom'">
    </finder-horizontal-rule>
</div>
