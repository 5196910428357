import { Component, Input } from '@angular/core';
import { ButtonModule } from '../button/button.module';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'finder-premier-access',
    standalone: true,
    imports: [ButtonModule, CommonModule],
    templateUrl: './premier-access.component.html',
    styleUrls: ['./premier-access.component.scss']
})
export class PremierAccessComponent {
    @Input() card: any;
    @Input() fullWidth = false;
}
