import {
    Component,
    ComponentRef,
    ElementRef,
    OnDestroy,
    OnInit,
    AfterViewInit,
    Renderer2,
    Type,
    ViewChild,
    ViewContainerRef,
    ComponentFactory,
    ComponentFactoryResolver,
} from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { Location } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { Observable, Subject } from 'rxjs';
// @see https://stackoverflow.com/questions/39514564/property-filter-does-not-exist-on-type-observableevent
import { filter, first, takeUntil } from 'rxjs/operators';
import get from 'lodash-es/get';

import { MODAL_ANIMATIONS } from './modal.animations';
import { MODAL_STATE, MODAL_SET_STATE, ANIMATION_STATE } from './modal.constants';
import { ModalChildComponent } from './modal-child-component.interface';
import { ModalConfig } from './modal-config.interface';
import { ModalOpenQueueService } from './modal-open-queue.service';
import { ViewportDetection } from '@finder/shared/services/viewport-detection/viewport-detection.service';
import { WindowRef } from '@finder/shared/services/window-ref/window-ref.service';
import { AuthenticationService } from '@finder/shared/services/authentication/authentication.service';
import { RouteStateService } from '@finder/shared/services/route-state-service/route-state-service';
import normalizeEventPartial from '@finder/shared/utils/object/normalizeEvent';
import { ConfigService } from '@finder/core/config.service';

const loggerBaseMessage = 'FINDER MODAL - ';

@Component({
    selector: 'finder-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    // @see https://github.com/angular/angular/issues/19921
    // We need to use spread operator to bypass importing issues with BrowserAnimationsModule in AppModule.
    animations: [...MODAL_ANIMATIONS],
})
export class ModalComponent implements OnInit, AfterViewInit, OnDestroy {
    // Reference to the template inside the modal component ngTemplate.
    @ViewChild('modalContent', { read: ViewContainerRef }) modalContent: ViewContainerRef;
    @ViewChild('modalOverlay', { read: ElementRef }) modalOverlay: ElementRef;
    @ViewChild('modalCloseBtn', { read: ElementRef }) modalCloseBtn: ElementRef;

    // This value is set in ModalService.create
    id: Readonly<number>;
    status: string;
    data: any;
    childComponent: Type<ModalChildComponent>;
    childComponentRef: ComponentRef<any>;
    config: ModalConfig;
    readyObserver: Observable<boolean>;
    hideHeader = false;
    MAX_TITLE_LENGTH = 26;

    private subject = new Subject<AnimationEvent>();
    private destroy$ = new Subject<void>();
    private lastFocusedElement;
    private modalInit = new Subject<boolean>();

    private _theme: string;
    set theme(name) {
        if (name) {
            this._theme = `${name}-theme`;
        }
    }
    get theme() {
        return this._theme;
    }

    get nativeElement(): HTMLElement {
        return this.elementRef.nativeElement;
    }

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private viewportDetection: ViewportDetection,
        private logger: LoggerService,
        private windowRef: WindowRef,
        private authenticationService: AuthenticationService,
        private routeState: RouteStateService,
        private router: Router,
        private location: Location,
        private modalOpenQueueService: ModalOpenQueueService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private configService: ConfigService,
    ) {
        this.readyObserver = new Observable((observer) => {
            if (this.status) {
                // If status prop is already set, then don't do anything.
                observer.next(true);
                observer.complete();
            } else {
                this.modalInit
                .pipe(
                    first(),
                    takeUntil(this.destroy$)
                ).subscribe(() => {
                    observer.next(true);
                    observer.complete();
                });
            }
        });
    }

    ngOnInit() {
        this.status = MODAL_STATE.unloaded;
        // Initialize default configuration for the modal.
        this.setConfig();
        this.theme = this.config.theme;
    }

    ngAfterViewInit(): void {
        // The status prop has been initialized so emit ready event. Now the modal is ready to be opened.
        this.modalInit.next(true);

        this.beforeOpen()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                // Apply styles before opening the modal for positioning purposes.
                this.applyBodyStyle();
                // Store the last focused element before opening the modal.
                this.lastFocusedElement = this.windowRef.nativeWindow.document.activeElement;
            });

        this.afterOpen()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                // Setup accessibility controls.
                this.setupAccessibility();
            });

        this.afterDismiss()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                // Focus the element that was focused prior to modal dismissing.
                this.lastFocusedElement.focus();
                // Remove the styles that kills the scrolling behind the modal.
                this.removeBodyStyle();
            });

        // Listen for the back button and close the modal
        this.router.events.pipe(
            // Listen for the back event when the modal is open
            filter((event: NavigationStart) => event.navigationTrigger === 'popstate' && this.is(MODAL_STATE.opened.toLocaleLowerCase())),
            first(),
            takeUntil(this.destroy$)
        ).subscribe(event => {
            this.hide();
        });
    }

    ngOnDestroy() {
        // Complete all subscriptions tied to the modal instance.
        // NOTE: Modal Service takes care of calling modalComponent.destroy() on afterClose() observer.
        this.destroy$.next();
        this.destroy$.complete();
        this.subject.complete();
    }

    get hideDesktopHeader() {
        const isMobile = this.viewportDetection.isMobile();

        return this.hideHeader && !isMobile;
    }

    /**
     * Returns a reference to the child component that governs the modal.
     * Gives access to the child directly from the parent component that creates the modal.
     *
     * @TODO Use generics to improve intellisense.
     *
     * @returns
     */
    getChildComponentRef() {
        return this.childComponentRef;
    }

    /**
     * Sets the config for the modal.
     *
     * @see ModalConfig interface for more information.
     * @param config
     */
    setConfig(config?: ModalConfig) {
        this.config = { ...this.config, ...config };
    }

    /**
     * Checks if the modal is in a given state.
     *
     * @param currentState - One of three possible values: opened|closed|hidden.
     * @returns
     */
    is(currentState: string): boolean {
        return this.status && this.status.toLowerCase().includes(currentState);
    }

    /**
     * Opens the modal.
     *
     * @returns
     */
    open() {
        if (!this.is('unloaded') && !this.is('hidden')) {
            this.logger
                .error(`${loggerBaseMessage} cannot open modal from an invalid state. Current state: ${this.status}.`);

            return this;
        }
        this.addDeepLink();
        if (this.is('unloaded')) {
            let childComponentFactory;
            // if the child component modal is lazy loaded in a different bundle
            // it will come as a ComponentFactory already (for JIT)
            if (this.childComponent instanceof ComponentFactory) {
                childComponentFactory = this.childComponent;
            } else {
                childComponentFactory = this.componentFactoryResolver
                    .resolveComponentFactory(this.childComponent);
            }
            // Clear any content inside the ngTemplate.
            // @TODO Remove this if not needed.
            this.modalContent.clear();
            this.childComponentRef = this.modalContent.createComponent(childComponentFactory);
            // Inject Modal inside the child component so that the child can attach modal's behaviour
            // like open/close/hide to UI elements.
            this.childComponentRef.instance.parentComponent = this;
        }
        // Inject the data object to feed the child component from the outside.
        this.childComponentRef.instance.data = get(this.config, 'data');
        // let the queue service to handle the open state
        this.modalOpenQueueService.add(this);

        return this;
    }

    applyOpenState() {
        this.setState(MODAL_SET_STATE.opened);
    }

    /**
     * openWithLogin will validate if the user is logged in
     * before opening the modal and if it's not it will redirect to login page.
     *
     * @returns an Observable.
     */
    openWithLogin(): Observable<any> {
        this.addDeepLink();
        const data = this.getDeepLinkData();
        let cancelUrl = '';
        if (data.deepLinkId) {
            // the following regExp will catch the deepLink with the trailing slash if exist
            const regExp = new RegExp(`${data.deepLinkId}\/?`, 'g');
            cancelUrl = data.url.replace(regExp, '');
        }

        const validate = this.configService.getValue('modalValidateLogin');

        return new Observable((observer) => {
            this.authenticationService.validateLogin(validate, { cancelUrl })
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: swid => {
                        if (swid) {
                            this.open();
                            observer.next(swid);
                        } else {
                            observer.next(false);
                        }
                        observer.complete();
                    },
                    error: err => {
                        observer.next(false);
                        observer.complete();

                        // Close the modal
                        this.dismiss();
                    }
                });
        });
    }

    /**
     * Closes the modal.
     *
     * @returns
     */
    close() {
        this.setState(MODAL_SET_STATE.closed);
        this.removeDeepLink();

        this.afterClose()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                // Remove the child component from the DOM to prevent memory leaks.
                this.childComponentRef.destroy();
            });

        return this;
    }

    /**
     * Hides the modal.
     *
     * @returns
     */
    hide() {
        if (this.is('opened')) {
            const subscription = this.afterHide().subscribe(() => {
                // Unsubscribe after removing the style to avoid subscribing many times.
                subscription.unsubscribe();
            });
            this.setState(MODAL_SET_STATE.hidden);
            this.removeDeepLink();
        } else {
            this.logger
                .error(`${loggerBaseMessage} cannot hide modal from an invalid state. Current state: ${this.status}.`);
        }

        return this;
    }

    /**
     * Dismisses the modal by either closing or hiding it. The action depends on the hideOnClose flag.
     *
     * @returns
     */
    dismiss() {
        // @TODO fix the dismiss on background click.
        if (this.config?.hideOnClose) {
            this.hide();
        } else {
            this.close();
        }
        this.removeDeepLink();

        return this;
    }

    /**
     * Returns an observable that can be subscribed to after the modal service has created the component.
     * Use this observable to listen for the modal component to be ready for use on screen
     *
     * @returns Observable<boolean>
     */
    ready(): Observable<boolean> {
        return this.readyObserver;
    }

    /**
     * Returns an observable that can be subscribed to after the closeModal animation finishes.
     * Use this observable to remove any handler attached to UI elements that can fire/open the modal.
     * Subscribe to this observable always if possible, as the service can remove all modals by force if necessary.
     *
     * @returns
     */
    afterClose(): Observable<AnimationEvent> {
        return this.animationComplete(MODAL_STATE.closed, ANIMATION_STATE.done);
    }

    /**
     * Returns an observable that can be subscribed to after the hideModal animation finishes.
     *
     * @returns
     */
    afterHide(): Observable<AnimationEvent> {
        return this.animationComplete(MODAL_STATE.hidden, ANIMATION_STATE.done);
    }

    /**
     * Returns an observable that can be subscribed to after the hideModal or closeModal animations finish.
     *
     * @returns
     */
    afterDismiss(): Observable<AnimationEvent> {
        return this.animationTransition(ANIMATION_STATE.done);
    }

    /**
     * Returns an observable that can be subscribed to after the openModal animation finishes.
     *
     * @returns
     */
    afterOpen(): Observable<AnimationEvent> {
        return this.animationComplete(MODAL_STATE.opened, ANIMATION_STATE.done);
    }

    /**
     * Returns an observable that can be subscribed to before closeModal animation triggers.
     *
     * @returns
     */
    beforeClose(): Observable<AnimationEvent> {
        return this.animationComplete(MODAL_STATE.closed, ANIMATION_STATE.start);
    }

    /**
     * Returns an observable that can be subscribed to before hideModal animation triggers.
     *
     * @returns
     */
    beforeHide(): Observable<AnimationEvent> {
        return this.animationComplete(MODAL_STATE.hidden, ANIMATION_STATE.start);
    }

    /**
     * Returns an observable that can be subscribed to before the hideModal or closeModal animations triggers.
     *
     * @returns
     */
    beforeDismiss(): Observable<AnimationEvent> {
        return this.animationTransition(ANIMATION_STATE.start);
    }

    /**
     * Returns an observable that can be subscribed to before openModal animation triggers.
     *
     * @returns
     */
    beforeOpen(): Observable<AnimationEvent> {
        return this.animationComplete(MODAL_STATE.opened, ANIMATION_STATE.start);
    }

    /**
     * The callback executed after an animation has finished.
     *
     * @param event
     */
    animationDone(event: AnimationEvent): void {
        // Notify observers that the animation finished
        this.subject.next(event);
        // If the modal is closed, then notify subscribers to stop listening this observer.
        if (event.toState.includes(MODAL_STATE.closed)) {
            this.subject.complete();
        }
    }

    /**
     * The callback executed before firing an animation.
     *
     * @param event
     */
    animationStarted(event: AnimationEvent): void {
        // Notify observers that the animation started
        this.subject.next(event);
    }

    /**
     * Applies css styles to the body element for modal positioning. Kills background scrolling.
     */
    applyBodyStyle(): void {
        const winRef = this.windowRef.nativeWindow;
        const scrollbarWidth = winRef.innerWidth - winRef.document.body.clientWidth;
        const marginWidth = parseInt(winRef.getComputedStyle(winRef.document.body).marginRight, 10);

        this.renderer.setStyle(winRef.document.body, 'overflow', 'hidden');
        this.renderer.setStyle(winRef.document.body, 'margin-right', `${marginWidth + scrollbarWidth}px`);
    }

    /**
     * Remove body styles that kill the scrolling behind the modal and reset the scroll position
     * of the modal
     */
    removeBodyStyle(): void {
        this.modalOverlay.nativeElement.scrollTo(0, 0);
        const winRef = this.windowRef.nativeWindow;
        this.renderer.removeStyle(winRef.document.body, 'overflow');
        this.renderer.removeStyle(winRef.document.body, 'margin-right');
    }

    /**
     * Setup event handlers to handle accessibility.
     */
    setupAccessibility() {
        const windowRef = this.windowRef.nativeWindow;
        const documentRef = windowRef.document;

        const focusFirstDescendant = (element) => {
            const attemptFocus = (el) => {
                try {
                    el.focus();
                } catch (e) { }

                return (this.windowRef.nativeWindow.document.activeElement === el);
            };

            const focusFirstElement = (el) => {
                const childNodes = el.childNodes;
                for (const child of childNodes) {
                    if (attemptFocus(child) || focusFirstElement(child)) {
                        return true;
                    }
                }

                return false;
            };

            return focusFirstElement(element);
        };

        const focusHandler = (evt) => {
            const childOf = (c, p) => {
                while ((c = c.parentNode) && c !== p) {
                    continue;
                }

                return !!c;
            };

            const parent = this.modalOverlay.nativeElement;
            const child = evt.target;

            // If the focused element is the accessibility marker, means that that guest
            // is trying to focus outside the modal. Reset focus to the first available element.
            if (!childOf(child, parent)) {
                focusFirstDescendant(parent);
            }
        };

        const escapeHandler = (evt) => {
            // IE11 normalize event
            const partialEvt = normalizeEventPartial(evt);

            if (partialEvt.key === 'Escape' && this.is('opened')) {
                this.dismiss();
            }
        };

        const disableScrolling = () => {
            const window = this.windowRef.nativeWindow;
            const x = window.scrollX;
            const y = window.scrollY;
            windowRef.onscroll = () => {
                windowRef.scrollTo(x, y);
            };
        };

        const enableScrolling = () => {
            windowRef.onscroll = () => { };
        };

        focusFirstDescendant(this.modalOverlay.nativeElement);

        // Attach listeners outside Angular Zone to avoid slow down our application
        // and result in a very bad user experience.
        documentRef.addEventListener('focusin', focusHandler);
        documentRef.addEventListener('keyup', escapeHandler);

        // Prevent modal's background scroll when focusing elements using the keyboard.
        // Scroll event can't be disabled.
        // @TODO Improve this technique using CSS if possible.
        disableScrolling();

        // Remove event listeners.
        this.beforeDismiss()
            .pipe(
                first(),
                takeUntil(this.destroy$)
            ).subscribe({
                next: () => {
                    enableScrolling();
                    documentRef.removeEventListener('focusin', focusHandler);
                    documentRef.removeEventListener('keyup', escapeHandler);
                },
                // Adding error callback to bypass EmptyError triggered by the first() filter in Observables that
                // completes before any next notification was sent.
                error: () => {}
            });
    }

    /**
     * Click handler on background click.
     * Dismisses the modal if either the modal wrapper or the overlay is clicked.
     *
     * @param evt Click event.
     */
    backgroundDismiss(evt) {
        // This is the button
        const overlay = this.modalOverlay.nativeElement;
        // This is the background
        const wrapper = overlay.querySelector('.modal-content-wrapper');

        if (this.config.dismissOnBackgroundClick
            && (evt.target === overlay || evt.target === wrapper)
        ) {
            this.dismiss();
        }
    }

    /**
     * Add deep link in the url.
     */
    addDeepLink() {
        const data = this.getDeepLinkData();

        if (!data.deepLinkId || data.url.includes(data.deepLinkId)) {
            return;
        }

        const url = Location.joinWithSlash(data.url, data.deepLinkId);

        this.location.replaceState(url, data.queryParams);
    }

    /**
     * Remove deep link from the url.
     */
    removeDeepLink() {
        const data = this.getDeepLinkData();

        if (data.deepLinkId) {
            const url = data.url.replace(`/${data.deepLinkId}`, '');
            this.location.replaceState(url, data.queryParams);
        }
    }

    /**
     * Get Deep Link data.
     *
     * @param value
     */
    getDeepLinkData() {
        const deepLinkId = get(this.config, 'deepLinkId');

        // Normalized URL path without hashes.
        const path = this.location.path().split('?');

        // Extract the url and the query params if there is any.
        const url = path[0];
        const queryParams = path[1] || '';

        return {
            url,
            deepLinkId,
            queryParams
        };
    }

    /**
     * Checks if the modal has a deep link currently active.
     *
     * @returns
     */
    deepLink(): Observable<any> {
        return new Observable(observer => {
            const deepLinkId = get(this.config, 'deepLinkId');
            if (deepLinkId) {
                this.routeState.pathParam()
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(params => {
                        const modal = get(params, 'modal');
                        observer.next(modal === deepLinkId);
                        observer.complete();
                    });
            } else {
                observer.next(false);
                observer.complete();
            }
        });
    }

    /**
     * Changes the modal to the proper state.
     *
     * @param newState - One of three possible values: opened|closed|hidden.
     */
    protected setState(newState: MODAL_SET_STATE): void {
        /**
         * This was changed to an enum to avoid creating unreachable code
         */
        const isMobile = this.viewportDetection.isMobile();
        switch (newState) {
            case MODAL_SET_STATE.opened:
                this.status = isMobile ? MODAL_STATE.mobileOpened : MODAL_STATE.opened;
                break;
            case MODAL_SET_STATE.hidden:
                this.status = isMobile ? MODAL_STATE.mobileHidden : MODAL_STATE.hidden;
                break;
            case MODAL_SET_STATE.closed:
                this.status = isMobile ? MODAL_STATE.mobileClosed : MODAL_STATE.closed;
                break;
        }
    }

    /**
     * Returns an observable that can be subscribed to after the animation state finishes.
     * Use this observable to remove any handler attached to UI elements that can fire/open the modal.
     * Subscribe to this observable always if possible, as the service can remove all modals by force if necessary.
     *
     * @returns
     */
    private animationComplete(state: string, phaseName: string): Observable<AnimationEvent> {
        return this.subject.asObservable()
            .pipe(
                filter((event: AnimationEvent) => event.toState.includes(state) && event.phaseName === phaseName)
            );
    }

    /**
     * Returns an observable that can be subscribed to after the hideModal or closeModal animations finish.
     *
     * @returns
     */
    private animationTransition(phaseName: string): Observable<AnimationEvent> {
        return this.subject.asObservable()
            .pipe(
                filter((event: AnimationEvent) =>
                    (event.toState.includes(MODAL_STATE.hidden) || event.toState.includes(MODAL_STATE.closed))
                        && event.phaseName === phaseName)
            );
    }

    /**
     * Handle tab event to unfocus and refocus with a delay to allow screen reader
     * repeat the announce when focus on the same element: GIT-45727
     *
     */
    setCloseFocus() {
        const closeButton = this.modalCloseBtn.nativeElement as HTMLElement;
        const focusableElements = this.modalOverlay.nativeElement.querySelectorAll('a, button:not(.btn-close):not(.btn-back)');
        const lastFocus = document.activeElement;

        /* istanbul ignore if */
        if (closeButton === lastFocus && !focusableElements.length) {
            setTimeout(() => closeButton.focus(), 10);
            setTimeout(() => closeButton.blur(), 25);
        }
    }
}
