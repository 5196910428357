import { Injectable } from '@angular/core';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { API_CONSTANTS, CONFIG_CONSTANTS, DESTINATION_ID_CONSTANTS } from '@finder/shared/constants/app.constants';
import { ConfigService } from '@finder/core/config.service';
import getApiBase from '@finder/shared/utils/string/getApiBase';

/**
 * Class that provides methods to retrieve calendar days for dining
 */
@Injectable()
export class DiningCalendarDaysService {

    baseUrl = this.config.getValue(CONFIG_CONSTANTS.apiUrlKey);
    version = this.config.getValue(CONFIG_CONSTANTS.defaultVersionKey);
    apiBase = getApiBase(this.baseUrl, this.version);
    url = `${this.apiBase}/${API_CONSTANTS.diningAvailabilityCalendarDaysApiEndpoint}`;

    constructor(
        private config: ConfigService,
        private http: HttpClient,
        private logger: LoggerService,
    ) { }

    getCalendarAvailDaysFromAvailApi(entityId: string, swid: string): Observable<any> {
        const destinationId = DESTINATION_ID_CONSTANTS[this.config.getValue('siteId')].split(';')[0];

        return this.http.get(`${this.url}/${destinationId}/${entityId}/${swid}/`)
            .pipe(
                catchError((error) => {
                    this.logger.error(
                        `CALENDAR AVAIL API ERROR. Reason: ${error.status}|${error.message}`
                    );

                    return throwError(() => new Error(error));
                })
            );
    }
}
