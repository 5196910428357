import { Component } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';

@Component({
    selector: 'finder-dine-to-finder-error-modal',
    templateUrl: './dine-to-finder-error-modal.component.html',
    styleUrls: ['./dine-to-finder-error-modal.component.scss']
})
export class DineToFinderErrorModalComponent {
    parentComponent: ModalComponent;
    data: any;

    constructor() { }

}
