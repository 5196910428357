import { Inject, Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CalendarsService } from '@finder/features/calendars/services/calendar.service';
import { FinderSeoService } from '@finder/shared/services/finder-seo/finder-seo.service';
import { FinderApiConnectorService } from '@finder/shared/services/finder-api-connector/finder-api-connector.service';
import { LoadingService } from '@finder/shared/services/loading-service/loading-service.service';
import { UserSelectionStorage } from '@finder/shared/services/user-selection-storage/user-selection-storage.service';
import { TravelAgentService } from '@finder/shared/services/travel-agent/travel-agent.service';

import { GetYMDProvider, GET_YMD } from '@finder/shared/utils/string/getYMD';
import { IsValidDateString, IS_VALID_DATE_STRING } from '../utils/date/isValidDateString';
import { IsCurrentOrFutureDate, IS_CURRENT_OR_FUTURE_DATE } from '../utils/date/isCurrentOrFutureDate';
import { GetDateObjectProvider, GET_DATE_OBJECT } from '../utils/date/getDateObject';

@Injectable()
export class CalendarResolver implements Resolve<any> {
    constructor(
        private calendarService: CalendarsService,
        private finderApi: FinderApiConnectorService,
        private finderSeoService: FinderSeoService,
        private loadingService: LoadingService,
        private userSelection: UserSelectionStorage,
        private travelAgentService: TravelAgentService,
        private router: Router,
        @Inject(GET_YMD) private getYMD: GetYMDProvider,
        @Inject(GET_DATE_OBJECT) private getDateObject: GetDateObjectProvider,
        @Inject(IS_VALID_DATE_STRING) private isValidDateString: IsValidDateString,
        @Inject(IS_CURRENT_OR_FUTURE_DATE) private isCurrentOrFutureDate: IsCurrentOrFutureDate,
    ) {}

    resolve(route: ActivatedRouteSnapshot) {
        this.loadingService.setErrorState(null);
        this.loadingService.setLoadingState(false);

        // If we try to go to a trade page but we don't have the session cookie, show an error.
        const tradeError = this.travelAgentService.showErrorIfCookieIsMissing();
        if (tradeError) {
            return;
        }

        // Day or five-day view.
        const viewType = route.firstChild.data.viewType;

        // Pull stored date from Session Storage,
        // if there is no date stored in storage it returns the current date.
        let dateSelected = this.userSelection.getCalendarDate();
        const isDateSelectedValid = this.isCurrentOrFutureDate(dateSelected);
        // if the last date selected is a past date, remove the storage and get the current date.
        if(!isDateSelectedValid) {
            this.userSelection.removeCalendarDate();
            dateSelected = new Date();
        }

        // Pull date param from the URL
        const urlDate = route.firstChild.params?.date;
        const isCurrentOrFutureDate = this.isCurrentOrFutureDate(this.getDateObject(urlDate));
        const isValidUrlDate = this.isValidDateString(urlDate) && isCurrentOrFutureDate;

        /* If the date from the URL is a past date or doesn't comply with the format YYYYY-MM-DD,
         * the page is redirected to the calendar view with the last selected date or the current date.
        */
        if(urlDate && !isValidUrlDate) {
            this.router.navigate([this.calendarService.getCalendarsDefaultPath(), viewType, this.getYMD(dateSelected)]);

            return;
        }

        const date = isValidUrlDate ? urlDate : this.getYMD(dateSelected);

        /**
         * Use a promise to resolve the route and never reject
         * the error so we can stay on the same URI
         */
        return new Promise((resolve, reject) => {
            this.finderApi.getCalendarData(viewType, date)
                .pipe(
                    catchError((error) => {
                        this.loadingService.setErrorState(error);
                        this.loadingService.setLoadingState(true);

                        /* If the date from the URL is a date greater than max days calendars,
                            the date storage by the user selection is removed
                            and the page is redirected to the calendar view w/o date in the URL
                        */
                        if (isValidUrlDate && error?.status === 403) {
                            this.userSelection.removeCalendarDate();
                            this.router.navigate([this.calendarService.getCalendarsDefaultPath(), viewType]);
                        }

                        // Is needed resolve the promise so the url redirection will be carried out
                        // when we want to show our error messaging page
                        resolve(null);

                        // This doesn't really do anything other an stop the error from a
                        // method that wants an Observable returned to it
                        return EMPTY;
                    })
                ).subscribe((result) => {
                    // Set SEO
                    this.finderSeoService.getPageKeyAndSetSeo();

                    // Disable loading spinner
                    this.loadingService.setLoadingState(true);

                    // Save the date from URL in the user selection
                    if (isValidUrlDate) {
                        this.userSelection.saveCalendarDate(urlDate);
                    }
                    resolve(result);
                });
        });
    }
}
