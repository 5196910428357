import moment from 'moment';

import { InjectionToken } from '@angular/core';

import { DATE_FORMAT_CONSTANTS } from '@finder/shared/constants/date.constants';

/* istanbul ignore next */
/**
 * @description Outputs the date provided (or today if no date provided) as a formatted string.
 * @param date  the date to convert
 */
export const getDateString = (
    date: Date = new Date(),
    locale?: string,
    formatOverride?: string
): string => {
    let format = DATE_FORMAT_CONSTANTS.scheduleDateFormat;

    if (locale) {
        // use locale-specific date formatting defined in date constants
        format = DATE_FORMAT_CONSTANTS[`${locale.toLowerCase().replace(/[^a-z]+/, '')}ShortDateFormat`] || format;
    }

    if (formatOverride) {
        // override to a specific date format for all locales (should be rare)
        format = formatOverride;
    }

    return moment(date).format(format);
};

export type GetDateStringProvider = (date: Date, locale?: string, formatOverride?: string) => string;
export const GET_DATE_STRING =
    new InjectionToken('GET_DATE_STRING', {
        factory: () => getDateString
    });
