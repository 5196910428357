import { InjectionToken } from '@angular/core';

/**
 * Remove css theme into the document head
 *
 * @param document the document
 * @param theme theme name
 */
export const removeCssThemes = (document: Document, theme: string): void => {
    // remove both the theme's CSS and any minified theme CSS used by Navigator app
    const links = document.head.querySelectorAll(
        'link[href*="-theme.css"], link[href*="-theme.min.css"]'
    );
    links.forEach((link: HTMLLinkElement) => {
        // If the theme file doesn't match the current theme remove it
        if (!link.href.includes(theme)) {
            document.head.removeChild(link);
        }
    });
};

export type RemoveCssThemesProvider = (document: Document, theme: string) => void;
export const REMOVE_CSS_THEMES =
    new InjectionToken('REMOVE_CSS_THEMES', {
        factory: () => removeCssThemes
    });
