export const MODAL_STATE = {
    unloaded: 'UNLOADED',
    opened: 'OPENED',
    closed: 'CLOSED',
    hidden: 'HIDDEN',
    mobileOpened: 'MOBILE_OPENED',
    mobileClosed: 'MOBILE_CLOSED',
    mobileHidden: 'MOBILE_HIDDEN',
};

export const ANIMATION_STATE = {
    start: 'start',
    done: 'done'
};

export enum MODAL_SET_STATE {
    opened,
    hidden,
    closed
}
