import { Component, OnInit, Input, Inject } from '@angular/core';
import { LazyLoaderService } from '@finder/shared/services/lazy-loader/lazy-loader-service';

@Component({
    selector: 'finder-script-loader',
    template: ''
})
export class ScriptLoaderComponent implements OnInit {
    @Input() src: string;

    constructor(
        private lazyLoader: LazyLoaderService,
    ) { }

    ngOnInit() {
        if (this.src) {
            this.lazyLoader.lazyLoadScriptResource(this.src, false);
        }
    }
}
