import { Component, Input } from '@angular/core';

const unknownError = 0;

@Component({
    selector: 'finder-system-error',
    templateUrl: './system-error.component.html',
    styleUrls: ['./system-error.component.scss']
})
export class SystemErrorComponent {

    @Input() status: number;
    @Input() bundle: any;
    unknown = unknownError;

    constructor() { }

}
