import { InjectionToken } from '@angular/core';
import { interval } from 'rxjs';
import { filter, finalize, map, take, timeout } from 'rxjs/operators';
import isUndefined from 'lodash-es/isUndefined';

import { WindowRef } from '@finder/shared/services/window-ref/window-ref.service';
import { DebugLogService } from '@finder/shared/services/debug-log/debug-log.service';
import { OneIdInstance } from '@finder/shared/services/one-id/one-id.interface';
import { OneIdService } from '@finder/shared/services/one-id/one-id.service';
import { timeout as rxjsTimeout } from '@finder/shared/utils/time/timeout';
import { ERROR, INIT, oneIdPlaceholder } from './one-id.constants';

const logName = 'OneID Factory';

// The injected values are defined in app.module
export const OneIdFactory = async (
    windowRef: WindowRef,
    debugLog: DebugLogService,
) => new Promise((resolve, reject) => {
    // Create a dummy
    let oid: OneIdInstance = oneIdPlaceholder();
    let intervalIndex = 0;

    const window = windowRef.nativeWindow;

    const resolveOneIdService = () => {
        const oneIdService = new OneIdService(oid, debugLog);
        const rxjsTimeout$ = rxjsTimeout(() => {
            resolveService(null);
        }, 2000);

        const resolveService = (obj) => {
            // Cancel the timer
            rxjsTimeout$.unsubscribe();

            resolve(oneIdService);

            oid.off(INIT, resolveService);
            oid.off(ERROR, resolveService);
        };

        debugLog.log(`***** ${logName} finalize global scope`, oid, oneIdService);
        /**
         * Wait for the service to init
         *
         * app.module calls this factory when the page is loading. So we're always using this factory. We
         * need to make sure this finishes or the page will never load if something really weird happens
         * with OneID.
         */
        oid.on(INIT, resolveService);
        oid.on(ERROR, resolveService);
    };

    // We want to get the global singleton from `window` but we don't know when it will be
    // available so keep trying. If it takes 2 seconds just quit and return the dummy instance.
    // TODO: change this to throw rather than return the dummy and handle it in the UI component
    interval(250) // Check every quarter-second
        .pipe(
            map(() => {
                debugLog.log(`***** ${logName} checking`, ++intervalIndex, window.OneID || window.DisneyID);

                /**
                 * https://mywiki.disney.com/pages/viewpage.action?spaceKey=gamprofile&title=Profile+Authenticator
                 *
                 * In the future we might be able to simplify this check to
                 * window.PEP.PROFILE_AUTH but hold off for now until
                 * we get a stable V4.
                 */
                if (window.OneID) { // Check for v4 first
                    debugLog.log(`***** ${logName}: v4 found`, oid);
                } else if (window.DisneyID) { // Check for v2
                    debugLog.log(`***** ${logName}: v2 found`, oid);
                }

                return { oid: window.OneID || window.DisneyID };
            }),
            filter(val => !isUndefined(val.oid)),
            take(1),
            // Timeout triggers the error case in the subscriber
            timeout(2000), // Timeout after 2 seconds
            finalize(() => resolveOneIdService()),
        )
        .subscribe({
            next: (result) => {
                oid = result.oid.get() as OneIdInstance;
            },
            error: () => {
                debugLog.log(`***** ${logName} - DisneyID not found on global namespace`);
            }
        });
});

export const ONE_ID_INSTANCE =
    new InjectionToken('ONE_ID_INSTANCE', {
        factory: (): Partial<OneIdInstance> => ({})
    });

