import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ConfigService } from '@finder/core/config.service';
import { API_CONSTANTS, CONFIG_CONSTANTS } from '@finder/shared/constants/app.constants';
import getApiBase from '@finder/shared/utils/string/getApiBase';
import { WindowRef } from '@finder/shared/services/window-ref/window-ref.service';

const apiUrlKey = CONFIG_CONSTANTS.apiUrlKey;
const defaultVersionKey = CONFIG_CONSTANTS.defaultVersionKey;
const soloBaseEndpoint = API_CONSTANTS.soloBaseEndpoint;

@Injectable({
    providedIn: 'root'
})
export class SoloService {

    constructor(
        private config: ConfigService,
        private http: HttpClient,
        private window: WindowRef,
    ) { }

    validateToken() {
        const url = this.getBaseUrl();

        return this.http.get<any>(`${url}/token`);
    }

    getLoginUrl() {
        const url = this.getBaseUrl();

        return `${url}/myid`;
    }

    getCallbackUrl() {
        const url = this.getBaseUrl();

        return `${url}/oidc`;
    }

    getLoginRedirectUrl(originalRequestUrl) {
        const loginUrl = this.getLoginUrl();
        const redirectUri = encodeURIComponent(originalRequestUrl);
        const callback = encodeURIComponent(this.getCallbackUrl());

        return `${loginUrl}/?redirectUri=${redirectUri}&myidCallbackUri=${callback}`;
    }

    private getBaseUrl() {
        // We need to create an absolute URL. For most environments we don't have
        // the domain as part of the web API base (we use the app for different domains)
        // so we need to prefix the URL with the current domain.
        const url = new URL(this.window.nativeWindow.location.href);
        let domain = '';

        // Local is really the only place where we already provide an absolute URL
        if (this.config.getValue(CONFIG_CONSTANTS.envKey) !== CONFIG_CONSTANTS.developmentEnv) {
            domain = `${url.protocol}//${url.hostname}`;
        }

        const baseUrl = domain + this.config.getValue(apiUrlKey);
        const version = this.config.getValue(defaultVersionKey);

        const apiBase = getApiBase(baseUrl, version);

        return `${apiBase}/${soloBaseEndpoint}`;
    }
}
