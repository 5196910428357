import { InjectionToken } from '@angular/core';

import { CALENDAR_CONSTANTS } from '@finder/shared/constants/app.constants';

/**
 * Checks the URL to know if we are on park calendars special page
 *
 * @param url string
 * @example
 *  /park-calendars/* => true
 *  /calendars/* => false
 *  @returns boolean - true if it's a park calendars url, false if not
 */
export const isParkCalendars = (path: any) => {
    if (!path) {
        return false;
    }

    return path.includes(CALENDAR_CONSTANTS.parkCalendarsPath);
};

export type IsParkCalendarsProvider = (url: string) => boolean;
export const IS_PARK_CALENDARS_URL =
    new InjectionToken('IS_PARK_CALENDARS_URL', {
        factory: () => isParkCalendars
    });
