import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LocaleDirective } from './locale.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        LocaleDirective
    ],
    providers: [],
    exports: [
        LocaleDirective
    ]
})
export class LocaleDirectiveModule {}
