// Pages that are not a list page but match the list route
export const ROUTE_EXCEPTIONS = [
    // This is an overview page that will be handled as a detail page in the future
    // WDW
    'destinations/animal-kingdom',
    'destinations/blizzard-beach',
    'destinations/boardwalk',
    'destinations/disney-springs',
    'destinations/epcot',
    'destinations/hollywood-studios',
    'destinations/magic-kingdom',
    'destinations/typhoon-lagoon',
    'destinations/wide-world-of-sports',

    // DLR
    'destinations/disney-california-adventure',
    'destinations/disneyland',
    'destinations/downtown-disney-district',
    'destinations/southern-california',

    // HKDL
    'destinations/theme-park',
    'destinations/inspiration-lake-recreation-centre',
    'destinations/disney-explorers-lodge'
];

export const ROUTE_TYPE = [
    'attractions',
    'destinations',
    'dining',
    'entertainment',
    'events',   // HKDL-specific
    'events-tours',
    'guest-services',
    'hotels',   // for lodging map support
    'maps',
    'news',     // for custom URLs
    'photography',
    'recreation',
    'resorts',  // for lodging map support
    'shops',
    'spas',
    'tours',    // HKDL-specific
    'transportation',
    'wishlist'
];

export const ROUTE_SECTION = [
    'map',
    'more',
    'good-neighbor'
];

export const ROUTE_CALENDAR_TYPE = [
    'calendars',
    'park-calendars'
];

export const ROUTE_PARKS = [
    'map',
    'all-star-movies-resort',
    'all-star-music-resort',
    'all-star-sports-resort',
    'animal-kingdom',
    'animal-kingdom-lodge',
    'animal-kingdom-villas-jambo',
    'animal-kingdom-villas-kidani',
    'art-of-animation-resort',
    'bay-lake-tower-at-contemporary',
    'beach-club-resort',
    'beach-club-villas',
    'blizzard-beach',
    'boardwalk',
    'boardwalk-inn',
    'boardwalk-villas',
    'boulder-ridge-villas-at-wilderness-lodge',
    'cabins-at-fort-wilderness-resort',
    'campsites-at-fort-wilderness-resort',
    'caribbean-beach-resort',
    'contemporary-resort',
    'copper-creek-villas-and-cabins',
    'coronado-springs-resort',
    'disney-california-adventure',
    'disney-springs',
    'disneyland',
    'disneyland-hotel',
    'disneys-boardwalk',
    'dolphin-hotel',
    'downtown-disney',
    'downtown-disney-district',
    'epcot',
    'four-seasons',
    'grand-californian-hotel',
    'grand-floridian-resort-and-spa',
    'hollywood-studios',
    'magic-kingdom',
    'old-key-west-resort',
    'pixar-place-hotel',
    'polynesian-resort',
    'polynesian-villas-bungalows',
    'pop-century-resort',
    'port-orleans-resort-french-quarter',
    'port-orleans-resort-riverside',
    'riviera-resort',
    'saratoga-springs-resort-and-spa',
    'swan-hotel',
    'typhoon-lagoon',
    'villas-at-grand-floridian',
    'villas-at-grand-floridian-resort-and-spa',
    'villas-at-wilderness-lodge',
    'wide-world-of-sports',
    'wilderness-lodge-resort',
    'yacht-club-resort',

    // HKDL entries
    'disney-explorers-lodge',
    'disneys-hollywood-hotel',
    'hong-kong-disneyland-hotel',
    'inspiration-lake-recreation-centre',
    'theme-park',
];
