<div id="headerWrapper"
    class="finder-syndicated-header-wrapper"
    [@headerState]="stateName"
    (@headerState.done)="headerStateDone($event)">
    <finder-script-loader [src]="syndicatedHeaderUrl"></finder-script-loader>
    <wdpr-wc-travel-agent-session-background-refresh
        *ngIf="enableBackgroundRefresh"
        [extendUrl]="extendTradeSessionUrl"
        [extendInterval]="extendTradeSessionInterval">
    </wdpr-wc-travel-agent-session-background-refresh>
</div>
