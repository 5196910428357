import { Injectable, Injector } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpEvent,
    HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { LocaleHelperService } from '@finder/shared/services/locale-service/locale-helper.service';
import { LOCALE_DEFAULT } from '@finder/shared/constants/app.constants';
import { TranslationHelperService } from './translation-helper.service';
import { environment } from 'environments/environment';

@Injectable()
export class TranslationInterceptor implements HttpInterceptor {

    constructor(private injector: Injector) {}

    /**
     * [PRO-346388]
     * This can be removed once WorldServer is ready
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const localeHelperService = this.injector.get(LocaleHelperService);
        const splitValue = 'finder/api';
        const locale = localeHelperService.getUriLocale() || LOCALE_DEFAULT;
        const isDefaultLocale = localeHelperService.isDefaultLocale(locale);
        const translationHelperServcice = this.injector.get(TranslationHelperService);
        const skipTranslation = translationHelperServcice.skipTranslation(req, splitValue, isDefaultLocale);

        // If we are in local dev don't bother
        if (environment.production === false) {
            return next.handle(req);
        }

        // Do not translate if any condition is met
        if (skipTranslation) {
            return next.handle(req);
        }

        // No locale detected. Return normal request
        if (!locale) {
            return next.handle(req);
        }

        req = this.createNewRequest(req, splitValue, locale);

        return next.handle(req);
    }

    private createNewRequest(req: HttpRequest<any>, splitValue: string, locale: string) {
        // Create the new uri
        const localeUrl = req.url.replace(splitValue, `${locale}/${splitValue}`);

        // Create the new request
        return req.clone({ url: localeUrl });
    }
}
