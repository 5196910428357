import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CONFIG_CONSTANTS, API_CONSTANTS } from '@finder/shared/constants/app.constants';
import { ConfigService } from '@finder/core/config.service';
import getApiBase from '@finder/shared/utils/string/getApiBase';

const apiUrlKey = CONFIG_CONSTANTS.apiUrlKey;
const siteIdKey = CONFIG_CONSTANTS.siteIdKey;
const defaultVersionKey = CONFIG_CONSTANTS.defaultVersionKey;
const resourceBundleEndpoint = API_CONSTANTS.resourceBundleEndpoint;

@Injectable()
export class LanguageBundleConnectorService {

    private baseUrl: string;
    private version: string;
    private siteId: string;

    constructor(
        private http: HttpClient,
        private config: ConfigService
    ) {
        this.baseUrl = this.config.getValue(apiUrlKey);
        this.version = this.config.getValue(defaultVersionKey);
        this.siteId = this.config.getValue(siteIdKey);
    }

    /**
     * Gets a bundle
     *
     * @param bundle string
     * @param version       version - endpoint version (optional)
     */
    public getBundle(version?: string): Observable<any> {
        const url = this.constructUrl(resourceBundleEndpoint, this.baseUrl, this.siteId, version) + '/';

        return this.http.get(url);
    }


    /**
     * Constructs the options and endpoint URL
     *
     * @param endpoint  list-svc endpoint
     * @param baseUrl   base URL
     * @param version   api version -- defaulted to v1
     */
    private constructUrl(endpoint: string, baseUrl: string, siteId: string, version: string = this.version): string {
        const apiBase = getApiBase(baseUrl, version);

        return `${apiBase}/${endpoint}/${siteId}`;
    }
}
