/**
 * A custom enum type used as a constant containing all the device types for type safety.
 *
 * @typedef DEVICE_TYPE
 */
export enum DEVICE_TYPE {
    mobile,
    tablet,
    desktop,
}
