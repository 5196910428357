import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DineToFinderErrorModalComponent } from './dine-to-finder-error-modal.component';

@NgModule({
    declarations: [
        DineToFinderErrorModalComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        DineToFinderErrorModalComponent
    ]
})
export class DineToFinderErrorModalModule { }
