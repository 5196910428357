import { Inject, Injectable } from '@angular/core';
import isEmpty from 'lodash-es/isEmpty';
import moment from 'moment';

import { SessionStorage } from '@finder/shared/services/session-storage/session-storage.service';
import { GetDateObjectProvider, GET_DATE_OBJECT } from '@finder/shared/utils/date/getDateObject';

import { USER_SELECTION_STORAGE } from '@finder/shared/services/user-selection-storage/user-selection-storage.constants';
import { CALENDAR_CONSTANTS } from '@finder/shared/constants/app.constants';
import { DATE_FORMAT_CONSTANTS } from '@finder/shared/constants/date.constants';

@Injectable({
    providedIn: 'root',
})
export class UserSelectionStorage {
    constructor(
        private storage: SessionStorage,
        @Inject(GET_DATE_OBJECT) private getDateObject: GetDateObjectProvider,
    ) {}

    /**
     * @name            saveDate
     * @description     Save the date the user selected into a storage
     *                  along with the date format.
     * @param           date the date the user selected.
     * @returns         a boolean indicating if the value was stored successfully.
     */
    saveDate(date: Date): boolean {
        const momentDate = moment(date);
        if (momentDate.isValid()) {
            const dateString = moment(date).format(USER_SELECTION_STORAGE.dateFormatString);

            return (
                this.saveToStorage(USER_SELECTION_STORAGE.keys.selectedDate, dateString) &&
                this.saveToStorage(USER_SELECTION_STORAGE.keys.dateFormat, USER_SELECTION_STORAGE.dateFormatString)
            );
        }

        return false;
    }

    /**
     * @name            getDate
     * @description     Get the date store in the storage
     *                  formatted with dateFormat in storage as well.
     * @returns         the date stored or undefined if there is nothing in storage.
     */
    getDate(): Date {
        let date;
        const dateString = this.storage.get(USER_SELECTION_STORAGE.keys.selectedDate);
        const dateFormat = this.storage.get(USER_SELECTION_STORAGE.keys.dateFormat);

        if (dateString && dateFormat) {
            const momentDate = moment(dateString, dateFormat);
            date = momentDate.toDate();
        }

        return date;
    }

    /**
     * @name            saveTime
     * @description     Save the time the user selected into a storage.
     * @param           time the time the user selected.
     * @returns         a boolean indicating if the value was stored successfully.
     */
    saveTime(time: string): boolean {
        const momentDate = moment(time, DATE_FORMAT_CONSTANTS.timeParseFormat);
        if (momentDate.isValid()) {
            const formattedTime = momentDate.format(USER_SELECTION_STORAGE.timeFormat);

            return this.saveToStorage(USER_SELECTION_STORAGE.keys.selectedTime, formattedTime);
        }

        return false;
    }

    /**
     * @name            getTime
     * @description     Get the time store in the storage
     *                  formatted with the original format.
     * @returns         the time stored or undefined if there is nothing in storage.
     */
    getTime() {
        let savedTime = this.storage.get(USER_SELECTION_STORAGE.keys.selectedTime);
        const momentTime = moment(savedTime, USER_SELECTION_STORAGE.timeFormat);
        if (momentTime.isValid()) {
            savedTime = momentTime.format(DATE_FORMAT_CONSTANTS.timeParseFormat);
        }

        return savedTime;
    }

    /**
     * @name        saveMealPeriod
     * @description Save the mealPeriodId the user selected into a storage.
     * @param       partySize the mealPeriodId the user selected.
     * @returns     a boolean indicating if the value was stored successfully.
     */
    saveMealPeriod(mealPeriodId: string) {
        return this.saveToStorage(USER_SELECTION_STORAGE.keys.selectedTime, mealPeriodId);
    }


    /**
     * @name        savePartySize
     * @description Save the partySize the user selected into a storage.
     * @param       partySize the time the user selected.
     * @returns     a boolean indicating if the value was stored successfully.
     */
    savePartySize(partySize: string) {
        return this.saveToStorage(USER_SELECTION_STORAGE.keys.selectedPartySize, partySize);
    }

    /**
     * @name        getPartySize
     * @description Get the party size from the storage.
     * @returns     the party sized stored or undefined if there is nothing in storage.
     */
    getPartySize(): string {
        return this.storage.get(USER_SELECTION_STORAGE.keys.selectedPartySize);
    }

    /**
     * @name        saveCalendarDate
     * @description Save the calendar date in session storage.
     * @param       simpleDate Selected Date.
     * @returns     a boolean indicating if the value was stored successfully.
     */
    saveCalendarDate(simpleDate: string) {
        return this.storage.set(
            CALENDAR_CONSTANTS.sessionStorage.selectedDate,
            simpleDate
        );
    }

    /**
     * @name        removeCalendarDate
     * @description Remove the calendar date in session storage.
     */
    removeCalendarDate() {
        this.storage.remove(
            CALENDAR_CONSTANTS.sessionStorage.selectedDate
        );
    }

    /**
     * @name        getCalendarDate
     * @description Get the calendar date from storage.
     * @returns     the stored date or the current date if there is nothing in the storage.
     */
    getCalendarDate() {
        const storedDate = this.storage.get(CALENDAR_CONSTANTS.sessionStorage.selectedDate);

        return (storedDate) ? this.getDateObject(storedDate) : new Date();
    }

    /**
     * @name        saveCalendarFilters
     * @description Save the calendar filters in session storage.
     * @params      filters Calendar Filters.
     * @returns     a boolean indicating if the value was stored successfully.
     */
    saveCalendarFilters(filters: string) {
        return this.storage.set(CALENDAR_CONSTANTS.sessionStorage.selectedFilters, filters);
    }

    /**
     * @name        getCalendarFilters
     * @description Get the calendar filters from storage.
     * @returns     the filters stored or undefined if there is nothing in the storage.
     */
    getCalendarFilters() {
        return this.storage.get(CALENDAR_CONSTANTS.sessionStorage.selectedFilters);
    }

    /**
     * @name        saveToStorage
     * @description Save the key/value to a storage.
     * @param       key the key to be saved in the storage.
     * @param       value the value to be saved in the storage.
     * @returns     a boolean indicating if the value was stored successfully.
     */
    private saveToStorage(key: string, value: string): boolean {
        if (!isEmpty(value)) {
            return this.storage.set(key, value);
        }

        return false;
    }
}
