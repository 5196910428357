import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { WindowRef } from '@finder/shared/services/window-ref/window-ref.service';

@Component({
    selector: 'finder-infinite-scrolling',
    templateUrl: './infinite-scrolling.component.html',
    styleUrls: ['./infinite-scrolling.component.scss']
})
export class InfiniteScrollingComponent implements OnDestroy, AfterViewInit {

    @Input() isScrollable = true;
    @Output() scrolled = new EventEmitter();
    @ViewChild('anchor') anchor: ElementRef<HTMLElement>;

    private observer: IntersectionObserver;
    private rootMargin = '1000px';

    constructor(
        private elementRef: ElementRef,
        private windowRef: WindowRef,
    ) { }

    get element() {
        return this.elementRef.nativeElement;
    }

    ngAfterViewInit() {
        const options = {
            root: this.isHostScrollable() ? this.elementRef.nativeElement : null,
            rootMargin: this.rootMargin
        };

        this.observer = new this.windowRef.nativeWindow.IntersectionObserver(/* istanbul ignore next */([entry]) => {
            if (entry.isIntersecting && this.isScrollable) {
                this.scrolled.emit();
            } else if (this.isScrollable === false) {
                this.observer.disconnect();
            }
        }, options);

        this.observer.observe(this.anchor.nativeElement);
    }

    ngOnDestroy() {
        this.observer.disconnect();
    }

    private isHostScrollable() {
        const style = window.getComputedStyle(this.element);

        return style.getPropertyValue('overflow') === 'auto' ||
            style.getPropertyValue('overflow-y') === 'scroll';
    }
}
