import { Directive, ElementRef, OnDestroy, Renderer2, AfterViewInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsService } from '@wdpr/angular-analytics';
import { combineLatest, fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import upperCase from 'lodash-es/upperCase';
import lowerCase from 'lodash-es/lowerCase';
import startCase from 'lodash-es/startCase';
import truncate from 'lodash-es/truncate';
import get from 'lodash-es/get';

import { ConfigService } from '@finder/core/config.service';

declare const WDPRO: any;

/**
 * Adds a `name` attribute with a dynamically created link ID value.
 * This is used by analytics to track clicks.
 *
 * It would appear that the `name` attribute _only_ works for anchor
 * click tracking, so an additional bit of logic was added to track
 * button clicks as well.
 *
 * Additionally, the dynamic link ID creation uses the `pageType` from the
 * route data. Since we had to create a dynamic component to load the
 * campaign and detail pages, logic was also added to differentiate the
 * `pageType` value for each of the different types.
 */
@Directive({
    selector: '[finderLinkId]'
})
export class LinkIdDirective implements AfterViewInit, OnDestroy {
    @Input() linkId: string;
    @Input() analyticsTracking = true;

    private destroy$ = new Subject<void>();
    private siteId: string;
    private pageType: string;
    private entityType: string;
    private elementText: string;

    constructor(
        private elementRef: ElementRef,
        private config: ConfigService,
        private route: ActivatedRoute,
        private renderer: Renderer2,
        private analyticsService: AnalyticsService,
    ) { }

    ngAfterViewInit() {
        this.subscribe();
    }

    /* istanbul ignore next */
    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private subscribe() {
        combineLatest([
            this.route.paramMap,
            this.route.data,
        ]).pipe(
            takeUntil(this.destroy$)
        ).subscribe(result => {
            if (this.analyticsTracking) {
                this.getLinkId(result[0], result[1]);
                this.setName();
                this.trackClick();
            }
        });
    }

    /**
     * Get the link ID from the various pieces of data
     *
     * @param params Route params
     * @param data Route data
     */
    private getLinkId(params, data) {
        // if a linkId is already provided, avoid this process
        if (this.linkId) {
            return;
        }
        // Get the page type from the route data
        // With the new dynamic routing component both detail and campaign
        // pages show as 'dynamic' so we have to do a little data check to
        // determine which type we actually have
        let pageType;
        switch (data.pageType) {
            case 'dynamic':
                pageType = get(data, 'svc.isCampaign', false) === true ?
                    'campaign' :
                    'detail';
                break;
            default:
                pageType = data.pageType;
                break;
        }

        // Get the various pieces of data that comprise the link ID
        // and transform them all appropriately: siteId in UPPER,
        // the rest in ProperCase - achieved using `lowerCase` then
        // `startCase`, then removing spaces.
        this.siteId = upperCase(this.config.getValue('siteId'));
        this.pageType = startCase(lowerCase(pageType));
        this.entityType = startCase(lowerCase(params.get('type')));
        this.elementText = startCase(lowerCase(
            truncate(this.elementRef.nativeElement.getAttribute('id') || this.elementRef.nativeElement.innerText, {
                length: this.config.getValue('analyticsText').length,
                separator: this.config.getValue('analyticsText').separator,
                omission: this.config.getValue('analyticsText').omission
            })
        ));

        // Push the pieces into an array and join then together with underscores.
        // This could have been done with a string template but this makes it a
        // little simpler to reorder them if necessary - we could possibly even
        // map from a template to allow the order to change dynamically?
        this.linkId = [
            this.siteId,
            this.pageType,
            this.entityType,
            this.elementText
        ].join('_')             // Join by underscore
        .replace(/_+/g, '_')    // Replace double underscores with single underscore
        .replace(/\s/g, '');    // Strip spaces
    }

    /**
     * Set the name attribute of the element
     */
    private setName() {
        this.renderer.setAttribute(
            this.elementRef.nativeElement,
            'name',
            `&amp;lid=${encodeURIComponent(this.linkId)}`
        );
    }

    private trackClick() {
        // Get the button from the component
        const isButton = this.elementRef.nativeElement.type === 'button';
        if (!isButton) {
            return;
        }

        fromEvent(this.elementRef.nativeElement, 'click')
            .pipe(
                takeUntil(this.destroy$)
            ).subscribe(() => {
                // If we try to use `analyticsService.getModel()` here we will get
                // an error because for whatever reason the page key service is not
                // properly returning the page key, so calling `getModel` attempts to
                // load the analytics and tries to get `/analytics/undefined.json` which
                // of course is incorrect and cannot be loaded, so we get an error and
                // nothing works.
                // At the point the button is clicked we _should_ have
                // the analytics model already, so why bother doing any async stuff.
                // Let's just try to grab the modal from the global scope and use it...
                const model = WDPRO.Analytics.Framework.analyticsModel[0];
                if (!model) {
                    return;
                }

                // Set link ID and fire link track
                model.linkId = this.linkId;
                this.analyticsService.trackLink(model);
            });
    }
}
