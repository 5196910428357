import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HorizontalRuleComponent } from '@finder/shared/components/horizontal-rule/horizontal-rule.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        HorizontalRuleComponent,
    ],
    exports: [
        HorizontalRuleComponent,
    ],
})
export class HorizontalRuleModule {}
