import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ModalComponent } from '@finder/shared/components/modal/modal.component';
import { ModalService } from '@finder/shared/components/modal/modal.service';
import { MobileTooltipComponent } from '@finder/shared/components/mobile-tooltip/mobile-tooltip.component';
import { ViewportDetection } from '@finder/shared/services/viewport-detection/viewport-detection.service';
import { TooltipPosition } from './tooltip.interface';

@Component({
    selector: 'finder-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit, OnDestroy {
    @Input() tooltip: string;
    @Input() position: TooltipPosition;
    @Input() title: string;
    @Input() id: Readonly<string>;

    mobileModal: ModalComponent;
    isMobile: boolean;

    private destroy$ = new Subject<void>();;

    constructor(
        private modalService: ModalService,
        private viewport: ViewportDetection
    ) {}

    ngOnInit() {
        this.mobileModal = this.modalService.create(
            MobileTooltipComponent,
            {
                title: this.title,
                data: {
                    text: this.tooltip
                },
                id: `tooltip-modal-${this.title}`
            }
        );

        this.isMobile = this.viewport.isMobile();

        // Close the modal if the viewport change from mobile to desktop
        this.viewport.onViewportResize()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.isMobile = this.viewport.isMobile();

                if (!this.isMobile && this.mobileModal.is('opened')) {
                    this.mobileModal.dismiss();
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    checkMobile() {
        if (this.viewport.isMobile()) {
            this.mobileModal.open();
        }
    }
}
