import { Injectable } from '@angular/core';
import { LOCALE_DEFAULT, LOCALE_HREF_PREFIX_IGNORELIST } from '@finder/shared/constants/app.constants';
import { LOCALE_REGEXP } from '@finder/shared/constants/app.constants';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LocaleHelperService {
    private uriLocale: string;
    private languageLoadedSubject = new BehaviorSubject<boolean>(false);
    private defaultLocales = LOCALE_HREF_PREFIX_IGNORELIST;

    constructor() { }

    /**
     * [PRO-346388]
     * Announce the locale from the URI has been set
     *
     * @returns Observable<boolean>
     */
    uriLocaleLoaded() {
        return this.languageLoadedSubject.asObservable();
    }

    /**
     * Set the value based on the URI
     *
     * @param value locale value from the URI
     */
    setUriLocale(value: string) {
        this.uriLocale = value;

        // [PRO-346388] Let anyone listening know the language has been loaded
        this.languageLoadedSubject.next(true);
    }

    /**
     * Return the locale value from the URI
     *
     * @returns
     */
    getUriLocale() {
        return this.uriLocale;
    }

    /**
     * Strips the current locale of a URL.
     * Ex: input: /es-us/attractions/magic-kingdom/space-mountain
     *     output: /attractions/magic-kingdom/space-mountain
     * Ex: input: /ko/tours/follow-your-dreams-guided-tour/
     *     output: /ko/tours/follow-your-dreams-guided-tour/
     *
     * @param url string - the URL to test
     * @return string - the URL, without locale if it had one
     */
    stripLocale(url) {
        // convert the string URL into an array (omitting anything empty)
        url = url.split('/').filter(value => value);

        const localeRE = new RegExp(LOCALE_REGEXP.lacd);
        if (localeRE.test(url[0])) {
            // a locale was found in the URL, so remove it
            url.shift(); // remove the locale from the URL
        }

        url = url.join('/'); // restore the URL to a string

        // return the URL with the leading slash it came in with
        return `/${url}`;
    }

    /**
     * Check to see if this is a default English locale
     */
    isDefaultLocale(locale) {
        locale = locale || LOCALE_DEFAULT;

        return this.defaultLocales.includes(this.getNormalizedLocale(locale));
    }

    /**
     * Normalize the locale string to `xx-xx` format to make it easier to parse
     */
    getNormalizedLocale(locale): string {
        if (!locale) {
            locale = LOCALE_DEFAULT;
        }

        return locale.toLowerCase().replace('_', '-');
    }

}
