import { Component, OnInit, Input, Inject, OnDestroy } from '@angular/core';
import { debounce, debounceTime, map, takeUntil } from 'rxjs/operators';
import { Subject, timer } from 'rxjs';

import { toQuerystring } from '@finder/shared/utils/object/toQuerystring';
import { ConfigService } from '@finder/core/config.service';
import { brandFooterDefaultOptions, cachedPath } from '@finder/shared/components/syndicated-layout/syndicated-options';
import { SyndicatedOptions } from '@finder/shared/components/syndicated-layout/syndicated-options.interface';
import { SYNDICATED_FOOTER_ANIMATIONS } from '@finder/shared/components/syndicated-layout/syndicated-footer/syndicated-footer.animations';
import { LoadingService } from '@finder/shared/services/loading-service/loading-service.service';
import { FooterService } from '@finder/shared/services/footer-service/footer-service.service';
import { WindowRef } from '@finder/shared/services/window-ref/window-ref.service';
import { IsParkCalendarsProvider, IS_PARK_CALENDARS_URL } from '@finder/shared/utils/url/isParkCalendarsUrl';

enum FooterState {
    unloaded = 'unloaded',
    loaded = 'loaded'
}

@Component({
    selector: 'finder-syndicated-footer',
    templateUrl: './syndicated-footer.component.html',
    styleUrls: ['./syndicated-footer.component.scss'],
    animations: [...SYNDICATED_FOOTER_ANIMATIONS]
})
export class SyndicatedFooterComponent implements OnInit, OnDestroy {
    @Input() url: string;
    @Input() siteId: string;
    @Input() version: number;
    @Input() locale: string;
    @Input() language: string;
    @Input() environment: string;
    @Input() source: string;

    syndicatedFooterUrl: string;
    footerType: string;
    defaultOptions: SyndicatedOptions;
    useCached = false; // @TODO validate if the user is not loggedIn to use cache.
    stateName: FooterState;
    showLine = false;
    footerConfig: boolean;
    private destroy$ = new Subject<void>();
    private legalWrapper = 'legalWrapper';

    constructor(
        private loadingService: LoadingService,
        private footerService: FooterService,
        private winRef: WindowRef,
        private configService: ConfigService,
        @Inject(IS_PARK_CALENDARS_URL) private isParkCalendars: IsParkCalendarsProvider,
    ) { }

    ngOnInit() {
        this.defaultOptions = { ...brandFooterDefaultOptions[this.siteId] };
        this.footerType = this.defaultOptions.container;
        this.defaultOptions.version = this.version || this.defaultOptions.version;
        this.defaultOptions.locale = this.locale || this.defaultOptions.locale;
        this.defaultOptions.language = this.language || this.defaultOptions.language;
        this.defaultOptions.siteOverride = this.siteId;
        // park-calendars is a very particular page, that is shared for some travel trade clients,
        // and per business requirements, we shouldn't show any links at all, icluding footer.
        if (this.isParkCalendars(this.winRef.nativeWindow.location.pathname)) {
            const syndicatedLayout = this.configService.getValue('syndicatedLayout');
            this.url = `${syndicatedLayout.baseUrl}${syndicatedLayout.footer?.legalPath}`;
            this.footerType = this.legalWrapper;
            this.defaultOptions.container = this.footerType;
        }

        this.syndicatedFooterUrl = `${this.url}${this.useCached ? cachedPath : '/'}${toQuerystring(this.defaultOptions)}`;

        this.footerService.watchFooter()
            .pipe(
                debounceTime(50),
                takeUntil(this.destroy$)
            )
            .subscribe((footerConfig) => {
                this.footerConfig =  footerConfig.horizontalSeparator;
            });

        this.loadingService.getLoadingState()
            .pipe(
                map((loaded) => loaded ? FooterState.loaded : FooterState.unloaded),
                // simulate transition duration timer
                debounce((footerState) => {
                    let time = 0;

                    // content load transition 500ms
                    if (footerState === FooterState.loaded) {
                        time = 500;
                    }

                    return timer(time);
                }),
                takeUntil(this.destroy$)
            )
            .subscribe((footerState) => {
                this.stateName = footerState;
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    footerStateDone(event: any) {
        if (event.toState !== FooterState.loaded) {
            return;
        }
        this.showLine = true;

        if (this.footerConfig) {
            this.showLine = false;
        }
        // We want to make sure that the animation is complete and the style has been set
        // by the animation module. Once that is done we can manually remove the style attr.
        event['element'].removeAttribute('style');
    }
}
