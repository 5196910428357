import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ScriptLoaderComponent } from './script-loader.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        ScriptLoaderComponent,
    ],
    providers: [],
    exports: [
        ScriptLoaderComponent,
    ]
})
export class ScriptLoaderModule {}
